import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
// import { Link } from "react-router-dom";

class ViewPriceByMaterial extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      newPrice: 0,
      vendor: "",
      stock: [],
      materials: [],
    };
  }

  handlePriceUpdate = (e) => {
    this.setState({ newPrice: e.target.value });
  };

  updatePrice = (e) => {
    if (this.state.newPrice < 0) return;
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = {
      id: this.state.id,
      price: this.state.newPrice,
    };
    this.stopUpdate();
    axios
      .put(`${process.env.REACT_APP_API_URL}material/updateprice`, data, config)
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}material/`, {})
          .then((res) => this.setState({ materials: res.data.materialTypes }));
      })
      .catch((err) => console.log(err));
  };

  stopUpdate = () => {
    this.setState({ id: "", editMode: false, newPrice: 0 });
  };

  handleMaterialTypeChange = (e) => {
    this.setState({ vendor: e.target.value });
    // let config = this.state.user?.token
    //   ? {
    //       headers: { Authorization: `Token ${this.state.user.token}` },
    //       params: { orderType: this.state.orderType },
    //     }
    //   : {};
    let data = { user: e.target.value };
    axios
      .get(`${process.env.REACT_APP_API_URL}stock/`, { params: data })
      .then((res) => this.setState({ stock: res.data }));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + userData.token,
          },
        })
        .then((res) => {
          this.setState({ user: res.data.users });
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}material/`, {})
      .then((res) => this.setState({ materials: res.data.materialTypes }));
    // axios.get(`${process.env.REACT_APP_API_URL}material/`, {}).then((res) =>
    // // console.log(res)
    //   this.setState(
    //     {
    //       materials: res.data.materialTypes,
    //     },
    //     () => console.log(this.state)
    //   )
    // );
  }

  render() {
    console.log(this.state.tempVar);
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Prices</h1>
              </div>
              {/* <div className="form-group mb-4">
                <select
                  className="form-control form-select"
                  name="vendor"
                  value={this.state.vendor}
                  onChange={this.handleMaterialTypeChange}
                >
                  <option value={""}>Select Material Type</option>
                  {this.state.materials.map((item, index) => (
                    <option key={`vendor${index}`} value={item._id}>
                      {item.materialType}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Material Name</th>
                        <th>Is Price Based On Material</th>
                        <th>Price</th>
                        {["superAdmin", "mainStaff"].includes(
                          this.state.user?.adminType
                        ) && <th>Edit</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.materials.map(
                        (item, index) =>
                          item.isPriceBasedOnMaterial && (
                            <tr key={item._id}>
                              <td>{index}</td>
                              <td>{item.materialType}</td>
                              <td>
                                {String(
                                  item.isPriceBasedOnMaterial
                                ).toUpperCase()}
                              </td>
                              <td>
                                <div className="flex">
                                  {this.state.editMode &&
                                  this.state.id === item._id ? (
                                    <>
                                      {/* {" "}
                                  <button
                                    className="bg-transparent rounded border-1 me-2"
                                    onClick={() => this.updateStock(-1)}
                                  >
                                    -
                                  </button>
                                  {this.state.newStock}
                                  <button
                                    className="bg-transparent rounded border-1 ms-2"
                                    onClick={() => this.updateStock(+1)}
                                  >
                                    +
                                  </button> */}
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="newPrice"
                                        value={this.state.newPrice}
                                        onChange={this.handlePriceUpdate}
                                      ></input>
                                    </>
                                  ) : (
                                    item.price
                                  )}
                                </div>
                              </td>
                              {["superAdmin", "mainStaff"].includes(
                                this.state.user?.adminType
                              ) && (
                                <td>
                                  {/* <Link
                              className="btn btn-sm cursor-pointer"
                              to={{
                                pathname: "/admin/addstock",
                                options: { isEditMode: true, id: item._id },
                              }}
                            > */}
                                  {this.state.editMode &&
                                  this.state.id === item._id ? (
                                    <>
                                      <button
                                        className="btn btn-sm cursor-pointer"
                                        onClick={() => this.updatePrice()}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-check-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sm cursor-pointer"
                                        onClick={() => {
                                          this.setState({
                                            editMode: true,
                                            id: item._id,
                                            newPrice: item.price,
                                          });
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-pencil-square"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                          <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                          ></path>
                                        </svg>
                                      </button>
                                    </>
                                  )}

                                  {/* </Link> */}
                                </td>
                              )}
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPriceByMaterial;
