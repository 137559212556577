import React, { Component } from "react";

export default class AreaComponent extends Component {
  state = {
    length: 0,
    totalArea: 0,
  };

  handleAreaChange = (e) => {
    let totalArea = e.target.value;
    let width = this.props.width.variantName || 1;
    this.setState({ length: totalArea / Number(width), totalArea: totalArea });
    // let data = [
    //   {
    //     name: "totalArea",
    //     value: totalArea,
    //   },
    //   {
    //     name: "length",
    //     value: totalArea / Number(width),
    //   },
    // ];
    let data = {
        totalArea: totalArea,
        length: totalArea / Number(width),
      }
    this.props.handleDataFromComponents(data);
  };

  handleLengthChange = (e) => {
    let length = e.target.value;
    let width = this.props.width.variantName || 1;
    this.setState({ totalArea: length * Number(width), length: length });
    // let data = [
    //   {
    //     name: "totalArea",
    //     value: length * Number(width),
    //   },
    //   {
    //     name: "length",
    //     value: length,
    //   },
    // ];
    let data = {
      totalArea: length * Number(width),
      length: length,
    };
    this.props.handleDataFromComponents(data);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.updateValues !== this.props.updateValues) {
      let width = this.props.width.variantName || 1;
      let totalArea = this.state.length * Number(width);
      this.setState({
        totalArea: totalArea,
        length: totalArea / Number(width),
      });
    }
  };

  render() {
    return (
      <>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="length">
            Length
          </label>
          <input
            type="number"
            id="length"
            className="form-control"
            value={this.state.length}
            onChange={this.handleLengthChange}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="totalArea">
            Total Area
          </label>
          <input
            type="number"
            id="totalArea"
            className="form-control"
            value={this.state.totalArea}
            onChange={this.handleAreaChange}
          />
          <small className="text-danger">
            {this.state.error?.weightError && this.state.error.weightError}
          </small>
        </div>
      </>
    );
  }
}
