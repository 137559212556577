import React, { Component } from "react";
import convert from "convert-units";
import axios from "axios";

export default class ItemEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      length: "",
      variant: "",
      weightPerMeter: 0,
      weight: 0,
      pieces: 0,
      itemPrice: 0,
      price: "",
      sectionId: "",
      imageUrl: "",
      materialImageUrl: "",
      materialNameId: "",
      materialTypeId: "",
      materials: [],
      sections: [],
      materialTypes: this.props.materialTypes,
      finish: "Black",
      lengthType: "1",
      variants: [],
      isPopulated: false,
      inventoryType: 0,
      totalArea: "",
      width: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () => {
      this.handleWeight();
      this.handleData();
    });
  };

  getVariant = () => {
    if (!this.state.variant) return;
    let variant = this.state.variants.filter(
      (i) => i.variantName === this.state.variant
    );
    return variant[0]._id;
  };

  getPrice = () => {
    let itemPrice;
    if (this.state.inventoryType === 0) {
      itemPrice = this.state.price * this.state.weight;
    } else if (this.state.inventoryType === 1) {
      itemPrice = this.state.price * this.state.totalArea * this.state.pieces;
    }
    return itemPrice;
  };

  handleData = () => {
    let data = {
      materialName: this.state.materialNameId,
      materialType: this.state.materialTypeId,
      section: this.state.sectionId,
      variant: this.getVariant(),
      weightPerMeter: this.state.weightPerMeter,
      weight: this.state.weight,
      pieces: this.state.pieces,
      finish: this.state.finish,
      itemPrice: this.getPrice(),
      length: this.state.length,
      totalArea: this.state.totalArea,
      inventoryType: this.state.inventoryType,
    };
    this.props.handleItems(this.props.id, data);
  };

  handleLengthType = (e) => {
    this.setState({ lengthType: e.target.value });
  };

  showPrice = (type, id) => {
    let materialType = this.props.materialTypes.filter(
      (i) => i._id === this.state.materialTypeId
    );
    if (materialType.length > 0) {
      if (materialType[0].isPriceBasedOnMaterial) {
        this.setState({ price: materialType[0].price });
      } else if (type === 2) {
        let section = this.state.sections.filter((i) => i._id === id);
        if (section.length > 0) this.setState({ price: section[0].price });
      }
    }
  };

  handleUnitChange = (e) => {
    this.setState({ lengthType: e.target.value });
  };

  handleMaterialTypeChange = (e) => {
    this.setState({ materialTypeId: e.target.value }, () => this.showPrice(1));
    let data = { params: { materialTypeId: e.target.value } };
    console.log(data, "33");
    axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
      this.setState(
        {
          sections: res.data.sections,
        },
        () => {
          if (!this.state.isPopulated) {
            console.log(this.state.sections, "11");
            this.handleSectionChange({
              target: { value: this.state.sectionId },
            });
          }
        }
      );
    });
    this.handleData();
  };

  handleSectionChange = (e) => {
    this.setState({ sectionId: e.target.value });
    this.showPrice(2, e.target.value);
    console.log(this.state.sections, "22");
    let imageUrl = this.state.sections.filter((i) => i._id === e.target.value);
    if (imageUrl.length > 0) {
      this.setState({ imageUrl: imageUrl[0].drawing });
      let data = { params: { sectionId: e.target.value } };
      axios
        .get(`${process.env.REACT_APP_API_URL}inventory/`, data)
        .then((res) => {
          this.setState({ materials: res.data.inventory }, () => {
            if (!this.state.isPopulated) {
              console.log(this.state.sections, "11");
              this.handleMaterialChange({
                target: { value: this.state.materialNameId },
              });
              this.setState({ isPopulated: true });
            }
          });
        });
      this.handleData();
    }
  };

  handleMaterialChange = (event) => {
    let imageUrl = this.state.materials.filter(
      (i) => i._id === event.target.value
    );
    this.setState(
      {
        materialImageUrl: imageUrl[0].drawing,
        materialNameId: event.target.value,
      },
      () => {
        this.state.materials.map((item) => {
          console.log(item._id, "ttttt", this.state.materialNameId);
          if (item._id === this.state.materialNameId) {
            return this.setState({
              weightPerMeter: item.weight,
              variants: item.variants,
            });
          }
          return null;
        });
        this.handleWeight();
        this.handleData();
      }
    );
  };

  handlePrice = () => {
    if (!this.state.materialNameId) return;
    let selectedMaterial = this.state.materials.filter(
      (item) => item._id === this.state.materialNameId
    );
    let { materialType, section } = selectedMaterial[0];
    let price = materialType.isPriceBasedOnMaterial
      ? materialType.price
      : section.price;
    let itemPrice = price * this.state.weight;
    this.setState({ itemPrice: itemPrice }, () => this.handleData());
  };

  handleWeight = () => {
    let weight =
      this.state.weightPerMeter * this.state.length * this.state.pieces;
    this.setState({ weight: weight }, () => this.handlePrice());
  };

  convertLength = (value) => {
    if (this.state.lengthType === "2") return convert(value).from("m").to("ft");
    else return value;
  };

  handleWidthChange = (e) => {
    let width = e.target.value;
    let totalArea = 0;
    if (this.state.length) {
      totalArea = Number(this.state.length) * Number(width);
      let itemPrice = this.state.totalArea * this.state.pieces;
      this.setState({
        variant: width,
        totalArea: totalArea,
        itemPrice: itemPrice,
      });
    } else {
      this.setState({ variant: width }, () => this.handleData());
    }
  };

  handleLength = (e) => {
    let length = e.target.value;
    let totalArea = 0;
    if (this.state.variant) {
      totalArea = Number(length) * Number(this.state.variant);
      let itemPrice = this.state.totalArea * this.state.pieces;
      this.setState({
        length: length,
        totalArea: totalArea,
        itemPrice: itemPrice,
      });
    } else {
      this.setState({ length: length }, () => this.handleData());
    }
  };

  populateData = (data) => {
    // this.handleSectionChange({ target: { value: data.section._id } });
    this.setState({
      materialNameId: data.materialName._id,
      materialTypeId: data.materialType._id,
      sectionId: data.section._id,
      variant: data.variant.variantName,
      weightPerMeter: data.weightPerMeter,
      weight: data.weight,
      pieces: data.pieces,
      finish: data.finish,
      itemPrice: data.itemPrice,
      price: data.price,
      totalArea: data.totalArea,
      length: data.length,
      imageUrl: data.section.drawing,
      materialImageUrl: data.materialName.drawing,
      inventoryType: Number(data.inventoryType) || 0,
    });
    // this.handleMaterialTypeChange({
    //   target: { value: data.materialType._id },
    // });
    this.populateDropDowns(data);
    if (!data.price) {
      if (data.materialType.isPriceBasedOnMaterial) {
        this.setState({ price: data.materialType.price });
      } else {
        this.setState({ price: data.section.price });
      }
    }
  };

  populateDropDowns = (data) => {
    let config = { params: { materialTypeId: data.materialType._id } };
    axios
      .get(`${process.env.REACT_APP_API_URL}section/`, config)
      .then((res) => {
        this.setState({ sections: res.data.sections });
      });

    config = { params: { sectionId: data.section._id } };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/`, config)
      .then((res) => {
        this.setState({
          materials: res.data.inventory,
        });
      });
    config = {
      headers: { authorization: `Token ${this.props.user.token}` },
      params: { id: data.materialName._id },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/getinventory/`, config)
      .then((res) => {
        console.log(res);
        this.setState({
          variants: res.data.inventory.variants,
        });
      });
  };

  componentDidMount() {
    if (this.props.loaded) {
      this.populateData(this.props.data);
    }
  }

  render() {
    let itemPrice = 0;
    if (this.state.inventoryType === 0) {
      itemPrice = this.state.price * this.state.weight;
    } else if (this.state.inventoryType === 1) {
      itemPrice = this.state.price * this.state.totalArea * this.state.pieces;
    }
    return (
      <>
        <div className="float-right" style={{ float: "right" }}>
          <button
            className="btn"
            disabled={this.props.length === 1}
            onClick={() => this.props.deleteItem(this.props.id)}
          >
            <i className="bi bi-trash-fill" />
          </button>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="materialTypeId">
            Material Type
          </label>
          <select
            className="form-control form-select col-12"
            id="materialTypeId"
            value={this.state.materialTypeId}
            onChange={this.handleMaterialTypeChange}
            required
          >
            {" "}
            <option value="" disabled selected>
              Select Material Type
            </option>
            {this.props.materialTypes.map((item, index) => (
              <option key={item._id} value={item._id}>
                {item.materialType}
              </option>
            ))}
          </select>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="sectionId">
                Category
              </label>
              <select
                className="form-control form-select col-12"
                id="sectionId"
                value={this.state.sectionId}
                onChange={this.handleSectionChange}
                required
              >
                {" "}
                <option value="" disabled selected>
                  Select Category
                </option>
                {this.state.sections.map((item, index) => (
                  <option key={`material${index}`} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {this.state.imageUrl && (
            <div className="col-6">
              <img
                className="orders-img"
                src={`${process.env.REACT_APP_IMAGE_URL + this.state.imageUrl}`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="materiaName">
                Material Name
              </label>
              <select
                className="form-control form-select col-12"
                id="materialNameId"
                value={this.state.materialNameId}
                onChange={this.handleMaterialChange}
                required
              >
                {" "}
                <option value="" disabled selected>
                  Select Material Name
                </option>
                {this.state.materials.map((item, index) => (
                  <option key={`material${index}`} value={item._id}>
                    {`${item.materialName} (Code ${item.materialCode})`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {this.state.imageUrl && (
            <div className="col-6">
              <img
                className="orders-img"
                src={`${
                  process.env.REACT_APP_IMAGE_URL + this.state.materialImageUrl
                }`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="weightPerMeter">
                  Weight/m
                </label>
                <input
                  type="number"
                  id="weightPerMeter"
                  className="form-control"
                  value={this.state.weightPerMeter}
                  disabled
                />
              </div>
            ) : (
              this.state.inventoryType === 1 && (
                <div className="form-outline">
                  <label className="form-label" htmlFor="length">
                    Length ({this.state.lengthType === "1" ? "m" : "feet"})
                  </label>
                  <input
                    type="number"
                    id="length"
                    className="form-control"
                    value={this.state.length}
                    onChange={this.handleLength}
                  />
                </div>
              )
            )}
          </div>
          <div className="col-2">
            <div className="form-outline">
              <label className="form-label" htmlFor="lengthType">
                Unit
              </label>
              <select
                className="form-control form-select col-12"
                id="lengthType"
                value={this.state.lengthType}
                onChange={this.handleUnitChange}
              >
                <option value={"1"}>m</option>
                <option value={"2"}>feet</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="variant">
                  Length(
                  {this.state.lengthType === "1" ? "m" : "feet"})
                </label>
                <select
                  className="form-control form-select col-12"
                  id="variant"
                  value={this.state.variant}
                  onChange={this.handleInputChange}
                  required
                >
                  {" "}
                  <option value="" disabled selected>
                    Select variant
                  </option>
                  {this.state.variants.map((item, index) => (
                    <option key={item._id} value={item.variantName}>
                      {this.convertLength(item.variantName)}
                    </option>
                  ))}
                </select>
                <small
                  className="float-right"
                  style={{ float: "right", cursor: "pointer" }}
                  title="Can't find the length you're looking for?"
                  onClick={() => this.props.handleModal(true)}
                >
                  <i class="bi bi-question-square-fill"></i>
                </small>
              </div>
            ) : (
              this.state.inventoryType === 1 && (
                <div className="form-outline">
                  <label className="form-label" htmlFor="variant">
                    Width ({this.state.lengthType === "1" ? "m" : "feet"})
                  </label>
                  <select
                    className="form-control form-select col-12"
                    id="variant"
                    value={this.state.variant}
                    onChange={this.handleWidthChange}
                    required
                  >
                    {" "}
                    <option value="" disabled selected>
                      Select Width
                    </option>
                    {this.state.variants.map((item, index) => (
                      <option key={item._id} value={item.variantName}>
                        {item.variantName}
                      </option>
                    ))}
                  </select>
                  <small
                    className="float-right"
                    style={{ float: "right", cursor: "pointer" }}
                    title="Can't find the length you're looking for?"
                    onClick={() => this.props.handleModal(true)}
                  >
                    <i class="bi bi-question-square-fill"></i>
                  </small>
                </div>
              )
            )}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="pieces">
                Pieces
              </label>
              <input
                type="number"
                id="pieces"
                className="form-control"
                value={this.state.pieces}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="weight">
                  Weight(kg)
                </label>
                <input
                  type="number"
                  id="weight"
                  className="form-control"
                  value={this.state.weight}
                  disabled
                />
              </div>
            ) : (
              <div className="form-outline">
                <label className="form-label" htmlFor="totalArea">
                  Square {this.state.lengthType === "1" ? "meter" : "feet"}
                </label>
                <input
                  type="number"
                  id="totalArea"
                  className="form-control"
                  value={this.state.totalArea}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="itemPrice">
                Item Price
              </label>
              <input
                type="number"
                id="price"
                className="form-control"
                value={this.state.price}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="itemPrice">
                Item Total
              </label>
              <div className="input-group">
                <span class="input-group-text br-0 border-gray bg-white">
                  ₹
                </span>
                <input
                  type="number"
                  id="itemPrice"
                  className="form-control bl-0 rounded-l-0"
                  value={itemPrice}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
