import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class ViewInventory extends React.Component {
  constructor() {
    super();
    this.state = {
      inventory: [],
      vendorList: [],
      tempVar: process.env.PORT,
      showModal: false,
    };
  }

  openImage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  handleVendorChange = (e) => {
    let body = {
      vendorId: e.target.value,
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/`, { params: body })
      .then((res) =>
        this.setState({ inventory: res.data }, () => console.log(res.data))
      );
  };

  handleModal = (value) => {
    this.setState({ showModal: value });
  };

  disableMaterial = () => {
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = {
      id: this.state.id,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}inventory/disable`, data, config)
      .then((res) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}inventory/`, {})
          .then((res) =>
            this.setState({ inventory: res.data }, () =>
              this.handleModal(false)
            )
          );
      });
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + userData.token,
          },
        })
        .then((res) => {
          this.setState({ user: res.data.users });
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/`, {})
      .then((res) =>
        this.setState({ inventory: res.data }, () =>
          console.log("list", res.data)
        )
      );
    axios
      .get(`${process.env.REACT_APP_API_URL}user/getvendors`, {})
      .then((res) =>
        this.setState(
          {
            vendorList: res.data.vendors,
          },
          () => console.log("vendors", res.data)
        )
      );
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Inventory</h1>
                <Modal
                  show={this.state.showModal}
                  onHide={() => this.handleModal(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Disable Item?</Modal.Title>
                  </Modal.Header>
                  {/* <Modal.Body></Modal.Body> */}
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.handleModal(false)}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => this.disableMaterial()}
                    >
                      Disable
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* <div className="form-group mb-4">
                <select
                  className="form-control form-select"
                  name="categoryId"
                  value={this.state.categoryId}
                  onChange={this.handleVendorChange}
                >
                  <option value={""}>All</option>
                  {this.state.vendorList.map((item, index) => (
                    <option key={item._id} value={item._id}>
                      {item.vendorName}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Material Code</th>
                        <th>Weight</th>
                        <th>Material Name</th>
                        <th>Brand</th>
                        <th>Material Type</th>
                        <th>Category Name</th>
                        <th>Finish</th>
                        <th>Threshold</th>
                        <th>Drawing</th>
                        <th>Action</th>
                        {/* <th>View</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.inventory.map((item, index) => (
                        <tr key={item.materialName}>
                          <td>{index}</td>
                          <td>
                            <ul className="un-styled p-0 m-0 font-weight-normal">
                              {item.inventoryVariants.map((i) => (
                                <li className="font-weight-normal">
                                  {i.materialCode}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <ul className="un-styled p-0 m-0">
                              {item.inventoryVariants.map((i) => (
                                <li className="font-weight-normal">
                                  {i.weight}
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>{item.materialName}</td>
                          <td>{item.brand?.brandName}</td>
                          <td>{item.materialType?.materialType}</td>
                          <td>{item.section.name}</td>
                          <td>{item.finish}</td>
                          <td>{item.threshold}</td>
                          <td>
                            <img
                              className="inventory-img"
                              onClick={() => this.openImage()}
                              src={`${
                                process.env.REACT_APP_IMAGE_URL + item.drawing
                              }`}
                              alt=""
                            />
                          </td>
                          {/* <td>
                            <img
                              src={`http://localhost:8081/uploads/${item.drawing}`}
                              alt=""
                              className="inventory-img"
                              onClick={() =>
                                this.openImage(
                                  `http://localhost:8081/uploads/${item.drawing}`
                                )
                              }
                            ></img>
                          </td> */}
                          <td className="d-flex">
                            {["superAdmin", "mainStaff"].includes(
                              this.state.user?.adminType
                            ) && (
                              <Link
                                className="me-2"
                                to={{
                                  pathname: "/admin/edit-inventory/" + item._id,
                                  options: { isEditMode: true, id: item._id },
                                }}
                              >
                                <button className="btn text-sm btn-outline-secondary border-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    ></path>
                                  </svg>
                                </button>
                              </Link>
                            )}
                            <Link
                              to={{
                                pathname:
                                  "/admin/view-inventory-stock/" + item._id,
                                options: { isEditMode: true, id: item._id },
                              }}
                            >
                              <button className="btn text-sm btn-outline-secondary border-0">
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  width="16"
                                  height="16"
                                  data-icon="warehouse"
                                  class="svg-inline--fa fa-warehouse fa-w-20"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 640 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z"
                                  ></path>
                                </svg>
                              </button>
                            </Link>
                            {["superAdmin", "mainStaff"].includes(
                              this.state.user?.adminType
                            ) && (
                              <button
                                className="btn text-sm btn-outline-secondary border-0"
                                onClick={() => {
                                  this.setState({ id: item._id }, () =>
                                    this.handleModal(true)
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-x-octagon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </button>
                            )}
                          </td>
                          {/* <td>
                           
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewInventory;
