import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// const queryString = require("query-string");
import moment from "moment";
import ViewOrderDetails from "./ViewOrderDetails";

class ViewOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      vendorId: "",
      orders: [],
      approved: true,
      orderDetails: {},
      stocks: [],
      orderType: "all",
      isOrderLoaded: true,
      viewModalState: false,
      driverName: "",
      driverContactNumber: "",
      vehicleNumber: "",
    };
  }
  openImage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  disableMaterial = () => {
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = {
      id: this.state.id,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}order/disable`, data, config)
      .then((res) => {
        this.getOrders();
        this.setState({
          showOrderDeleteModel: false,
        });
      });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleVendorModal = (modalState) => {
    if (this.state.action === "confirm") {
      this.getVendors();
      this.setState({ showVendorModal: modalState });
    } else {
      this.getOrder();
      this.setState({ showVendorModal: modalState });
    }
  };

  handleOrderTypeChange = (e) => {
    this.setState({ orderType: e.target.value });
    let config = {
      headers: { Authorization: `Token ${this.state.token}` },
      params: { orderType: e.target.value },
    };
    axios.get(`${process.env.REACT_APP_API_URL}order/`, config).then((res) => {
      this.setState({ isOrderLoaded: true, orders: res.data }, () =>
        console.log("orders==>", this.state.orders)
      );
      console.log(res);
    });
  };

  handleApproval = () => {
    // this.setState({ id: option.id, action: option.action }, () =>
    //   this.handleVendorModal(true)
    // );
    // if (this.state.selectVendor) {
    this.handleVendorModal(false);
    let config = this.state.token
      ? { headers: { Authorization: `Token ${this.state.token}` } }
      : {};
    let data = {
      action: this.state.action,
      id: this.state.id,
      materialNameId: this.state.materialName,
      vehicleNumber: this.state.vehicleNumber,
      pieces: this.state.pieces,
      vendorId: this.state.vendorId,
      driverName: this.state.driverName,
      driverContactNumber: this.state.driverContactNumber,
      weight: this.state.weight,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}order/approval`, data, config)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            approved: true,
            selectVendor: false,
            isOrderLoaded: false,
          });
          config = this.state.token
            ? {
                headers: { Authorization: `Token ${this.state.token}` },
                params: { orderType: this.state.orderType },
              }
            : {};
          console.log("token", config);
          axios
            .get(`${process.env.REACT_APP_API_URL}order/`, config)
            .then((res) => {
              this.setState({ isOrderLoaded: true, orders: res.data }, () =>
                console.log("orders==>", this.state.orders)
              );
              console.log(res);
            });
        } else {
          console.log("stock not available");
        }
      });
    // } else {
    //   this.setState({ selectVendor: true });
    // }
  };

  getOrders = () => {
    let config = this.state.token
      ? {
          headers: { authorization: `Token ${this.state.token}` },
          params: { orderType: this.state.orderType },
        }
      : {};
    axios.get(`${process.env.REACT_APP_API_URL}order/`, config).then((res) => {
      this.setState({ orders: res.data });
      console.log(res);
    });
    return;
  };

  getVendors = () => {
    let config = {
      headers: { Authorization: `Token ${this.state.token}` },
      params: {
        materialNameId: this.state.materialName,
        pieces: this.state.pieces,
        length: this.state.length,
        weight: this.state.weight,
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}stock/checkstock`, config).then(
      (res) =>
        this.setState(
          {
            stocks: res.data.stocks,
          },
          () => console.log(res)
        )
      // console.log(res)
    );
  };

  getOrder = () => {
    let config = {
      headers: { Authorization: `Token ${this.state.token}` },
      params: {
        id: this.state.id,
        user: this.state.user,
      },
    };
    axios.get(`${process.env.REACT_APP_API_URL}order/getorder`, config).then(
      (res) =>
        this.setState(
          {
            orderDetails: res.data[0],
          },
          () => console.log(res.data)
        )
      // console.log(res)
    );
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.location.search !== this.props.location.search) {
      // let query = queryString.parse(this.props.location.search);
      let config = this.state.user?.token
        ? {
            headers: { Authorization: `Token ${this.state.user.token}` },
            params: { orderType: this.state.orderType },
          }
        : {};
      axios
        .get(`${process.env.REACT_APP_API_URL}order/`, config)
        .then((res) => {
          this.setState({ orders: res.data });
          console.log(res);
        });
    }
  };

  componentDidMount() {
    // var query = queryString.parse(this.props.location.search);
    // var params = { options: query.option };
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? {
              headers: { Authorization: `Token ${this.state.user.token}` },
              params: { orderType: this.state.orderType },
            }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}order/`, config)
          .then((res) => {
            this.setState({ orders: res.data });
            console.log(res);
          });
        // config={
        //   headers: { Authorization: `Token ${this.state.user.token}` },
        //   params: { orderType: this.state.orderType },
        // }
        // axios
        //   .get(`${process.env.REACT_APP_API_URL}stock/checkstock`, config)
        //   .then((res) =>
        //     // this.setState(
        //     //   {
        //     //     vendors: res.data.vendors,
        //     //   },
        //     //   () => console.log(this.state)
        //     // )
        //     console.log(res)
        //   );
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Orders</h1>
                {/* Reactbootstrap */}
                <Modal
                  show={this.state.showVendorModal}
                  onHide={() => this.handleVendorModal(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Select Vendor</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {this.state.action === "confirm" ? (
                      <form>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="vendorId">
                                Vendor
                              </label>
                              <select
                                className="col-12 form-control form-select"
                                id="vendorId"
                                value={this.state.vendorId}
                                onChange={this.handleInputChange}
                              >
                                <option value="" selected disabled>
                                  Select Vendor
                                </option>
                                {this.state.stocks.map((item, index) => (
                                  <option
                                    key={`vendor${index}`}
                                    value={item.vendor._id}
                                  >
                                    {item.vendor.vendorName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="driverName"
                              >
                                Driver Name
                              </label>
                              <input
                                type="text"
                                id="driverName"
                                className="form-control"
                                value={this.state.driverName}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="driverContactNumber"
                              >
                                Driver Contact Number
                              </label>
                              <input
                                type="number"
                                id="driverContactNumber"
                                className="form-control"
                                value={this.state.driverContactNumber}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="vehicleNumber"
                              >
                                Vehicle Number
                              </label>
                              <input
                                type="text"
                                id="vehicleNumber"
                                className="form-control"
                                value={this.state.vehicleNumber}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      this.state.orderDetails && (
                        <ViewOrderDetails
                          orderDetails={this.state.orderDetails}
                        />
                      )
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.handleVendorModal(false)}
                    >
                      Close
                    </Button>
                    {this.state.action === "confirm" && (
                      <Button
                        variant="primary"
                        onClick={() => this.handleApproval(true)}
                      >
                        Confirm Order
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={this.state.showOrderDeleteModel}
                  onHide={() => this.setState({ showOrderDeleteModel: false })}
                >
                  <Modal.Header>
                    <Modal.Title>Delete Order</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure to delete order?</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ showOrderDeleteModel: false })
                      }
                    >
                      Close
                    </Button>

                    <Button
                      variant="danger"
                      onClick={() => this.disableMaterial()}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="form-group mb-4">
                <select
                  className="form-control form-select"
                  name="orderType"
                  value={this.state.orderType}
                  onChange={this.handleOrderTypeChange}
                >
                  <option value={"all"}>All</option>
                  <option value={"confirmed"}>Confirmed</option>
                  <option value={"pending"}>Pending</option>
                </select>
              </div>

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>Order No</th>
                        <th>Ordered Date</th>
                        {/* <th>Project Name</th> */}
                        <th>Customer Name</th>
                        <th>User</th>
                        <th>Delivery Date</th>
                        <th>Total Price</th>
                        <th>Remarks</th>
                        <th>Status</th>
                        <th>Payment Status</th>
                        <th>Action</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody key={this.state.isOrderLoaded}>
                      {this.state.isOrderLoaded &&
                        this.state.orders.map((item, index) => (
                          //item.status !== "confirmed" &&
                          <tr key={`${item.orderNumber}=${item.status}`}>
                            <td>{item.orderNumber.toUpperCase()}</td>
                            <td>
                              {moment(item.createdAt).format("MMM Do YY")}
                            </td>
                            {/* <td>{item.projectName}</td> */}
                            <td>{item.addressDetails?.fullName}</td>
                            <td>{item.user?.phoneNo}</td>
                            {/* <td>{item.length}</td>
                          <td>{item.weightPerMeter}</td>
                          <td>{item.weight}</td>
                          <td>{item.pieces}</td> */}
                            <td>
                              {moment(item.deliveryDate).format("MMM Do YY")}
                            </td>
                            <td>{item.totalPrice}</td>
                            <td>{item.remarks}</td>
                            <td>{item.status.toUpperCase()}</td>
                            <td>{item.paymentStatus.toUpperCase()}</td>
                            <td>
                              <Link
                                className="btn btn-sm "
                                to={{
                                  pathname:
                                    "/admin/view-order-details/" + item._id,
                                }}
                              >
                                View
                              </Link>
                              {/* <button
                                className={`btn btn-sm ${
                                  item.status.toUpperCase() === "CONFIRMED"
                                    ? "btn-success"
                                    : "btn-outline-secondary"
                                }`}
                                // disabled={
                                //   item.status.toUpperCase() === "CONFIRMED"
                                // }
                                onClick={() => {
                                  this.setState(
                                    {
                                      id: item._id,
                                      action:
                                        item.status.toUpperCase() ===
                                        "CONFIRMED"
                                          ? "view"
                                          : "confirm",
                                      materialName: item.materialName._id,
                                      user: item.user._id,
                                      pieces: item.pieces,
                                      length: item.length._id,
                                      weight: item.weight,
                                    },
                                    () => this.handleVendorModal(true)
                                  );
                                }}
                              >
                                {item.status.toUpperCase() === "CONFIRMED"
                                  ? "View"
                                  : "Confirm"}
                              </button> */}
                            </td>

                            {/* <button
                            className="btn btn-sm btn-outline-secondary"
                            onClick={() => {
                              this.handleApprovval({
                                action: "deny",
                                id: item._id,
                              });
                            }}
                          >
                            Deny
                          </button> */}
                            <td className="d-flex">
                              <Link
                                // style={{
                                //   pointerEvents:
                                //     item.status === "confirmed" || "none",
                                // }}
                                className="btn btn-sm "
                                to={{
                                  pathname: "/edit-order/" + item._id,
                                  options: { isEditMode: true, id: item._id },
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  ></path>
                                </svg>
                              </Link>
                              {item.status.toUpperCase() != "APPROVED" && (
                                <button
                                  className="btn text-sm btn-outline-secondary border-0"
                                  onClick={() => {
                                    this.setState({
                                      id: item._id,
                                      showOrderDeleteModel: true,
                                    });
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-x-octagon"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                  </svg>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewOrder;
