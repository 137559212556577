import React from "react";
import { Link } from "react-router-dom";

class SidebarUser extends React.Component {
  state = {
    user: false,
  };

  componentDidMount() {
    var user = localStorage.getItem("user")
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
  }

  render() {
    return (
      <div
        id="sidebarMenu"
        className="p-4 mt-5 col-md-3 col-lg-2 d-md-block sidebar collapse"
      >
        <ul className="list-unstyled ps-0">
          <li className="mb-1">
            <Link
              className="btn btn-toggle align-items-center rounded collapsed"
              to="/orders"
            >
              Submit New Quote
            </Link>
          </li>
          {this.state.user && (
            <>
              <li className="mb-1">
                <Link
                  className="btn btn-toggle align-items-center rounded collapsed"
                  to="/vieworders"
                >
                  Check Your Quote
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  className="btn btn-toggle align-items-center rounded collapsed"
                  to="/confirmed"
                >
                  Confirmed Order
                </Link>
              </li>
              <li className="mb-1">
                <Link
                  className="btn btn-toggle align-items-center rounded collapsed"
                  to="/account"
                >
                  My Account Info
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}

export default SidebarUser;
