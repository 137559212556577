import React from "react";
import axios from "axios";
// import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import Header from "../Header";
import SidebarUser from "../SideBar2";

class UserAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      userData: {},
    };
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}user/getuser`, config)
          .then((res) => {
            this.setState({ userData: res.data });
            console.log(res);
          });
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SidebarUser />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">My Account</h1>
                <Link
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                  to="/updateaccount"
                >
                  Update Account
                </Link>
              </div>
              <div className="table-sec">
              <div className="table-responsive">
                <table className="table table-borderless table-hover">
                  <thead className="border-bottom">
                    <tr>
                      <th>First Name</th>
                      <th>Phone Number</th>
                      <th>Age</th>
                      <th>Account Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.userData.firstName}</td>
                      <td>{this.state.userData.phoneNo}</td>
                      <td>{this.state.userData.age}</td>
                      <td>{this.state.userData.accountType}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default UserAccount;
