import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

class AddAdminStore extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      vendorName: "",
      location: "",
      loading: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  requestBodyBuilder = () => {
    var data = {
      storeName: this.state.vendorName,
      location: this.state.location,
    };
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.props.location.options?.isEditMode) {
      this.submitOrderEdit(event);
    } else {
      // let config = this.state.user?.token
      //   ? { headers: { authorization: `Token ${this.state.user.token}` } }
      //   : {};
      console.log("state=>", this.state, this.state.config);
      // const data = this.requestBodyBuilder();
      // console.log(data);
      var data = this.requestBodyBuilder();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}adminstore/add`,
          data,
          this.state.config
        )
        .then((res) => {
          if (res) {
            this.props.history.push("/admin/view-vendors");
          }
        })
        .catch(({ response }) => this.setState({ loading: false }));
    }
  };

  submitOrderEdit = (event) => {
    // let config = this.state.user?.token
    //   ? { headers: { authorization: `Token ${this.state.user.token}` } }
    //   : {};
    // const newData = this.requestBodyBuilder();
    // console.
    var data = {
      inventory: this.state.inventory,
      vendor: this.state.vendor,
      userType: this.state.userType,
      currentStock: this.state.currentStock,
      location: this.state.location,
      id: this.state.id,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}stock/update`,
        data,
        this.state.config
      )
      .then((res) => this.props.history.goBack())
      .catch((err) => console.log(err));
  };

  initialiseStateWithData(data) {
    this.setState({
      // user: data.user,
      inventory: data.inventory._id,
      vendor: data.user._id,
      currentStock: data.currentStock,
      id: this.props.location.options.id,
    });
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);
    if (userData) {
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        this.setState({ config: config });
        if (this.props.location.options?.isEditMode) {
          var id = this.props.location.options.id;
          axios
            .get(
              `${process.env.REACT_APP_API_URL}stock/getstock?id=${id}`,
              config
            )
            .then((res) => {
              this.initialiseStateWithData(res.data[0]);
            });
        }
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Add Admin Store</h1>
              </div>

              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="vendorName">
                      Store Name
                    </label>
                    <input
                      type="text"
                      id="vendorName"
                      className="form-control"
                      value={this.state.vendorName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="location">
                      Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      value={this.state.location}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    Add Store
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default AddAdminStore;
