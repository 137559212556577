import React from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import Header from "../Header";

class SignUpWithOtp extends React.Component {
  constructor() {
    super();
    this.changePhoneNumber = (event) =>
      this.setState({ phoneNumber: event.target.value });
    this.changeOtp = (event) => this.setState({ otp: event.target.value });

    this.state = {
      phoneNumber: "",
      otp: "",
      isOtpGenerated: false,
      error: "",
      loading: false,
    };
  }

  sendOtp = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}user/generateotp`, {
        number: "+91" + this.state.phoneNumber,
      })
      .then((res) => {
        if (res.data.token) {
          this.setState({
            token: res.data.token,
            isOtpGenerated: true,
            loading: false,
          });
        }
        console.log(res);
      });
  };

  verifyOtp = (event) => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}user/verifyotp`, {
        otp: this.state.otp,
        otpToken: this.state.token,
      })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem("user", JSON.stringify(res.data));
          if (this.props.location.options?.isAfterSignIn) {
            this.props.history.push({
              pathname: "/orders",
              options: this.props.location?.options,
            });
          } else {
             this.props.history.push({
            pathname: "/",
          });
          }
          // this.props.history.push({
          //   pathname: "/",
          // });
        }
        console.log("resss", res);
      })
      .catch(({ response }) => {
        console.log(response);
        if (response) {
          this.setState({ error: response.data?.errors?.message });
        }
      });
  };

  // submitForm(event) {
  //   event.preventDefault();
  //   axios
  //     .post(`${process.env.REACT_APP_API_URL}user/signup`, {
  //       password: this.state.password,
  //       confirmPassword: this.state.confirmPassword,
  //       lastName: this.state.lastName,
  //       firstName: this.state.firstName,
  //       email: this.state.email,
  //       userType: this.state.userType,
  //     })
  //     .then((res) => console.log(res));
  // }

  render() {
    return (
      <>
        <Header isAuthPage={true} />
        <div className="auth-page">
          <div className="container">
            <div className="row  h-100">
              <div className="auth-box col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 px-md-5 py-md-5 px-4 py-4 rounded-6  border">
                <div className="mb-5 text-center">
                  <h2 className="text-center fw-bold pt-4">Sign In</h2>
                  <small className="text-sm fw-light">
                    Enter your mobile number below to login
                  </small>
                </div>

                <form>
                  <fieldset>
                    <fieldset className="form-group form-outline mb-4">
                      <div className="input-group">
                        <span class="input-group-text br-0 border-gray bg-white">
                          +91
                        </span>
                        <input
                          className="form-control py-2 border rounded-3"
                          type="text"
                          placeholder="Phone Number"
                          value={this.state.phoneNumber}
                          onChange={this.changePhoneNumber}
                        />
                      </div>
                    </fieldset>
                    {this.state.isOtpGenerated && (
                      <>
                        <fieldset className="form-group form-outline mb-4">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="OTP"
                            value={this.state.otp}
                            onChange={this.changeOtp}
                          />
                        </fieldset>
                        <small className="text-danger">
                          {this.state.error}
                        </small>
                        <button
                          className="btn py-2 mb-4 btn-primary2 rounded-3 w-100"
                          onClick={(event) => this.verifyOtp(event)}
                          type="submit"
                        >
                          Verify OTP
                        </button>
                      </>
                    )}
                    <button
                      className="btn py-2 mb-4 btn-primary2 rounded-3 w-100"
                      onClick={(event) => this.sendOtp(event)}
                      type="submit"
                      disabled={this.state.loading}
                    >
                      {this.state.isOtpGenerated ? "Resend OTP" : "Send OTP"}
                    </button>
                  </fieldset>
                </form>
                {/* <p className="text-center py-2 mb-4 ">
                  <Link className="text-dark" to="./signin">
                    Have an account? SignIn
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignUpWithOtp;
