import axios from "axios";
import React, { Component } from "react";
import Header from "../Header";
import SideBar from "../SideBar";

export default class ViewEnquiries extends Component {
   state={
       enquiries:[]
   } 
  getMaterials = () => {
    axios.get(`${process.env.REACT_APP_API_URL}enquiry/`).then((res) => {
      this.setState({ enquiries: res.data.enquiries });
      console.log(res.data.enquiries);
    });
  };

  disableMaterial = () => {
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = {
      id: this.state.id,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}material/disable`, data, config)
      .then((res) => {
        this.getMaterials();
      });
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: user });
      this.getMaterials();
      this.setState({ user: userData });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Enquiry</h1>
              </div>

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Title</th>
                        <th>Message</th>
                        <th>Phone No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.enquiries.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>{item.fullName}</td>
                          <td>{item.email}</td>
                          <td>{item.title}</td>
                          <td>{item.message}</td>
                          <td>{item.phoneNo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
