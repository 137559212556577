import React from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import axios from "axios";
import Toggle from "react-toggle";
import "react-toggle/style.css"

export default class EditContractors extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      firstName: "",
      phoneNo: "",
      pincode: Number,
      userType: "Fabricator",
      location: "",
      rating: "",
      approved: Boolean,
      loading: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  requestBodyBuilder = () => {
    var data = {
      id: this.state.id,
      firstName: this.state.firstName,
      phoneNo: this.state.phoneNo,
      pincode: this.state.pincode,
      location: this.state.location,
      rating: this.state.rating,
      approved:this.state.approved
    };
    console.log("data", data);
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let config = { headers: { authorization: `Token ${this.state.token}` } };
    let data = this.requestBodyBuilder();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}user/update-contractorsList`,
        data,
        config
      )
      .then((res) => {
        console.log("res", res);
        if (res) {
          this.props.history.push("/admin/view-contractors");
        }
      })
      //   .catch(({ response }) => this.setState({ loading: false }));
      .catch((err) => console.log(err));
  };

  populateData = (data) => {
    this.setState({
      firstName: data.firstName,
      phoneNo: data.phoneNo,
      pincode: data.pincode,
      location: data.location,
      rating: data.rating,
      approved: data.approved,
    });
    console.log('pop',this.state);
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);
    let id = this.props.match.params.id;
    this.setState({ id: id });
    if (userData) {
      this.setState({ user: userData, token: userData.token, id: id });
      let config = {
        headers: { authorization: `Token ${user.token}` },
        params: { id: id },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}user/get-contractorsList/`,
          config
        )
        .then((res) => {
          console.log("res", res);
          console.log('st',this.state);
          if (res.data.contractor[0]) {
            this.populateData(res.data.contractor[0]);
          }
        });
    }
  }

  handleCheeseChange=(e)=>{
    console.log('e',e.target.checked);
    this.setState({approved:e.target.checked})
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Edit Contractor</h1>
              </div>
              <div className="col-md-6">
                <form>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      className="form-control"
                      name="brandName"
                      value={this.state.firstName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Mobile Number
                    </label>
                    <input
                      type="text"
                      id="phoneNo"
                      className="form-control"
                      name="brandName"
                      value={this.state.phoneNo}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Pincode
                    </label>
                    <input
                      type="text"
                      id="pincode"
                      className="form-control"
                      name="brandName"
                      value={this.state.pincode}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Location Name
                    </label>
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      name="brandName"
                      value={this.state.location}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Rating (out of 5)
                    </label>
                    <input
                      type="text"
                      id="rating"
                      className="form-control"
                      name="brandName"
                      value={this.state.rating}
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="form-outline mb-4 d-flex">
                    <label className="form-label" htmlFor="weight">
                      Approve as Loham Expert:
                    </label>
                    <div>
                      <Toggle
                        className="ms-3"
                        id="approved"
                        name="brandName"
                        checked={this.state.approved}
                        onChange={this.handleCheeseChange}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                    onClick={(event) => this.submitOrder(event)}
                  >
                    Update
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
