import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import Length from "./Length";
import InventoryVariant from "./InventoryVariant";
import Colors from "./Colors";
import LengthSelect from "./LengthSelect";
import toast, { Toaster } from "react-hot-toast";

class EditInventoryBulk extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      user: "",
      materialName: "",
      materialCode: "",
      brand: "",
      materialTypeId: "",
      sectionId: "",
      color: "",
      weight: "",
      threshold: "",
      inventoryType: 0,
      inventory: [],
      materials: [],
      sections: [],
      brands: [],
      loading: false,
      variants: [{ variantName: "", id: 0 }],
      inventoryVariants: [{ materialCode: "", weight: "", id: 0 }],
      isColor: "false",
      colors: [],
      colorId: 0,
      lengthVariants: [],
    };
  }
  handleFileChange = (event) => {
    this.setState({
      inventory: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleInventoryTypeChange = (event) => {
    this.setState({ [event.target.id]: Number(event.target.value) });
  };

  handleVariant = (id, value) => {
    let variant = [...this.state.variants];
    let temp = { ...variant[id], variantName: value };
    variant[id] = temp;
    this.setState({ variants: variant }, () =>
      console.log(this.state.variants)
    );
  };

  handleInventoryVariant = (id, data) => {
    let temp = this.state.inventoryVariants;
    temp[id].materialCode = data.materialCode;
    temp[id].weight = data.weight;
    this.setState({ inventoryVariants: temp }, () =>
      console.log(this.state.inventoryVariants, data)
    );
  };

  addVariant = () => {
    this.setState({
      variants: [
        ...this.state.variants,
        { variantName: "", id: this.state.variants.length },
      ],
    });
  };

  deleteVariant = (id) => {
    var tempList = this.state.variants.filter((item, index) => index !== id);
    this.setState({ variants: tempList });
  };

  addInventoryVariant = () => {
    this.setState({
      inventoryVariants: [
        ...this.state.inventoryVariants,
        {
          materialCode: "",
          weight: "",
          id: this.state.inventoryVariants.length,
        },
      ],
    });
  };

  deleteInventoryVariant = (id) => {
    if (this.state.inventoryVariants.length > 1) {
      var tempList = this.state.inventoryVariants.filter(
        (item, index) => index !== id
      );
      this.setState({ inventoryVariants: tempList });
    }
  };

  deleteColors = (id) => {
    var tempList = this.state.colors.filter((item, index) => item.id !== id);
    this.setState({ colors: tempList });
  };

  addColors = () => {
    this.setState({
      colors: [...this.state.colors, { name: "", id: this.state.colorId + 1 }],
      colorId: this.state.colorId + 1,
    });
  };

  // handleColors = (id, value) => {
  //   let colors = [...this.state.colors];
  //   for (const color of colors) {
  //     if (color.id === id) color.name = value;
  //   }
  //   this.setState({ colors });
  // };

  handleColors = (value) => {
    this.setState({ colors: value });
  };

  handleLength = (value) => {
    this.setState({ lengthVariants: value });
  };

  // handleFileChange = (event) => {
  //   this.setState({
  //     drawing: event.target.files[0],
  //   });
  // };

  handleMaterialTypeChange = (e) => {
    this.setState({ materialTypeId: e.target.value });
    let data = { params: { materialTypeId: e.target.value } };
    axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
      console.log(res, "rrrrrrrrrrrrres");
      this.setState({ sections: res.data.sections });
    });
  };

  requestBodyBuilder = () => {
    const data = new FormData();
    data.append("materialCode", this.state.materialCode);
    data.append("materialName", this.state.materialName);
    data.append("brandId", this.state.brand);
    data.append("materialTypeId", this.state.materialTypeId);
    data.append("finish", this.state.finish);
    if (this.state.isColor === "true") {
      data.append(
        "colors",
        JSON.stringify(this.state.colors.map((i) => i.value.toUpperCase()))
      );
    }
    data.append(
      "lengthVariants",
      JSON.stringify(this.state.lengthVariants.map((i) => i.value))
    );
    data.append("isColor", this.state.isColor);
    data.append("inventoryType", this.state.inventoryType);
    if (this.state.weight && this.state.inventoryType === 0)
      data.append("weight", this.state.weight);
    data.append("threshold", this.state.threshold);
    data.append("sectionId", this.state.sectionId);
    data.append("id", this.state.id);
    data.append("variants", JSON.stringify(this.state.variants));
    data.append(
      "inventoryVariants",
      JSON.stringify(this.state.inventoryVariants)
    );
    if (this.state.inventory) data.append("inventory", this.state.inventory);
    return data;
  };

  validate = () => {
    console.log(this.state);

    if (
      this.state.lengthVariants.length === 0 &&
      this.state.inventoryType === 1
    ) {
      toast.error("Length is required");
      return false;
    }
    // if (this.state.colors.length === 0) {
    //   toast.error("Color is required");
    //   return false;
    // }
    return true;
  };

  submitOrder = (event) => {
    event.preventDefault();
    console.log("AAAA");
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    if (this.state.inventoryVariants.length === 0) return;
    else {
      if (!this.validate()) return;
      const newData = this.requestBodyBuilder();
      newData.append(
        "colors",
        JSON.stringify(this.state.colors.map((item) => item.value))
      );
      axios
        .put(
          `${process.env.REACT_APP_API_URL}inventory/update`,
          newData,
          config
        )
        .then((res) =>
          this.props.history.push({
            pathname: "/admin/viewinventory",
          })
        )
        .catch((err) => console.log(err));
    }
  };

  initialiseStateWithData(data) {
    this.setState({
      brand: data.brand,
      sectionId: data.section._id,
      // materialCode: data.materialCode,
      materialTypeId: data.materialType._id,
      materialName: data.materialName,
      color: data.color,
      // weight: data.weight,
      threshold: data.threshold,
      inventoryType: data.inventoryType || 0,
      inventory: data.drawing,
      preview: process.env.REACT_APP_IMAGE_URL + data.drawing,
      isColor: data.isColorAvailable || "false",
      finish: data.finish,
      colors: data.colors.length
        ? data.colors.map((i) => ({ value: i, label: i }))
        : [],
      lengthVariants: data.lengthVariants.length
        ? data.lengthVariants.map((i) => ({ value: i, label: i }))
        : [],
      colorId: data.colorId,
    });
  }

  getBrands = () => {
    axios.get(`${process.env.REACT_APP_API_URL}brand/`, {}).then((res) =>
      // console.log(res.data)
      this.setState({
        brands: res.data.brands,
      })
    );
  };

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}material/`, {}).then((res) =>
      this.setState({
        materials: res.data.materialTypes,
      })
    );
    this.getBrands();
    var user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({
        user: user,
        token: user.token,
        id: this.props.match.params.id,
      });
      let config = {
        headers: { authorization: `Token ${user.token}` },
      };
      axios
        .get(
          `${process.env.REACT_APP_API_URL}inventory/getinventory?id=${this.props.match.params.id}`,
          config
        )
        .then((res) => {
          let data = {
            params: {
              materialTypeId: res.data.inventory.materialType._id,
            },
          };
          axios
            .get(`${process.env.REACT_APP_API_URL}section/`, data)
            .then((response) => {
              console.log(response, "rrrrrrrrrrrrres");
              this.setState({
                sections: response.data.sections,
                variants: res.data.inventory.variants,
                inventoryVariants: res.data.inventory.inventoryVariants,
              });
            });
          this.initialiseStateWithData(res.data.inventory);
        });
    }
  }

  render() {
    console.log("tttt ", this.state);
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  {" "}
                  {this.props.location?.options?.isEditMode
                    ? "Edit Inventory"
                    : "Add Inventory"}
                </h1>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                >
                  Button
                </button> */}
              </div>
              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialName">
                          Material Name
                        </label>
                        <input
                          type="text"
                          id="materialName"
                          className="form-control"
                          value={this.state.materialName}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="brand">
                          Brand
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="brand"
                          value={this.state.brand}
                          onChange={this.handleInputChange}
                        >
                          <option value="" disabled selected>
                            Select Brand
                          </option>
                          {this.state.brands.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.brandName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materialType">
                      Material Type
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="materialTypeId"
                      value={this.state.materialTypeId}
                      onChange={this.handleMaterialTypeChange}
                    >
                      <option value="" disabled selected>
                        Select Material Type
                      </option>
                      {this.state.materials.map((material, index) => (
                        <option key={`material${index}`} value={material._id}>
                          {material.materialType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="sectionId">
                      Category
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="sectionId"
                      value={this.state.sectionId}
                      onChange={this.handleInputChange}
                    >
                      <option value="" disabled selected>
                        Select Category
                      </option>
                      {this.state.sections.map((item, index) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.inventoryVariants.map((item, index) => (
                    <InventoryVariant
                      key={item.id}
                      isDisabled={this.state.inventoryVariants.length}
                      id={index}
                      inventoryType={this.state.inventoryType}
                      handleVariant={this.handleInventoryVariant}
                      data={item}
                      deleteVariant={this.deleteInventoryVariant}
                    />
                  ))}
                  <div className="form-outline row mb-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => this.addInventoryVariant()}
                    >
                      <i className="bi bi-plus-circle pe-2 " />
                      Add Material
                    </button>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materialType">
                      Inventory Type
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="inventoryType"
                      value={this.state.inventoryType}
                      onChange={this.handleInventoryTypeChange}
                    >
                      <option value={0} selected>
                        Length
                      </option>
                      <option value={1}>Area</option>
                      <option value={2}>Pieces</option>
                    </select>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="finish">
                          Finish
                        </label>
                        <input
                          type="text"
                          id="finish"
                          className="form-control"
                          value={this.state.finish}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="threshold">
                          Threshold (kg)
                        </label>
                        <input
                          type="text"
                          id="threshold"
                          className="form-control"
                          value={this.state.threshold}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="finish">
                          Is Color available
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="isColor"
                          value={this.state.isColor}
                          onChange={this.handleInputChange}
                        >
                          <option value={"false"} selected>
                            No
                          </option>
                          <option value={"true"}>Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <Colors
                    isDisabled={this.state.isColor === "false"}
                    handleColors={this.handleColors}
                    colors={this.state.colors}
                  />
                  {/* {this.state.colors.map((item) => (
                    <Colors
                      key={item.id}
                      isDisabled={this.state.colors.length}
                      isColor={this.state.isColor}
                      id={item.id}
                      handleColors={this.handleColors}
                      data={item.name}
                      deleteColors={this.deleteColors}
                    />
                  ))} */}

                  {/* <div className="form-outline row mb-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={this.addColors}
                    >
                      <i className="bi bi-plus-circle pe-2 " />
                      Add Color
                    </button>
                  </div> */}
                  {/* <div className="row mb-4"> */}

                  <div className="form-outline row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="drawing">
                          Drawing
                        </label>
                        <input
                          type="file"
                          id="inventory"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={this.handleFileChange}
                        />
                      </div>
                      {this.state.preview && (
                        <img
                          alt=""
                          className="uploaded-preview mt-4"
                          src={this.state.preview}
                        />
                      )}
                    </div>
                  </div>
                  {this.state.inventoryType !== 2 &&
                    this.state.variants.map((item, index) => (
                      <Length
                        key={item.id}
                        isDisabled={this.state.variants.length}
                        id={index}
                        inventoryType={this.state.inventoryType}
                        handleVariant={this.handleVariant}
                        data={item}
                        deleteVariant={this.deleteVariant}
                      />
                    ))}
                  {this.state.inventoryType !== 2 && (
                    <div className="form-outline row mb-4">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.addVariant()}
                      >
                        <i className="bi bi-plus-circle pe-2 " />
                        Add Variant
                      </button>
                    </div>
                  )}
                  {this.state.inventoryType === 1 && (
                    <LengthSelect
                      isDisabled={this.state.inventoryType !== 1}
                      handleLength={this.handleLength}
                      lengthVariants={this.state.lengthVariants}
                    />
                  )}
                  {/* </div> */}
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    {this.props.location?.options?.isEditMode
                      ? "Edit Inventory"
                      : "Add Inventory"}
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default EditInventoryBulk;
