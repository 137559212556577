import React, { useEffect, useMemo, useState } from "react";
import MultiCreatableSelectWithoutDropDown from "./MultiCreatableSelectWithoutDropDown";

const LengthSelect = ({ isDisabled, handleLength, lengthVariants }) => {
  const [value, setValue] = useState([]);

  const handleInputChange = (values) => {
    setValue(values);
    handleLength(values);
  };

  const options = useMemo(() => [], []);

  useEffect(() => {
    setValue(lengthVariants);
  }, [lengthVariants]);

  return (
    <div className="form-outline mb-4">
      <label className="form-label" htmlFor="variantName">
        Length
      </label>
      <div className="w-100 ">
        <MultiCreatableSelectWithoutDropDown
          onChange={handleInputChange}
          value={value}
          options={options}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default LengthSelect;
