import React, { Component } from "react";

export default class LengthComponent extends Component {
  state = {
    currentStock: 0,
    totalWeight: 0,
  };

  handleWeightChange = (e) => {
    let currentStock = parseInt(e.target.value / this.props.weight);
    this.setState({
      totalWeight: e.target.value,
      currentStock: currentStock,
    });
    let data = {
      totalWeight: e.target.value,
      currentStock: currentStock,
    };
    this.props.handleDataFromComponents(data);
  };

  handleStockChange = (e) => {
    // console.log("this.props ", e.target.value , this.props.weight , Number(this.props.updateValues.variantName));
    let totalWeight = e.target.value * this.props.weight * Number(this.props.updateValues.variantName);
    this.setState({
      currentStock: e.target.value,
      totalWeight: totalWeight,
    });
    let data = {
      totalWeight: totalWeight,
      currentStock: e.target.value,
    };
    this.props.handleDataFromComponents(data);
  };

  render() {
    return (
      <>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="currentStock">
            Stock
          </label>
          <input
            type="number"
            id="currentStock"
            className="form-control"
            value={this.state.currentStock}
            onChange={this.handleStockChange}
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="weight">
            Total Weight (kg)
          </label>
          <input
            type="number"
            id="totalWeight"
            className="form-control"
            value={this.state.totalWeight}
            onChange={this.handleWeightChange}
          />
          <small className="text-danger">
            {this.state.error?.weightError && this.state.error.weightError}
          </small>
        </div>
      </>
    );
  }
}
