import React, {  } from 'react'
import Header from "../Header";
import SideBar from "../SideBar";
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';
import toast from 'react-hot-toast';

function ViewE2eEnquiries(){

  const[user,setUser] = useState([])
  const [contractors, setContractors] = useState([]);
  const [userData,setUserData] = useState([])
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [viewContractor,setviewContractor] = useState([])
  const [viewBundle,setviewBundle] = useState([])
  const [viewUser,setviewUser] = useState([])
  const [approved,setApproved] = useState()



  useEffect(() => {
   axios.get(`${process.env.REACT_APP_API_URL}enquiry/get-end-enquiry`)
   .then((res)=>{
    console.log('enquiry',res.data.enquiries);
    setUser(res.data.enquiries)
   }) 
  }, [])

 
  const userIds = user.map((enquiry) => enquiry.userId);
  const contractorIds = user.map((enquiry) => enquiry.contractorId);

  console.log('user',userIds);
  console.log('contractor',contractorIds);

  useEffect(() => {
    const fechdata = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "user/getcontractor/" + contractorIds
        );
        console.log("c", response.data.users);
        setContractors(response.data.users);
      } catch (error) {
        console.log(error);
      }
    };
    fechdata();
  },[user]);
  console.log("contractor", contractors);



  const handleshow = async(id,uid,cid)=>{
    try {
      const Ids={
        id,
        cid,
        uid
      }
      setShow(true)
      const response = await axios.post(
        process.env.REACT_APP_API_URL  + "enquiry/view-end-enquiry" ,Ids
      );
      console.log('modal',response);
      setviewContractor(response.data.contractor)
      setviewBundle(response.data.enquiries)
      setviewUser(response.data.user)
      setApproved(response.data.enquiries.contacted);
    }
    catch{

    }
  }

  const handleCheeseChange=(e)=>{
    setApproved(e.target.checked);
  }
  console.log('approval',approved);



  const handleUpdate=async(id)=>{
    try{
      const data={
        id,
        approved
      }
      const res = await axios.post(
        process.env.REACT_APP_API_URL  + "enquiry/update-end-enquiry" ,data
      );
      toast.success("Updated");
      window.location.reload()
    }
    catch(error){
      console.log(error);
    }
  }
 
  

  useEffect(() => {
    const fechuser = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "user/get-users/" + userIds
        );
        console.log("u", response.data.users);
        setUserData(response.data.users)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fechuser();
  },[user]);
  console.log("user", userData);


    return (
      <div>
        <Header />
        <div className='container-fluid'>
            <div className='row'>
                <SideBar/>
                <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">E2E Enquiry</h1>
              </div>
              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>User Name</th>
                        <th>U-Phone No</th>
                        <th>Message</th>
                        <th>Date</th>
                        <th>Solution </th>
                        <th>Contractor</th>
                        <th>C-Phone No</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                
                      {userData.map((u,index)=>{
                         const c = contractors[index];
                         const n = user[index];
                         return (
                          <tr key={index}>
                            <td>{index+1}</td>
                            <td>{u.firstName}</td>
                            <td>{u.phoneNo}</td>
                            <td>{n.message?n.message.slice(0,15):'--------'}</td>
                            <td>{moment(n.createdAt).format("MMM Do YY")}</td>
                            <td>{n ? n.solutionName : 'No Solution'}</td>
                            <td>{c ? c.firstName : '--------'}</td>
                            <td>{c ? c.phoneNo : '------------------'}</td>
                            <td>{n.contacted==true?"Contacted":'Not Contacted'}</td>
                            <td>
                            <button
                              className="btn btn-sm btn-outline-secondary"
                              onClick={()=>handleshow(n?._id,u?._id,c?._id)}
                            >
                              View
                            </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
                </main>

            </div>
        </div>

      <Modal 
      size="lg"
      aria-labelledby="contained-modal-title"
      centered show={show} onHide={handleClose}>
        <Modal.Header closeButton
        >
          <h3>Solution : <b>{viewBundle.solutionName}</b></h3>
          {/* <h3>Date : {moment(viewBundle.createdAt).format("MMM Do YY")}</h3> */}
        </Modal.Header>
        <Modal.Body>
            <div style={{ display:"flex", justifyContent:'space-between' }}>
             <div>
                <h1>User Details</h1>
                <h5>Name : <b className='text-success'>{viewUser.firstName}</b></h5>
                <h5>Phone : <b className='text-success'>{viewUser.phoneNo}</b></h5>
                <div className="form-outline mb-4 d-flex">
                    <b className="form-label" htmlFor="weight">
                      Contacted
                    </b>
                    <div>
                      <Toggle
                        className="ms-3"
                        id="contacted"
                        checked={approved}
                        onChange={handleCheeseChange}
                      />
                    </div>
                  </div>
                  <button style={{background:'green',color:'white'}}
                  onClick={()=>handleUpdate(viewBundle._id)}>Update</button>
             </div>
             <div>
                <h1>Contractor Details</h1>
                <h5>Name : <b className='text-danger'>{viewContractor?viewContractor.firstName:'No Contractor'}</b></h5>
                <h5>Phone : <b className='text-danger'>{viewContractor?viewContractor.phoneNo:'NOt selected'}</b></h5>
                <h5>Location : <b className='text-danger'>{viewContractor?viewContractor.location:'not selected'}</b></h5>
             </div>
            </div>
            <h3 className='text-center mt-3'>Message</h3>
            <div className="form-control"
             style={{width:'100%',border:'2px solid',height:'80px',wordWrap:'break-word',overflow:'auto'}}>
              <b >{viewBundle.message}</b>
            </div>
        </Modal.Body>
      </Modal>
        </div>
    )
  }
export default ViewE2eEnquiries
