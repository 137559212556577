import React, { Component } from "react";

export default class Length extends Component {
  state = {
    variantName: this.props.data.variantName,
  };

  handleInputChange = (e) => {
    this.setState({ variantName: e.target.value });
    this.props.handleVariant(this.props.id, e.target.value);
  };

  render() {
    console.log(typeof this.props.inventoryType);
    return (
      <div className="form-outline mb-4 d-flex">
        <label className="form-label" htmlFor="variantName">
          {this.props.inventoryType === 0
            ? "Length (m) "
            : this.props.inventoryType === 1 && "Width (m)"}
        </label>
        <input
          type="number"
          id="varientName"
          className="form-control"
          value={this.state.variantName}
          onChange={this.handleInputChange}
        />
        <div>
          <button
            className="btn"
            disabled={this.props.isDisabled === 1}
            onClick={() => this.props.deleteVariant(this.props.id)}
          >
            <i className="bi bi-trash-fill" />
          </button>
        </div>
      </div>
    );
  }
}
