// import { Link } from 'react-router-dom';
import React from "react";
import axios from "axios";
import Header from "../Header";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SideBar from "../SideBar";

class SignUp extends React.Component {
  constructor() {
    super();
    this.changeEmail = (event) => this.setState({ email: event.target.value });
    this.changePassword = (event) =>
      this.setState({ password: event.target.value });
    this.changeConfirmPassword = (event) =>
      this.setState({ confirmPassword: event.target.value });
    this.changeLastName = (event) =>
      this.setState({ lastName: event.target.value });
    this.changeFirstName = (event) =>
      this.setState({ firstName: event.target.value });
    this.changeUserType = (event) =>
      this.setState({ userType: event.target.value });
    this.changeAdminType = (event) =>
      this.setState({ adminType: event.target.value });
    this.changeVendorName = (event) =>
      this.setState({ vendorName: event.target.value });
    this.changePhoneNo = (event) =>
      this.setState({ phoneNo: event.target.value });

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      confirmPassword: "",
      password: "",
      userType: "admin",
      adminType: "superAdmin",
      vendorName: "",
      phoneNo: "",
      showModal: false,
    };
  }

  handleModal = (modalState) => {
    this.setState({ showModal: modalState });
  };

  submitForm(event) {
    event.preventDefault();
    let config = this.state.user?.token
      ? { headers: { authorization: `Token ${this.state.user.token}` } }
      : {};

    let data = {
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      email: this.state.email,
      userType: this.state.userType,
      vendorName: this.state.vendorName,
      phoneNo: this.state.phoneNo,
    };
    if (this.state.userType == "admin") {
      data.adminType = this.state.adminType;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}user/signup`, data, config)
      .then((res) => {
        if (res.data.message) {
          this.handleModal(true);
        }
      });
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + userData.token,
          },
        })
        .then((res) => {
          if (res.data.users.adminType !== "superAdmin") {
            this.props.history.push("/admin");
          }
          this.setState({ user: userData, token: userData.token });
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    }
  }

  render() {
    return (
      <>
        <Header isAuthPage={true} />
        <div className="auth-page">
          <SideBar />
          <div className="container">
            <div className="row justify-content-center">
              <div className="auth-box col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 px-md-5 py-md-5 px-4 py-4 rounded-6  border">
                <h2 className="text-center mb-5">Create Admin User</h2>
                <Modal
                  show={this.state.showModal}
                  onHide={() => this.handleModal(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Success</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>User Added</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary2"
                      onClick={() => this.handleModal(false)}
                    >
                      Okay
                    </Button>
                  </Modal.Footer>
                </Modal>
                <form onSubmit={(event) => this.submitForm(event)}>
                  <fieldset>
                    <fieldset className="form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        value={this.state.firstName}
                        onChange={this.changeFirstName}
                      />
                    </fieldset>

                    <fieldset className="form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.changeLastName}
                      />
                    </fieldset>

                    <fieldset className="form-group form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.changeEmail}
                      />
                    </fieldset>
                    <fieldset className="form-group form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone No"
                        value={this.state.phoneNo}
                        onChange={this.changePhoneNo}
                      />
                    </fieldset>

                    <fieldset className="form-group form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.changePassword}
                      />
                    </fieldset>

                    <fieldset className="form-group form-group form-outline mb-4">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.changeConfirmPassword}
                      />
                    </fieldset>

                    <fieldset className="form-group form-group form-outline mb-4">
                      <label className="form-label" htmlFor="materialType">
                        User Type
                      </label>
                      <select
                        className="form-control form-select col-12"
                        id="materialType"
                        value={this.state.userType}
                        onChange={this.changeUserType}
                      >
                        <option key={"admin"} value={"admin"}>
                          Admin
                        </option>
                        {/* <option key={"staff"} value={"staff"}>
                          Staff
                        </option> */}
                        <option key={"vendor"} value={"vendor"}>
                          Vendor
                        </option>
                      </select>
                    </fieldset>

                    {this.state.userType === "admin" && (
                      <>
                        <fieldset className="form-group form-group form-outline mb-4">
                          <label className="form-label" htmlFor="materialType">
                            Admin Type
                          </label>
                          <select
                            className="form-control form-select col-12"
                            id="adminType"
                            value={this.state.adminType}
                            onChange={this.changeAdminType}
                          >
                            <option key={"superAdmin"} value={"superAdmin"}>
                              Super Admin
                            </option>
                            <option key={"mainStaff"} value={"mainStaff"}>
                              Main Staff
                            </option>
                            <option key={"staff"} value={"staff"}>
                              Staff
                            </option>
                          </select>
                        </fieldset>
                      </>
                    )}

                    <fieldset
                      className="form-group form-group form-outline mb-4"
                      style={{
                        display:
                          this.state.userType === "vendor" ? "block" : "none",
                      }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Vendor Name"
                        value={this.state.vendorName}
                        onChange={this.changeVendorName}
                      />
                    </fieldset>

                    <button
                      className="btn btn-primary2 w-100 btn-lg  pull-xs-right"
                      type="submit"
                    >
                      {/* disabled={this.props.inProgress}> */}
                      Sign up
                    </button>
                  </fieldset>
                </form>
                {/* <p className="text-xs-center">
                  <Link to="./signin">Have an account?</Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SignUp;
