import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Header from "../Header";
import SideBar from "../SideBar";
import BundleItem from "./BundleItem";
import Contractors from "./Contractors";


const UpdateBundle = ({ props }) => {
  const history = useHistory();
  const params = useParams();
  const [bundleName, setBundleName] = useState("");
  const [drawing, setDrawing] = useState([]);
  const [preview, setPreview] = useState([]);
  const [materialTypeList, setMaterialTypeList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [bundleItems, setBundleItems] = useState([
    {
      inventory: "",
      section: "",
      materialType: "",
      brand: "",
      id: 1,
      //   variant: "",
    },
  ]);

  useEffect(() => {
    console.log("hhh ", params.id);
    axios
      .get(`${process.env.REACT_APP_API_URL}bundle/get-bundle/${params.id}`)
      .then((res) => {
        console.log("rrr ", res.data.data);
        setBundleName(res.data.data.name);
        setDrawing(res.data.data.drawing);
        setPreview(process.env.REACT_APP_IMAGE_URL + res.data.data.drawing)
        setBundleItems(res.data.data.itemList)
        setSelectedContractors(res.data.data.contractors)
      }); 
  }, []);

  const handleContractorsChange = (selectedContractors) => {
    setSelectedContractors(selectedContractors);
  };

  const handleBundleName = ({ target }) => setBundleName(target.value);
  const handleFileChange = ({ target }) => {
    setDrawing(target.files[0]);
    setPreview(URL.createObjectURL(target.files[0]));
  };
  const getBrandList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}brand/list`
    );
    if (response.data.data) setBrandList(response.data.data);
  };

  const getMaterialTypeList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}material/list`
    );
    if (response.data.data) setMaterialTypeList(response.data.data);
  };

  const handleBundleItem = (item) => {
    let bundleItem = bundleItems.find((i) => i.id === item.id || i.id === item._id);
    bundleItem.inventory = item.inventory;
    bundleItem.section = item.section;
    bundleItem.materialType = item.materialType;
    bundleItem.brand = item.brand;
  };

  const addBundleItem = () => {
    let bundleItem = {
      inventory: "",
      section: "",
      materialType: "",
      brand: "",
      //   variant: "",
      id: bundleItems[bundleItems.length - 1].id + 1,
    };
    let newBundleItemList = [...bundleItems, bundleItem];
    setBundleItems(newBundleItemList);
  };

  const removeBundleItem = (id) => {
    if (bundleItems.length === 1) return;
    console.log("id", id);
    console.log("bundleItems", bundleItems);
    const bundleItemList = bundleItems.filter((i) => i.id !== id && i._id !== id);
    setBundleItems(bundleItemList);
  };

  const createRequestBody = () => {
    const data = new FormData();
    data.append("name", bundleName);
    data.append("bundleItems", JSON.stringify(bundleItems));
    data.append("bundle", drawing);
    return data;
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsDisabled(true);
      const user = JSON.parse(localStorage.getItem("user"));
      const data = createRequestBody();
      const contractorIds = selectedContractors.map((contractor) => contractor.value);
      data.append("contractorIds", JSON.stringify(contractorIds));
      const config = {
        headers: { authorization: `Token ${user.token}` },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}bundle/update-bundle/${params.id}`,
        data,
        config
      );
      URL.revokeObjectURL(preview);
      if (response) {
        history.push("/admin/view-bundle");
      }
      setIsDisabled(false);
    } catch (error) {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    // getCategoryList();
    getMaterialTypeList();
    getBrandList();
  }, []);

  console.log("bundleItems", bundleItems);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2 fw-bold">Update Bundle</h1>
            </div>

            <div className="col-md-6">
              <form onSubmit={handleSubmit}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="weight">
                        Bundle Name
                      </label>
                      <input
                        type="text"
                        id="bundleName"
                        className="form-control"
                        name="bundleName"
                        required
                        onChange={handleBundleName}
                        value={bundleName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="drawing">
                        Drawing
                      </label>
                      <input
                        type="file"
                        id="drawing"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                    {preview && (
                      <img
                        alt=""
                        className="uploaded-preview mt-4"
                        src={preview}
                      />
                    )}
                  </div>
                </div>

                {bundleItems.map((i, index) => (
                  <BundleItem
                    key={i.id}
                    brandList={brandList}
                    // categoryList={categoryList}
                    materialTypeList={materialTypeList}
                    handleBundleItem={handleBundleItem}
                    id={i.id || i._id}
                    removeBundleItem={removeBundleItem}
                    item={i}
                  />
                ))}
                <Contractors selectedContractors={selectedContractors} onContractorsChange={handleContractorsChange}/>
                <div className="form-outline row mt-3 mb-4">
                  <button type="button" className="btn" onClick={addBundleItem}>
                    <i className="bi bi-plus-circle pe-2 " />
                    Add Item
                  </button>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary2 btn-block mb-4"
                  disabled={isDisabled}
                >
                  Update Bundle
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default UpdateBundle;
