import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import Length from "./Length";
import InventoryVariant from "./InventoryVariant";
import Colors from "./Colors";
import MultiCreatableSelect from "./MultiCreatableSelect";
import LengthSelect from "./LengthSelect";

class AddInventoryBulk extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      user: "",
      materialName: "",
      brand: "",
      materialTypeId: "",
      sectionId: "",
      finish: "",
      threshold: "",
      inventoryType: 0,
      inventory: [],
      materials: [],
      sections: [],
      brands: [],
      loading: false,
      variants: [{ variantName: "", id: 0 }],
      inventoryVariants: [{ materialCode: "", weight: "", id: 0 }],
      isColor: "false",
      colors: [{ name: "", id: 0 }],
      colorId: 0,
      lengthVariants: [],
    };
  }
  handleFileChange = (event) => {
    this.setState({
      inventory: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleInventoryTypeChange = (event) => {
    this.setState({ [event.target.id]: Number(event.target.value) });
    // , () => {
    //   if (event.target.value !== 0) {
    //     let temp = this.state.inventoryVariants.map((i) => {
    //       i.weight = 0;
    //       return i;
    //     });
    //     this.setState({ inventoryVariants: temp });
    //     console.log(temp)
    //   }
    // }
  };

  handleVariant = (id, value) => {
    let variant = [...this.state.variants];
    let temp = { ...variant[id], variantName: value };
    variant[id] = temp;
    this.setState({ variants: variant });
  };

  handleInventoryVariant = (id, data) => {
    let temp = this.state.inventoryVariants;
    temp[id].materialCode = data.materialCode;
    temp[id].weight = data.weight;
    this.setState({ inventoryVariants: temp }, () =>
      console.log(this.state.inventoryVariants, data)
    );
  };

  addVariant = () => {
    this.setState({
      variants: [
        ...this.state.variants,
        { variantName: "", id: this.state.variants.length },
      ],
    });
  };

  deleteVariant = (id) => {
    var tempList = this.state.variants.filter((item, index) => index !== id);
    this.setState({ variants: tempList });
  };

  addInventoryVariant = () => {
    this.setState({
      inventoryVariants: [
        ...this.state.inventoryVariants,
        {
          materialCode: "",
          weight: "",
          id: this.state.inventoryVariants.length,
        },
      ],
    });
  };

  deleteColors = (id) => {
    var tempList = this.state.colors.filter((item, index) => item.id !== id);
    this.setState({ colors: tempList });
  };

  addColors = () => {
    this.setState({
      colors: [...this.state.colors, { name: "", id: this.state.colorId + 1 }],
      colorId: this.state.colorId + 1,
    });
  };

  handleColors = (value) => {
    this.setState({ colors: value });
  };

  handleLength = (value) => {
    this.setState({ lengthVariants: value });
  };

  deleteInventoryVariant = (id) => {
    if (this.state.inventoryVariants.length > 1) {
      var tempList = this.state.inventoryVariants.filter(
        (item, index) => index !== id
      );
      this.setState({ inventoryVariants: tempList });
    } else return;
  };

  // handleFileChange = (event) => {
  //   this.setState({
  //     drawing: event.target.files[0],
  //   });
  // };

  handleMaterialTypeChange = (e) => {
    this.setState({ materialTypeId: e.target.value });
    let data = { params: { materialTypeId: e.target.value } };
    axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
      this.setState({ sections: res.data.sections });
    });
  };

  requestBodyBuilder = () => {
    const data = new FormData();
    data.append(
      "inventoryVariants",
      JSON.stringify(this.state.inventoryVariants)
    );
    data.append("materialName", this.state.materialName);
    data.append("brandId", this.state.brand);
    data.append("materialTypeId", this.state.materialTypeId);
    data.append("finish", this.state.finish);
    if (this.state.isColor === "true") {
      data.append(
        "colors",
        JSON.stringify(this.state.colors.map((i) => i.value.toUpperCase()))
      );
    }
    data.append(
      "lengthVariants",
      JSON.stringify(this.state.lengthVariants.map((i) => i.value))
    );
    data.append("isColor", this.state.isColor);
    data.append("inventoryType", this.state.inventoryType);
    // if (this.state.weight && this.state.inventoryType === 0)
    //   data.append("weight", this.state.weight);
    data.append("threshold", this.state.threshold);
    data.append("sectionId", this.state.sectionId);
    data.append("variants", JSON.stringify(this.state.variants));
    if (this.state.inventory) data.append("inventory", this.state.inventory);
    if (this.props.location.options?.isEditMode)
      data.append("id", this.props.location.options.id);
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    try {
      this.setState({ loading: true });
      if (this.props.location.options?.isEditMode) {
        this.submitOrderEdit(event);
      } else {
        const newData = this.requestBodyBuilder();
        let config = {
          headers: { authorization: `Token ${this.state.user.token}` },
        };
        axios
          .post(
            `${process.env.REACT_APP_API_URL}inventory/add`,
            newData,
            config
          )
          .then((res) => {
            if (res) {
              this.props.history.push({
                pathname: "/admin/viewinventory",
                options: { isAfterSignIn: true, data: this.state },
              });
            }
            console.log(res);
          })
          .catch(({ response }) => {
            console.log(response);
            this.setState({ error: response.data.message, loading: false });
          });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  submitOrderEdit = (event) => {
    let config = this.state.user?.token
      ? { headers: { authorization: `Token ${this.state.user.token}` } }
      : {};
    const newData = this.requestBodyBuilder();
    newData.id = this.props.location.options.id;
    console.log("data=>", newData, config, "state=>", this.state);
    axios
      .put(`${process.env.REACT_APP_API_URL}inventory/update`, newData, config)
      .then((res) =>
        this.props.history.push({
          pathname: "/admin/viewinventory",
        })
      )
      .catch((err) => console.log(err));
  };

  initialiseStateWithData(data) {
    this.setState(
      {
        id: this.props.location.options.id,
        brand: data.brand,
        sectionId: data.section._id,
        materialCode: data.materialCode,
        materialTypeId: data.materialType._id,
        materialName: data.materialName,
        finish: data.finish,
        weight: data.weight,
        threshold: data.threshold,
        inventoryType: data.inventoryType || 0,
        preview: process.env.REACT_APP_IMAGE_URL + "inventory/" + data.drawing,
      },
      () => console.log(this.state)
    );
  }

  getBrands = () => {
    axios.get(`${process.env.REACT_APP_API_URL}brand/`, {}).then((res) =>
      // console.log(res.data)
      this.setState({
        brands: res.data.brands,
      })
    );
  };

  componentDidMount() {
    console.log(this.props.location.options, this.props.location);
    axios.get(`${process.env.REACT_APP_API_URL}material/`, {}).then((res) =>
      // console.log(res.data)
      this.setState({
        materials: res.data.materialTypes,
      })
    );
    this.getBrands();
    // axios.get(`${process.env.REACT_APP_API_URL}user/getvendors`, {}).then((res) =>
    //   // console.log(res.data.vendors[0])
    //   this.setState(
    //     {
    //       vendors: res.data.vendors,
    //       vendor: res.data.vendors[0].vendorName,
    //     },
    //     () => console.log(res)
    //   )
    // );
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);

    if (userData) {
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        if (this.props.location.options?.isEditMode) {
          var id = this.props.location.options.id;
          axios
            .get(
              `${process.env.REACT_APP_API_URL}inventory/getinventory?id=${id}`,
              config
            )
            .then((res) => {
              let data = {
                params: { materialTypeId: res.data[0].materialType._id },
              };
              axios
                .get(`${process.env.REACT_APP_API_URL}section/`, data)
                .then((response) => {
                  console.log(response, "rrrrrrrrrrrrres");
                  this.setState({
                    sections: response.data.sections,
                    variants: res.data[0].variants,
                  });
                });
              this.initialiseStateWithData(res.data[0]);
            });
        } else if (this.props.location.options?.isAfterSignIn) {
          this.initialiseStateWithData(this.props.location.options.data);
        }
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  {" "}
                  {this.props.location?.options?.isEditMode
                    ? "Edit Inventory"
                    : "Add Inventory"}
                </h1>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                >
                  Button
                </button> */}
              </div>
              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  {/* <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materialCode">
                      Material Code
                    </label>
                    <input
                      type="text"
                      id="materialCode"
                      className="form-control"
                      value={this.state.materialCode}
                      onChange={this.handleInputChange}
                    />
                    <small className="text-danger">
                      {this.state.error &&
                        "This material has already been added"}
                    </small>
                  </div> */}
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialName">
                          Material Name
                        </label>
                        <input
                          type="text"
                          id="materialName"
                          className="form-control"
                          value={this.state.materialName}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="brand">
                          Brand
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="brand"
                          value={this.state.brand}
                          onChange={this.handleInputChange}
                        >
                          <option value="" disabled selected>
                            Select Brand
                          </option>
                          {this.state.brands.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.brandName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materialType">
                      Material Type
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="materialTypeId"
                      value={this.state.materialTypeId}
                      onChange={this.handleMaterialTypeChange}
                    >
                      <option value="" disabled selected>
                        Select Material Type
                      </option>
                      {this.state.materials.map((material, index) => (
                        <option key={`material${index}`} value={material._id}>
                          {material.materialType}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="sectionId">
                      Category
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="sectionId"
                      value={this.state.sectionId}
                      onChange={this.handleInputChange}
                    >
                      <option value="" disabled selected>
                        Select Category
                      </option>
                      {this.state.sections.map((item, index) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {this.state.inventoryVariants.map((item, index) => (
                    <InventoryVariant
                      key={item.id}
                      isDisabled={this.state.inventoryVariants.length}
                      id={index}
                      inventoryType={this.state.inventoryType}
                      handleVariant={this.handleInventoryVariant}
                      data={item}
                      deleteVariant={this.deleteInventoryVariant}
                    />
                  ))}
                  <div className="form-outline row mb-4">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => this.addInventoryVariant()}
                    >
                      <i className="bi bi-plus-circle pe-2 " />
                      Add Material
                    </button>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materialType">
                      Inventory Type
                    </label>
                    <select
                      className="form-control form-select col-12"
                      id="inventoryType"
                      value={this.state.inventoryType}
                      onChange={this.handleInventoryTypeChange}
                    >
                      <option value={0} selected>
                        Length
                      </option>
                      <option value={1}>Area</option>
                      <option value={2}>Pieces</option>
                    </select>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="finish">
                          Finish
                        </label>
                        <input
                          type="text"
                          id="finish"
                          className="form-control"
                          value={this.state.finish}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="threshold">
                          Threshold (kg)
                        </label>
                        <input
                          type="text"
                          id="threshold"
                          className="form-control"
                          value={this.state.threshold}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="finish">
                          Is Color available
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="isColor"
                          value={this.state.isColor}
                          onChange={this.handleInputChange}
                        >
                          <option value={"false"} selected>
                            No
                          </option>
                          <option value={"true"}>Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <Colors
                    isDisabled={this.state.isColor === "false"}
                    handleColors={this.handleColors}
                  />

                  <div className="form-outline row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="drawing">
                          Drawing
                        </label>
                        <input
                          type="file"
                          id="inventory"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={this.handleFileChange}
                        />
                      </div>
                      {this.state.preview && (
                        <img
                          alt=""
                          className="uploaded-preview mt-4"
                          src={this.state.preview}
                        />
                      )}
                    </div>
                  </div>
                  {this.state.inventoryType !== 2 &&
                    this.state.variants.map((item, index) => (
                      <Length
                        key={item.id}
                        isDisabled={this.state.variants.length}
                        id={index}
                        inventoryType={this.state.inventoryType}
                        handleVariant={this.handleVariant}
                        data={item}
                        deleteVariant={this.deleteVariant}
                      />
                    ))}
                  {this.state.inventoryType !== 2 && (
                    <div className="form-outline row mb-4">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.addVariant()}
                      >
                        <i className="bi bi-plus-circle pe-2 " />
                        Add Variant
                      </button>
                    </div>
                  )}
                  {this.state.inventoryType === 1 && (
                    <LengthSelect
                      isDisabled={this.state.inventoryType !== 1}
                      handleLength={this.handleLength}
                    />
                  )}
                  {/* </div> */}
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    {this.props.location?.options?.isEditMode
                      ? "Edit Inventory"
                      : "Add Inventory"}
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default AddInventoryBulk;
