import React, { Component } from "react";

export default class InventoryVariant extends Component {
  state = {
    materialCode: this.props.data.materialCode,
    weight: this.props.data.weight,
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.props.handleVariant(this.props.id, {
        materialCode: this.state.materialCode,
        weight: this.state.weight,
      })
    );
  };

  render() {
    return (
      <div className="form-outline mb-4 d-flex">
        {/* <div className="col-5"> */}
        <label className="form-label me-2" htmlFor="materialCode">
          Material Code
        </label>
        <input
          type="text"
          id="materialCode"
          name="materialCode"
          className="form-control me-2"
          value={this.state.materialCode}
          onChange={this.handleInputChange}
          required
        />
        {/* </div>
        <div className="col-5"> */}
        <label className="form-label me-2" htmlFor="weight">
          Weight
        </label>
        <input
          type="number"
          id="weight"
          name="weight"
          className="form-control"
          value={this.state.weight}
          onChange={this.handleInputChange}
          disabled={this.props.inventoryType !== 0}
        />
        {/* </div> */}
        <div>
          <button
            className="btn"
            disabled={this.props.isDisabled===1}
            onClick={() => this.props.deleteVariant(this.props.id)}
          >
            <i className="bi bi-trash-fill" />
          </button>
        </div>
      </div>
    );
  }
}
