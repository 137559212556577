import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import axios from "axios";

class SideBar extends React.Component {
  state = {
    user: "",
  };
  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + user.token,
          },
        })
        .then((res) => {
          this.setState({ user: res.data.users });
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    } else {
      this.props.history.push("/");
    }
  };
  render() {
    console.log("sss ", this.state);
    return (
      <div
        id="sidebarMenu"
        className="p-4 mt-5 col-md-3 col-lg-2 d-md-block sidebar collapse"
      >
        {this.state.user?.userType == "admin" ? (
          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="false"
              >
                inventory
              </button>
              <div className="collapse" id="home-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {["superAdmin", "mainStaff"].includes(
                    this.state.user?.adminType
                  ) && (
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/inventory"
                        className="link-dark rounded"
                      >
                        Add Inventory Item
                      </NavLink>
                    </li>
                  )}

                  <li>
                    <NavLink
                      to="/admin/viewinventory"
                      className="link-dark rounded active"
                    >
                      View Inventory
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/addstock"
                      className="link-dark rounded active"
                    >
                      Add Stock
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/viewstock"
                      className="link-dark rounded active"
                    >
                      View Stock
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            {["superAdmin", "mainStaff"].includes(
              this.state.user?.adminType
            ) && (
              <li className="mb-1">
                <button
                  className="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#orders-collapse"
                  aria-expanded="false"
                >
                  Orders
                </button>
                <div className="collapse" id="orders-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <NavLink
                        to="/admin/vieworders?option=all"
                        className="link-dark rounded"
                      >
                        All Orders
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            )}

            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#material-types"
                aria-expanded="false"
              >
                Material Type
              </button>
              <div className="collapse" id="material-types">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {["superAdmin", "mainStaff"].includes(
                    this.state.user?.adminType
                  ) && (
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/add-material"
                        className="link-dark rounded"
                      >
                        Add Material Type
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="/admin/view-materials"
                      className="link-dark rounded active"
                    >
                      View Materials Type
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#section"
                aria-expanded="false"
              >
                Category
              </button>
              <div className="collapse" id="section">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {["superAdmin", "mainStaff"].includes(
                    this.state.user?.adminType
                  ) && (
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/add-section"
                        className="link-dark rounded"
                      >
                        Add Category
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <NavLink
                      to="/admin/view-sections"
                      className="link-dark rounded active"
                    >
                      View Category
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#price"
                aria-expanded="false"
              >
                Price
              </button>
              <div className="collapse" id="price">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/admin/view-price-by-material"
                      className="link-dark rounded"
                    >
                      View Price By Materials Type
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/view-price-by-section"
                      className="link-dark rounded active"
                    >
                      View Price By Section
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#brand"
                aria-expanded="false"
              >
                Brands
              </button>
              <div className="collapse" id="brand">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/admin/view-brands"
                      className="link-dark rounded"
                    >
                      View Brands
                    </NavLink>
                  </li>
                  {["superAdmin", "mainStaff"].includes(
                    this.state.user?.adminType
                  ) && (
                    <li>
                      <NavLink
                        to="/admin/add-brand"
                        className="link-dark rounded active"
                      >
                        Add Brands
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#bundle"
                aria-expanded="false"
              >
                Bundle
              </button>
              <div className="collapse" id="bundle">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/admin/view-bundle"
                      className="link-dark rounded"
                    >
                      View Bundle
                    </NavLink>
                  </li>
                  {["superAdmin", "mainStaff"].includes(
                    this.state.user?.adminType
                  ) && (
                    <li>
                      <NavLink
                        to="/admin/add-bundle"
                        className="link-dark rounded active"
                      >
                        Add Bundle
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            {["superAdmin", "mainStaff"].includes(
              this.state.user?.adminType
            ) && (
              <li className="mb-1">
                <button
                  className="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#users"
                  aria-expanded="false"
                >
                  User Management
                </button>
                <div className="collapse" id="users">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/view-public-users"
                        className="link-dark rounded"
                      >
                        View Public Users
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/view-contractors"
                        className="link-dark rounded"
                      >
                        View Contractors
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active"
                        to="/admin/view-vendors"
                        className="link-dark rounded"
                      >
                        View Vendors
                      </NavLink>
                    </li>
                    {["superAdmin"].includes(
                      this.state.user?.adminType
                    ) && (
                      <li>
                        <NavLink
                          activeClassName="active"
                          to="/admin/view-admins"
                          className="link-dark rounded"
                        >
                          View Admins
                        </NavLink>
                      </li>
                    )}
                    {["superAdmin"].includes(
                      this.state.user?.adminType
                    ) && (
                      <li>
                        <NavLink
                          activeClassName="active"
                          to="/admin/add-adminstore"
                          className="link-dark rounded"
                        >
                          Add Admin Store
                        </NavLink>
                      </li>
                    )}
                    {["superAdmin"].includes(
                      this.state.user?.adminType
                    ) && (
                      <li className="mb-1">
                        <NavLink
                          activeClassName="active"
                          to="/admin/signup"
                          className="link-dark rounded"
                        >
                          Create User
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                aria-expanded="false"
              >
                {" "}
                <div className="list-unstyled fw-normal pb-1">
                  <NavLink
                    activeClassName="active"
                    to="/admin/view-enquiries/"
                    className="link-dark rounded"
                    style={{
                      textDecoration: "none",
                      color: "#000000a6",
                      fontWeight: "600",
                    }}
                  >
                    Enquiries
                  </NavLink>
                </div>
              </button>
            </li>
             <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                aria-expanded="false"
              >
                {" "}
                <div className="list-unstyled fw-normal pb-1">
                  <NavLink
                    activeClassName="active"
                    to="/admin/view-e2e-enquiries/"
                    className="link-dark rounded"
                    style={{
                      textDecoration: "none",
                      color: "#000000a6",
                      fontWeight: "600",
                    }}
                  >
                   E2E Enquiries
                  </NavLink>
                </div>
              </button>
            </li>
          </ul>
        ) : this.state.user?.userType === "vendor" ? (
          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="false"
              >
                Stocks
              </button>
              <div className="collapse" id="home-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      to="/admin/vendorviewstock"
                      className="link-dark rounded active"
                    >
                      View Stock
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#orders-collapse"
                aria-expanded="false"
              >
                Orders
              </button>
              <div className="collapse" id="orders-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {/* <li>
          <NavLink
            to="/admin/vieworders?option=new"
            className="link-dark rounded"
          >
            New Orders
          </NavLink>
        </li> */}
                  <li>
                    <NavLink
                      to="/vendor/vieworders"
                      className="link-dark rounded"
                    >
                      All Orders
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        ) : (
          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="false"
              >
                Inventory
              </button>
              <div className="collapse" id="home-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/admin/inventory"
                      className="link-dark rounded"
                    >
                      Add Inventory Item
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/admin/viewinventory"
                      className="link-dark rounded active"
                    >
                      View Inventory
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#orders-collapse"
                aria-expanded="false"
              >
                Orders
              </button>
              <div className="collapse" id="orders-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {/* <li>
          <NavLink
            to="/admin/vieworders?option=new"
            className="link-dark rounded"
          >
            New Orders
          </NavLink>
        </li> */}
                  <li>
                    <NavLink
                      to="/admin/vieworders?option=all"
                      className="link-dark rounded"
                    >
                      All Orders
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default withRouter(SideBar);
