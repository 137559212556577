import React, { useEffect, useMemo, useState } from "react";
import MultiCreatableSelect from "./MultiCreatableSelect";

const Colors = ({ isDisabled, handleColors, colors }) => {
  const [colorName, setColorName] = useState([]);

  const handleInputChange = (value) => {
    setColorName(value);
    handleColors(value);
  };

  const options = useMemo(
    () => [
      { value: "Black", label: "Black" },
      { value: "Red", label: "Red" },
      { value: "Green", label: "Green" },
      { value: "Grey", label: "Grey" },
    ],
    []
  );

  useEffect(() => {
    if (colors) setColorName(colors);
  }, [colors]);

  return (
    <div className="form-outline mb-4">
      <label className="form-label" htmlFor="variantName">
        Color
      </label>
      <div className="w-100 ">
        <MultiCreatableSelect
          onChange={handleInputChange}
          value={colorName}
          options={options}
          isDisabled={isDisabled}
          autoFocus={true}
          onMenuOpen={true}
        />
      </div>
    </div>
  );
};

export default Colors;
