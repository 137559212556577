import React from "react";
import axios from "axios";
// import SideBar from "../SideBar";
// import { Link } from "react-router-dom";
import Header from "../Header";
import SidebarUser from "../SideBar2";

class CompleteAccount extends React.Component {
  constructor() {
    super();
    this.state = {
      user: [],
      accountType: "individual",
      firstName: "",
      phoneNumber: "",
      location: "",
      age: "",
      aadhar: "",
      nameOfCompany: "",
      addressOfCompany: "",
      gst: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  updateAccount = (event) => {
    event.preventDefault();
    console.log(this.state.user);
    let config = this.state.user?.token
      ? { headers: { authorization: `Token ${this.state.user.token}` } }
      : {};
    axios
      .put(
        `${process.env.REACT_APP_API_URL}user/update`,
        {
          id: this.state.user.id,
          accountType: this.state.accountType,
          firstName: this.state.firstName,
          phoneNo: this.state.phoneNumber,
          location: this.state.location,
          age: this.state.age,
          aadharCardNo: this.state.aadhar,
          companyName: this.state.nameOfCompany,
          address: this.state.addressOfCompany,
        },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.data) {
          var user = localStorage.getItem("user");
          if (user) {
            var userData = JSON.parse(user);
            userData.isComplete = true;
            localStorage.setItem("user", JSON.stringify(userData));
          }
          if (this.props.location.options?.isOrderPage) {
            this.props.history.push({
              pathname: "/orders",
              options: this.props.location?.options,
            });
          } else {
            this.props.history.goBack();
          }
        }
        console.log("failed");
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}user/getuser`, config)
          .then((res) => {
            console.log(res);
            this.setState({
              phoneNumber: res.data.phoneNo,
              firstName: res.data.firstName || "",
              location: res.data.location || "",
              email: res.data.email || "",
              age: res.data.age || "",
              gst: res.data.gst || "",
              aadhar: res.data.aadharCardNo || "",
              companyName: res.data.companyName || "",
              addressOfCompany: res.data.address || "",
            });
            console.log(res);
          });
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SidebarUser />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">My Account</h1>
              </div>
              <div className="col-md-6">
              <form onSubmit={(event) => this.updateAccount(event)}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="phoneNumber">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        id="phoneNumber"
                        className="form-control"
                        value={this.state.phoneNumber}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        id="location"
                        className="form-control"
                        value={this.state.location}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="age">
                        Age
                      </label>
                      <input
                        type="number"
                        id="age"
                        className="form-control"
                        value={this.state.age}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="firstName">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        className="form-control"
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-outline">
                  <label className="form-label" htmlFor="aadhar">
                    Aadhar Card Number
                  </label>
                  <input
                    type="text"
                    id="aadhar"
                    className="form-control"
                    value={this.state.aadhar}
                    onChange={this.handleInputChange}
                  />
                </div>
                {/* Number input */}
                <div className="row my-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="accountType">
                        User Type
                      </label>
                      <select
                        className="form-control select col-12"
                        id="accountType"
                        value={this.state.accountType}
                        onChange={this.handleInputChange}
                      >
                        <option key={"individual"} value={"individual"}>
                          Individual
                        </option>
                        <option key={"company"} value={"company"}>
                          Company
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="col"
                    style={{
                      display:
                        this.state.accountType === "company" ? "block" : "none",
                    }}
                  >
                    <div className="form-outline">
                      <label className="form-label" htmlFor="gst">
                        GST Number
                      </label>
                      <input
                        type="text"
                        id="gst"
                        className="form-control"
                        value={this.state.gst}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row mb-4"
                  style={{
                    display:
                      this.state.accountType === "company" ? "block" : "none",
                  }}
                >
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="nameOfCompany">
                        Name of Company
                      </label>
                      <input
                        type="text"
                        id="nameOfCompany"
                        className="form-control"
                        value={this.state.nameOfCompany}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="addressOfCompany">
                        Address Of Company
                      </label>
                      <input
                        type="text"
                        id="addressOfCompany"
                        className="form-control"
                        value={this.state.addressOfCompany}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary2 btn-block mb-4"
                >
                  Update
                </button>
              </form>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default CompleteAccount;
