import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header";
import SideBar from "../SideBar";

const ViewBundle = () => {
  const history = useHistory();
  const [bundleList, setBundleList] = useState([]);
  const [user, setUser] = useState(null);
  const getBundleList = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}bundle/list`
    );
    if (response.data.data) setBundleList(response.data.data);
  };
  useEffect(() => {
    getBundleList();
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + userData.token,
          },
        })
        .then((res) => {
          setUser(res.data.users);
        })
        .catch((err) => {
          history.push("/");
        });
    }
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2 fw-bold">Bundle</h1>
            </div>

            <div className="table-sec">
              <div className="table-responsive">
                <table className="table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Bundle Name</th>
                      <th>Items</th>
                      {["superAdmin", "mainStaff"].includes(
                        user?.adminType
                      ) && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {bundleList.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index}</td>
                        <td>{item.name}</td>
                        <td>
                          {item.itemList.map(
                            (i) => i.inventory.materialName + ", "
                          )}
                        </td>
                        {["superAdmin", "mainStaff"].includes(
                          user?.adminType
                        ) && (
                          <td>
                            <button
                              className="btn btn-sm cursor-pointer"
                              onClick={() => {
                                history.push("/admin/edit-bundle/" + item._id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                ></path>
                              </svg>
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ViewBundle;
