import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

export default class EditMaterial extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      materialType: "",
      price: "",
      loading: false,
      error: "",
      priceOnMaterial: null,
      drawing: "",
      preview: null,
      isLoaded: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleFileChange = (event) => {
    this.setState({
      drawing: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  handlepriceOnMaterialChange = (event) => {
    let value = event.target.value === "true" ? true : false;
    this.setState({ priceOnMaterial: value });
  };

  createRequestBody = () => {
    const data = new FormData();
    data.append("id", this.state.id);
    data.append("materialType", this.state.materialType);
    data.append("price", this.state.price);
    data.append("material", this.state.drawing);
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = this.createRequestBody();
    axios
      .put(`${process.env.REACT_APP_API_URL}material/update`, data, config)
      .then((res) => {
        if (res) {
          this.props.history.push({
            pathname: "/admin/view-materials",
          });
        }
        console.log(res);
      })
      .catch(({ response }) => {
        this.setState({ loading: false, error: response.data.message });
      });
  };

  populateData = (data) => {
    this.setState({
      materialType: data.materialType,
      price: data.price,
      priceOnMaterial: data.isPriceBasedOnMaterial,
      preview: process.env.REACT_APP_IMAGE_URL + data.drawing,
      isLoaded: true,
    });
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = this.props.match.params.id;
    this.setState({ id: id });
    if (user) {
      this.setState({ user: user });
      let config = {
        headers: { authorization: `Token ${user.token}` },
        params: { id: id },
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}material/getmaterial/`, config)
        .then((res) => {
          if (res.data.materialType[0]) {
            this.populateData(res.data.materialType[0]);
          }
        });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Edit Material</h1>
              </div>
              <div className="col-md-6">
                {this.state.isLoaded && (
                  <form onSubmit={(event) => this.submitOrder(event)}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="materialType">
                            Material Type
                          </label>
                          <input
                            type="text"
                            id="materialType"
                            className="form-control"
                            value={this.state.materialType}
                            onChange={this.handleInputChange}
                          />
                          <small className="text-danger">
                            {this.state.error &&
                              "This material has already been added"}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="price">
                            Price
                          </label>
                          <input
                            type="text"
                            id="price"
                            className="form-control"
                            value={this.state.price}
                            onChange={this.handleInputChange}
                            disabled={!this.state.priceOnMaterial}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="drawing">
                            Drawing
                          </label>
                          <input
                            type="file"
                            id="drawing"
                            accept="image/png, image/jpeg"
                            className="form-control"
                            onChange={this.handleFileChange}
                            // required
                          />
                        </div>
                        <img
                          alt=""
                          className="uploaded-preview mt-4"
                          src={this.state.preview}
                        />
                        {console.log(this.state.preview)}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary2 btn-block mb-4"
                      disabled={this.state.loading}
                    >
                      Edit Material
                    </button>
                  </form>
                )}
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
