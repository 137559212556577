import React from "react";
import { Link } from "react-router-dom";

class ErrorPage extends React.Component {

  render() {
    return (
        <>
        <h1>Error 404</h1>
        <p>Page not found!!</p>
        <Link to="/">Go to home page</Link>
        </>
    );
  }
}

export default ErrorPage;
