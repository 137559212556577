import React from "react";
import axios from "axios";
// import SideBar from "../SideBar";
// import { Link } from "react-router-dom";
import Header from "../Header";
import SidebarUser from "../SideBar2";

class Confirmed extends React.Component {
  constructor() {
    super();
    this.state = {
      orders: [],
    };
  }
  openImage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}order/confirmed`, config)
          .then((res) => {
            this.setState({ orders: res.data });
            console.log(res);
          });
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
           
            <SidebarUser />

            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Orders</h1>
              
              </div>
              <div className="table-sec">
              <div className="table-responsive">
                <table className="table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th>Order No</th>
                      <th>Section Name</th>
                      <th>Project Name</th>
                      <th>Customer Name</th>
                      <th>Material Name</th>
                      <th>Length</th>
                      <th>Weight/m</th>
                      <th>Weight</th>
                      <th>Pieces</th>
                      <th>Total Price</th>
                      <th>Delivery Date</th>
                      <th>finish</th>
                      <th>Remarks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.orders.map((item, index) => (
                      <tr key={item.orderNumber}>
                        <td>{item.orderNumber.toUpperCase()}</td>
                        <td>{item.section.name}</td>
                        <td>{item.projectName}</td>
                        <td>{item.customerName}</td>
                        <td>{item.materialName.materialName}</td>
                        <td>{item.length}</td>
                        <td>{item.weightPerMeter}</td>
                        <td>{item.weight}</td>
                        <td>{item.pieces}</td>
                        <td>{item.totalPrice}</td>
                        <td>{item.deliveryDate.split("T")[0]}</td>
                        <td>{item.finish}</td>
                        <td>{item.remarks}</td>
                        <td>{item.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default Confirmed;
