import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

export default class EditVendor extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      vendorName: "",
      loading: false,
      confirmPassword: "",
      password: "",
      email: "",
      passwordError: "",
      emailError: "",
      vendorData: {},
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value, passwordError: "" });
  };

  createRequestBody = () => {
    const data = {
      id: this.state.id,
      vendorName: this.state.vendorName
        ? this.state.vendorName
        : this.state.vendorData.vendorName,
      email: this.state.email,
      password: this.state.password,
    };

    return data;
  };

  validatePassword = () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        passwordError: "Passwords do not match",
        loading: false,
      });
      return false;
    }
    return true;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: false });
    let isValid = this.validatePassword();
    if (!isValid) return;
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = this.createRequestBody();
    console.log("req data", data);
    axios
      .put(`${process.env.REACT_APP_API_URL}vendor/update`, data, config)
      .then((res) => {
        if (res) {
          this.props.history.push({
            pathname: "/admin/view-vendors",
          });
        }
      })
      .catch(({ response }) => {
        this.setState({
          loading: false,
          emailError: response.data?.errors?.message,
        });
        console.log(response);
      });
  };

  populateData = (data) => {
    this.setState({
      vendorName: data.vendorName,
      email: data.email,
    });
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = this.props.match.params.id;
    if (user) {
      this.setState({ user: user, id: id });
      let config = {
        headers: { authorization: `Token ${user.token}` },
        params: { id: id },
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}vendor/get-vendor-data/`, config)
        .then((res) => {
          if (res.data.vendor) {
            this.setState({ vendorData: res.data.vendor });
            this.populateData(res.data.vendor);
          }
        });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Edit Vendor</h1>
              </div>
              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="row mb-4">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="vendorName">
                        Vendor Name
                      </label>
                      <input
                        type="text"
                        id="vendorName"
                        className="form-control"
                        value={this.state.vendorName}
                        onChange={this.handleInputChange}
                        placeholder={this.state.vendorData.vendorName}
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="form-outline position-relative">
                      <label className="form-label" htmlFor="email">
                        Email ID
                      </label>
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        placeholder={this.state.vendorData.email}
                        required
                      />
                      <small className="text-danger position-absolute">
                        {this.state.emailError}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="form-outline position-relative">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                      />
                      <small className="text-danger position-absolute">
                        {this.state.passwordError}
                      </small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="confirmPassword">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confirmPassword"
                        className="form-control"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                        required={this.state.password}
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    Update Vendor
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
