import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

export default class AddMaterials extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      materialType: "",
      priceOnMaterial: false,
      price: "",
      nonBrandedPrice: "",
      drawing: "",
      isBranded: false,
      loading: false,
      error: "",
      preview: "",
    };
  }

  handleFileChange = (event) => {
    this.setState({
      drawing: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
    console.log('img',this.state.drawing);
  };
  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handlepriceOnMaterialChange = (event) => {
    let value = event.target.value === "true" ? true : false;
    this.setState({ priceOnMaterial: value });
  };

  handleBranded = (event) => {
    let value = event.target.value === "true" ? true : false;
    this.setState({ isBranded: value });
  };

  createRequestBody = () => {
    const data = new FormData();
    data.append("isPriceBasedOnMaterial", this.state.priceOnMaterial);
    data.append("materialType", this.state.materialType);
    data.append("isBranded", this.state.isBranded);
    data.append("nonBrandedPrice", this.state.nonBrandedPrice);
    data.append("price", this.state.price);
    data.append("material", this.state.drawing);
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = this.createRequestBody();
    axios
      .post(`${process.env.REACT_APP_API_URL}material/add`, data, config)
      .then((res) => {
        if (res) {
          this.props.history.push({
            pathname: "/admin/view-materials",
          });
        }
        console.log(res);
      })
      .catch(({ response }) => {
        this.setState({ loading: false, error: response.data.message });
      });
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      this.setState({ user: user });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  {" "}
                  {this.props.location?.options?.isEditMode
                    ? "Edit Inventory"
                    : "Add Material"}
                </h1>
              </div>
              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialType">
                          Material Type
                        </label>
                        <input
                          type="text"
                          id="materialType"
                          className="form-control"
                          value={this.state.materialType}
                          onChange={this.handleInputChange}
                          required
                        />
                        <small className="position-absolute text-danger">
                          {this.state.error &&
                            "This material has already been added"}
                        </small>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="priceOnMaterial">
                          Is Price Based On Material Type
                        </label>
                        {/* <input
                          class="form-check-input"
                          type="checkbox"
                          value={true}
                          id="priceOnMaterial"
                        ></input> */}
                        <select
                          className="form-control form-select"
                          id="priceOnMaterial"
                          value={this.state.priceOnMaterial}
                          onChange={this.handlepriceOnMaterialChange}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                        {/* <input
                          type="text"
                          id="priceOnMaterial"
                          className="form-control"
                          value={this.state.priceOnMaterial}
                          onChange={this.handleInputChange}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="isBranded">
                          Is Branded
                        </label>
                        <select
                          className="form-control form-select"
                          id="isBranded"
                          value={this.state.isBranded}
                          onChange={this.handleBranded}
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="drawing">
                          Drawing
                        </label>
                        <input
                          type="file"
                          id="drawing"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={this.handleFileChange}
                          required
                        />
                      </div>
                      {this.state.preview && (
                        <img
                          alt=""
                          className="uploaded-preview mt-4"
                          src={this.state.preview}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="price">
                          {this.state.isBranded &&
                            this.state.priceOnMaterial &&
                            "Branded"}{" "}
                          Price
                        </label>
                        <input
                          type="text"
                          id="price"
                          className="form-control"
                          value={this.state.price}
                          onChange={this.handleInputChange}
                          disabled={!this.state.priceOnMaterial}
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.priceOnMaterial && this.state.isBranded && (
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label
                            className="form-label"
                            htmlFor="nonBrandedPrice"
                          >
                            Non Branded Price
                          </label>
                          <input
                            type="text"
                            id="nonBrandedPrice"
                            className="form-control"
                            value={this.state.nonBrandedPrice}
                            onChange={this.handleInputChange}
                            disabled={
                              !this.state.priceOnMaterial ||
                              !this.state.isBranded
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    {this.props.location?.options?.isEditMode
                      ? "Edit Inventory"
                      : "Add Material"}
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}
