import React, { Component } from "react";

export default class ViewOrderDetails extends Component {
  render() {
    return (
      <form >
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="projectName">
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            className="form-control"
            value={this.props.orderDetails?.projectName}
            disabled
          />
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="customerName">
            Customer Name
          </label>
          <input
            type="text"
            id="customerName"
            className="form-control"
            value={this.props.orderDetails?.customerName}
            disabled
          />
        </div>
        {/* <div className="row mb-4"> */}
          {/* <div className="col-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="sectionId">
                Category
              </label>
              <input
                type="text"
                id="sectionId"
                className="form-control"
                value={this.props.orderDetails?.section?.name}
                disabled
              />
            </div>
          </div> */}
          {/* {this.props.orderDetails?.imageUrl && (
            <div className="col-6">
              <img
                className="orders-img"
                src={`${process.env.REACT_APP_IMAGE_URL + this.props.orderDetails?.imageUrl}`}
                alt=""
              />
            </div>
          )} */}
          {/* <div className="col">
            <label className="form-label" htmlFor="materiaName">
              Material Name
            </label>
            <input
              type="text"
              id="materialNameId"
              className="form-control"
              value={this.props.orderDetails?.materialName?.materialName}
              disabled
            />
          </div> */}
        {/* </div> */}
{/* 
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="weightPerMeter">
                Weight/m
              </label>
              <input
                type="number"
                id="weightPerMeter"
                className="form-control"
                value={this.props.orderDetails?.weightPerMeter}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="length">
                Length(m)
              </label>
              <input
                type="number"
                id="length"
                className="form-control"
                value={this.props.orderDetails?.length?.variantName}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="pieces">
                Pieces
              </label>
              <input
                type="number"
                id="pieces"
                className="form-control"
                value={this.props.orderDetails?.pieces}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="weight">
                Weight(kg)
              </label>
              <input
                type="number"
                id="weight"
                className="form-control"
                value={this.props.orderDetails?.weight}
                disabled
              />
            </div>
          </div>
        </div> */}
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="totalprice">
            Total Price
          </label>
          <div className="input-group">
            <span class="input-group-text br-0 border-gray bg-white">₹</span>
            <input
              type="number"
              id="totalprice"
              className="form-control bl-0 rounded-l-0"
              value={this.props.orderDetails?.totalPrice}
              disabled
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="deliveryDate">
                Delivery Date
              </label>
              <input
                type="date"
                id="deliveryDate"
                className="form-control"
                value={this.props.orderDetails?.deliveryDate?.split("T")[0]}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="location">
            Delivery Location
          </label>
          <div className="input-group">
            <span class="input-group-text br-0 border-gray bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
            </span>
            <input
              type="text"
              id="deliveryLocation"
              value={this.props.orderDetails?.deliveryLocation}
              className="form-control bl-0 rounded-l-0"
              disabled
            />
          </div>
        </div>

        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="remarks">
            Remarks
          </label>
          <textarea
            className="form-control"
            id="remarks"
            rows={4}
            value={this.props.orderDetails?.remarks}
            disabled
          />
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="vendorSelection">
                Vendor
              </label>
              <input
                type="text"
                id="vendorSelection"
                className="form-control bl-0 rounded-l-0"
                value={this.props.orderDetails?.vendor?.vendorName}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="driverName">
                Driver Name
              </label>
              <input
                type="text"
                id="driverName"
                className="form-control"
                value={this.props.orderDetails?.driverName}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="driverContactNumber">
                Driver Contact Number
              </label>
              <input
                type="number"
                id="driverContactNumber"
                className="form-control"
                value={this.props.orderDetails?.driverContactNumber}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="vehicleNumber">
                Vehicle Number
              </label>
              <input
                type="text"
                id="vehicleNumber"
                className="form-control"
                value={this.props.orderDetails?.vehicleNumber}
                disabled
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}
