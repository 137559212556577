import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import MultiSelect from "./MultiSelect";

const AddSections = (props) => {
  const [name, setName] = useState("");
  const [materialTypeId, setMaterialTypeId] = useState("");
  const [price, setPrice] = useState("");
  const [drawing, setDrawing] = useState("");
  const [user, setUser] = useState("");
  const [materialTypes, setMaterialTypes] = useState([]);
  const [isPriceBasedOnMaterial, setIsPriceBasedOnMaterial] = useState(false);
  const [preview, setPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = ({ target }) => setName(target.value);
  const handlePriceChange = ({ target }) => setPrice(target.value);

  const handleMaterialTypeChange = (value) => {
    setMaterialTypeId(value);
    console.log(value[0]?.value.isPriceBasedOnMaterial);
    setIsPriceBasedOnMaterial(value[0]?.value?.isPriceBasedOnMaterial || false);
  };

  const handleFileChange = ({ target }) => {
    setDrawing(target.files[0]);
    setPreview(URL.createObjectURL(target.files[0]));
  };

  const createRequestBody = () => {
    const data = new FormData();
    data.append("name", name);
    data.append(
      "materialTypeList",
      JSON.stringify(materialTypeId.map((i) => i.value._id))
    );
    data.append("price", price);
    data.append("section", drawing);
    return data;
  };

  const submit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const data = createRequestBody();
      const config = {
        headers: { authorization: `Token ${user.token}` },
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "section/add-v2",
        data,
        config
      );
      setIsLoading(false);
      if (response)
        props.history.push({
          pathname: "/admin/view-sections",
        });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getMaterialTypes = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "material/"
      );
      if (response) {
        const _materialTypes = response.data.materialTypes.map((i) => ({
          label: i.materialType,
          value: i,
        }));
        setMaterialTypes(_materialTypes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
      getMaterialTypes();
    } else {
      props.history.push("/");
    }
    return () => {
      URL.revokeObjectURL(preview);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <SideBar />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">Add Category</h1>
            </div>
            <div className="col-md-6">
              <form onSubmit={submit}>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="materialType">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={handleNameChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="materialType">
                        Material Type
                      </label>
                      <MultiSelect
                        onChange={handleMaterialTypeChange}
                        options={materialTypes}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="price">
                        Price
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={price}
                        onChange={handlePriceChange}
                        disabled={isPriceBasedOnMaterial}
                        required={!isPriceBasedOnMaterial}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="drawing">
                        Drawing
                      </label>
                      <input
                        type="file"
                        id="drawing"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        onChange={handleFileChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  {preview && (
                    <img
                      alt=""
                      className="uploaded-preview mt-4"
                      src={preview}
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-primary2 btn-block mb-4"
                  disabled={isLoading}
                >
                  Add Category
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default AddSections;
