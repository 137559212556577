import React from "react";

class ViewOrderItems extends React.Component {
  constructor() {
    super();
    this.state = {
      orders: [],
    };
  }

  render() {
    return (
      <div class="table-responsive">
        <table className="table table-borderless table-hover">
          <thead>
            <tr>
              <th>Category Name</th>
              <th>Material Name</th>
              <th>Length</th>
              <th>Weight/m</th>
              <th>Total Weight</th>
              <th>Pieces</th>
              <th>Finish</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {this.props.items.map((item, index) => (
              <tr key={index}>
                <td>{item.section?.name}</td>
                <td>{item.materialName?.materialName}</td>
                <td>{item.variant?.variantName}</td>
                <td>{item.weightPerMeter}</td>
                <td>{item.weight}</td>
                <td>{item.pieces}</td>
                <td>{item.materialName.color}</td>
                <td>{item.itemPrice}</td>
                {/* <td>
                <Link
                  style={{
                    pointerEvents: item.status === "confirmed" && "none",
                  }}
                  className="btn btn-sm cursor-pointer"
                  to={{
                    pathname: "/orders",
                    options: { isEditMode: true, id: item._id },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    ></path>
                  </svg>
                </Link>
              </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ViewOrderItems;
