import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

class AddBrand extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      brandName: "",
      userType: "vendor",
      loading: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };
  requestBodyBuilder = () => {
    var data = {
      brandName: this.state.brandName,
    };
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let config = { headers: { authorization: `Token ${this.state.token}` } };
    var data = this.requestBodyBuilder();
    axios
      .post(`${process.env.REACT_APP_API_URL}brand/add`, data, config)
      .then((res) => {
        if (res) {
          this.props.history.push("/admin/view-brands");
        }
      })
      .catch(({ response }) => this.setState({ loading: false }));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);
    if (userData) {
      this.setState({ user: userData, token: userData.token });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Add Brand</h1>
              </div>

              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="weight">
                      Brand Name
                    </label>
                    <input
                      type="text"
                      id="brandName"
                      className="form-control"
                      name="brandName"
                      value={this.state.brandName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    Add Brand
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default AddBrand;
