import React from "react";
import Header from "../Header";
import SidebarUser from "../SideBar2";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      user: false,
    };
  }

  handleData = (data) => {
    this.setState({ [data.key]: data.value });
  };
  componentDidMount() {
    var user = localStorage.getItem("user");
    console.log(user);
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
  }

  render() {
    return (
      <div>
        <Header handleData={this.handleData} />
        <div className="container-fluid">
          <div className="row">
            
            <SidebarUser/>
       
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <h1 className="Heading">
                <span className="h6 font-weight-light">Hey,</span> <br></br>
                {this.state.user
                  ?  ` ${
                      this.state.user.firstName? this.state.user.firstName: ""
                    }`
                  : `Welcome to Bright Aluminium`}
                !!
              </h1>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
