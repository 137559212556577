import axios from "axios";
import React, { useEffect, useState } from "react";
import Contractors from "./Contractors";

const BundleItem = ({
  // categoryList,
  materialTypeList,
  brandList,
  handleBundleItem,
  id,
  removeBundleItem,
  item
}) => {
  const [inventoryList, setInventoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // const [inventoryVariantList, setInventoryVariantList] = useState([]);
  //   const [variantList, setVariantList] = useState([]);
  const [inventory, setInventory] = useState(item?.inventory?._id ?? "");
  const [section, setSection] = useState(item?.section ?? "");
  const [materialType, setMaterialType] = useState(item?.materialType ?? "");
  //   const [variant, setVariant] = useState("");
  const [brand, setBrand] = useState(item?.brand ?? "");

  // console.log("iii ", item.inventory._id);

  const getCategoryList = async () => {
    const query = { params: { materialType } };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}section/list`,
      query
    );
    if (response.data.data) setCategoryList(response.data.data);
  };

  const getInventoryList = async () => {
    setInventory("");
    const query = { params: { categoryId: section, brand } };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}inventory/list`,
      query
    );
    if (response.data.data) setInventoryList(response.data.data);
  };
  // const handleInventoryVariantChange = ({ target }) =>
  //   setInventoryVariant(target.value);

  const handleBrandChange = ({ target }) => {
    setInventory("");
    setBrand(target.value);
  };

  const handleMaterialTypeSelect = ({ target }) => {
    setInventory("");
    setSection("");
    setMaterialType(target.value);
  };

  const handleCategorySelect = ({ target }) => {
    setInventory("");
    setSection(target.value);
  };
  //   const handleVariantChange = ({ target }) => setVariant(target.value);

  const handleInventorySelect = ({ target }) => {
    // const selected = inventoryList.find((i) => i._id === target.value);
    // setInventoryVariantList(selected.inventoryVariants);
    // // setVariantList(selected.variants);
    setInventory(target.value);
  };

  useEffect(() => {
    if (materialType) getCategoryList();
    if ((section, brand, materialType)) getInventoryList();
    // eslint-disable-next-line
  }, [section, brand, materialType]);

  useEffect(() => {
    handleBundleItem({
      brand,
      section,
      inventory,
      id,
      materialType,
    });
  }, [brand, section, inventory, materialType, handleBundleItem, id]);

  return (
    <>
      <div class="border-top border-primary2" />
      <div class="border-top border-primary2" />
      <div className="row mb-4 mt-2">
        <div className="col">
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="inventory">
              Material Type
            </label>
            <select
              className="form-control form-select col-12"
              id="materialType"
              onChange={handleMaterialTypeSelect}
              required
            >
              <option value="" disabled selected>
                Select Material Type
              </option>
              {materialTypeList.map((item) => (
                <option key={item._id} value={item._id} selected={item._id == materialType}>
                  {item.materialType}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col">
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="inventory">
              Category
            </label>
            <select
              className="form-control form-select col-12"
              id="categoryId"
              onChange={handleCategorySelect}
              required
            >
              <option value="" disabled selected>
                Select Category
              </option>
              {categoryList.map((item) => (
                <option key={item._id} value={item._id} selected={item._id == section}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="inventory">
              Brand
            </label>
            <select
              className="form-control form-select col-12"
              id="categoryId"
              onChange={handleBrandChange}
              required
            >
              <option value="" disabled selected>
                Select Brand
              </option>
              {brandList.map((item) => (
                <option key={item._id} value={item._id} selected={item._id == brand}>
                  {item.brandName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col">
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="inventory">
              Inventory
            </label>
            <select
              className="form-control form-select col-12"
              id="inventoryId"
              onChange={handleInventorySelect}
              required
            >
              <option value="" disabled selected>
                Select Inventory
              </option>
              {inventoryList.map((item) => (
                <option key={item._id} value={item._id} selected={item._id == inventory}>
                  {item.materialName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {/* <Contractors/>       */}
      <div className="form-outline row mb-4">
        <button
          type="button"
          className="btn"
          onClick={() => removeBundleItem(id)}
        >
          <i className="bi bi-plus-circle pe-2 " />
          Remove Item
        </button>
      </div>

      {/* <div className="row mb-4">
        <div className="col">
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="inventory">
              Variant
            </label>
            <select
              className="form-control form-select col-12"
              id="variant"
              onChange={handleVariantChange}
              required
            >
              <option value="" disabled selected>
                Select Variant
              </option>
              {variantList.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.variantName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default BundleItem;
