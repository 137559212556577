import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";

export default class Enquiry extends Component {
  state = {
    message: "",
    title: "",
    phoneNo: this.props.user?.phoneNo || "",
    disable: false,
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    this.setState({ disable: true });
    let data = {
      title: this.state.title,
      message: this.state.message,
      phoneNo: this.state.phoneNo,
    };
    let config = {
      headers: { authorization: `Token ${this.props.token}` },
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}enquiry/create`, data, config)
      .then((res) => {
        alert(
          "Your enquiry has been sent. The admin will contact you as soon as possible"
        );
        this.setState({ disable: false });
      });
  };

  render() {
    return (
      <>
        <Modal.Header>
          <Modal.Title>Enquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <label className="form-label" htmlFor="title">
            If you can't find the options to suit your requirements, you can
            enquire the admin.
          </label>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="title">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              className="form-control"
              value={this.state.title}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="5"
              className="form-control"
              value={this.state.message}
              onChange={this.handleInputChange}
              required
            />
          </div>
          <div className="form-outline mb-4">
            <label className="form-label" htmlFor="phoneNo">
              Phone No
            </label>
            <input
              type="text"
              id="phoneNo"
              name="phoneNo"
              className="form-control"
              value={this.state.phoneNo}
              onChange={this.handleInputChange}
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.handleModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary2"
            onClick={this.onSubmit}
            disabled={this.state.disable}
          >
            Submit
          </Button>
        </Modal.Footer>
      </>
    );
  }
}
