import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import LengthComponent from "./LengthComponent";
import AreaComponent from "./AreaComponent";
import toast from "react-hot-toast";

class AddStock extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      inventoryId: "",
      vendor: "",
      vendorId: "",
      userType: "vendor",
      variant: "",
      currentStock: "",
      weight: null,
      location: "",
      materials: [],
      inventories: [],
      vendors: [],
      locations: [],
      loading: false,
      length: "",
      variants: [],
      totalWeight: null,
      inventoryType: 0,
      selectedVariant: {},
      updateValues: [],
      inventoryVariants: [],
      sections: [],
      colors: [],
      color: "",
      isColorAvailable: false,
      categoryId: "",
      materialTypeId: "",
    };
  }
  notify = (message) => toast.error(message);

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleVariantChange = (e) => {
    let id = e.target.value;
    let selectedVariant = this.state.variants.filter((i) => i._id === id);
    this.setState({
      selectedVariant: selectedVariant[0],
      variant: id,
      updateValues: selectedVariant[0],
    });
  };

  handleAreaData = (data) => {
    this.setState({ totalArea: data.totalArea, length: data.length }, () => {
      console.log(this.state);
    });
  };

  handleLengthData = (data) => {
    this.setState(
      { totalWeight: data.totalWeight, currentStock: data.currentStock },
      () => {
        console.log(this.state);
      }
    );
  };

  handleMaterialTypeChange = ({ target }) => {
    this.setState({
      materialTypeId: target.value,
      inventoryId: "",
      categoryId: "",
    });
    let data = { params: { materialType: target.value } };
    axios
      .get(`${process.env.REACT_APP_API_URL}section/list`, data)
      .then((res) => {
        this.setState({ sections: res.data.data });
      });
  };

  handleSectionChange = ({ target }) => {
    this.setState({ categoryId: target.value, inventoryId: "" });
    let data = { params: { categoryId: target.value } };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/list`, data)
      .then((res) => {
        this.setState({ inventories: res.data.data });
      });
  };

  handleInventoryChange = (e) => {
    let inventory = this.state.inventories.find(
      (i) => i._id === e.target.value
    );
    if (inventory) {
      this.setState({
        inventoryId: e.target.value,
        weight: inventory.weight,
        variants: inventory.variants,
        threshold: inventory.threshold,
        inventoryType: inventory.inventoryType || 0,
        inventoryVariants: inventory.inventoryVariants,
        colors: inventory.colors,
        isColorAvailable: inventory.isColorAvailable,
      });
    }
  };

  handleColorChange = ({ target }) => {
    this.setState({ color: target.value });
  };

  handleCodeChange = (e) => {
    let code = this.state.inventoryVariants.find(
      (i) => i._id === e.target.value
    );
    if (code)
      this.setState({
        weight: code.weight,
        inventoryVariant: e.target.value,
      });
  };

  handleVendorChange = (e) => {
    this.setState({ vendorId: e.target.value });
  };

  requestBodyBuilder = () => {
    var data = {
      inventoryId: this.state.inventoryId,
      vendorId: this.state.vendorId,
      variant: this.state.variant,
      inventoryType: this.state.inventoryType,
      inventoryVariant: this.state.inventoryVariant,
      color: this.state.color,
    };
    if (this.state.inventoryType === 0) {
      data.currentStock = this.state.currentStock;
      data.weight = this.state.totalWeight;
    } else if (this.state.inventoryType === 1) {
      data.length = this.state.length;
      data.totalArea = this.state.totalArea;
    }
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    var data = this.requestBodyBuilder();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}stock/add-stock`,
        data,
        this.state.config
      )
      .then((res) => {
        if (res) {
          this.props.history.push("/admin/viewstock");
        }
      })
      .catch(({ response }) => {
        this.notify(response.data.message);
        this.setState({ loading: false });
      });
  };

  getMaterialTypes = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}material/list`)
      .then((res) => this.setState({ materials: res.data.data }));
  };

  componentDidMount() {
    this.getMaterialTypes();
    axios.get(`${process.env.REACT_APP_API_URL}vendor/`, {}).then((res) =>
      this.setState(
        {
          vendors: res.data.vendors,
        },
        () => console.log(res.data, "vendor")
      )
    );
    axios.get(`${process.env.REACT_APP_API_URL}location/`, {}).then((res) =>
      this.setState(
        {
          locations: res.data,
        },
        () => console.log(this.state)
      )
    );
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);
    if (userData) {
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        this.setState({ config: config });
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Add Stock</h1>
                {/* <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary"
                >
                  Button
                </button> */}
              </div>

              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="row mb-4">
                    {/*  */}
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="vendor">
                          Vendor
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="vendorId"
                          value={this.state.vendorId}
                          onChange={this.handleVendorChange}
                          required
                        >
                          <option value={""} selected disabled>
                            Select Vendor
                          </option>
                          {this.state.vendors.map((item, index) => (
                            <option key={`vendor${index}`} value={item._id}>
                              {item.vendorName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialTypeId">
                          Material Type
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="materialTypeId"
                          value={this.state.materialTypeId}
                          onChange={this.handleMaterialTypeChange}
                          required
                        >
                          <option value="" disabled selected>
                            Select Material Type
                          </option>
                          {this.state.materials.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.materialType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="inventory">
                          Section
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="sectionId"
                          value={this.state.sectionId}
                          onChange={this.handleSectionChange}
                          required
                        >
                          <option value="" selected>
                            Select Section
                          </option>
                          {this.state.sections.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="inventory">
                          Inventory
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="inventoryId"
                          value={this.state.inventoryId}
                          onChange={this.handleInventoryChange}
                          required
                        >
                          <option value="" selected>
                            Select Inventory
                          </option>
                          {this.state.inventories.map((item, index) => (
                            <option key={item._id} value={item._id}>
                              {item.materialName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="inventory">
                          Code
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="inventoryVariant"
                          value={this.state.inventoryVariant}
                          onChange={this.handleCodeChange}
                          required
                        >
                          <option value="" selected>
                            Select Code
                          </option>
                          {this.state.inventoryVariants.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.materialCode}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="inventory">
                          Color
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="color"
                          value={this.state.color}
                          onChange={this.handleColorChange}
                          required={this.state.isColorAvailable}
                          disabled={!this.state.isColorAvailable}
                        >
                          <option value="" selected>
                            Select Color
                          </option>
                          {this.state.colors.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    {this.state.inventoryType === 0 && (
                      <div className="col">
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="Threshold">
                            Weight (kg)
                          </label>
                          <input
                            type="number"
                            id="weight"
                            className="form-control"
                            value={this.state.weight}
                            disabled
                          />
                        </div>
                      </div>
                    )}

                    <div className="col">
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="threshold">
                          Threshold (kg)
                        </label>
                        <input
                          type="number"
                          id="threshold"
                          className="form-control"
                          value={this.state.threshold}
                          disabled
                        />
                      </div>
                    </div>
                    {this.state.inventoryType !== 2 && (
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="variant">
                            {this.state.inventoryType === 0
                              ? "Length (m) "
                              : this.state.inventoryType === 1 && "Width (m)"}
                          </label>
                          <select
                            className="form-control form-select col-12"
                            id="variant"
                            value={this.state.variant}
                            onChange={this.handleVariantChange}
                            disabled={this.state.inventoryType === 2}
                            required
                          >
                            <option value="" disabled selected>
                              {this.state.inventoryType === 0
                                ? "Select Length"
                                : this.state.inventoryType === 1 &&
                                  "Select Width"}
                            </option>
                            {this.state.variants.map((item, index) => (
                              <option key={item._id} value={item._id}>
                                {item.variantName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  {this.state.inventoryType !== 2 &&
                  this.state.inventoryType === 0 ? (
                    <LengthComponent
                      weight={this.state.weight}
                      updateValues={this.state.updateValues}
                      handleDataFromComponents={this.handleLengthData}
                    />
                  ) : (
                    this.state.inventoryType !== 2 && (
                      <AreaComponent
                        width={this.state.selectedVariant}
                        updateValues={this.state.updateValues}
                        handleDataFromComponents={this.handleAreaData}
                      />
                    )
                  )}
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    Add Stock
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
        {/* </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

export default AddStock;
