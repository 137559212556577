import React, { Component } from "react";
import convert from "convert-units";
import axios from "axios";

export default class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variant: "",
      weightPerMeter: 0,
      weight: 0,
      pieces: 0,
      itemPrice: 0,
      price: "",
      sectionId: "",
      imageUrl: "",
      materialImageUrl: "",
      materialNameId: "",
      materialTypeId: "",
      materials: [],
      sections: [],
      materialTypes: this.props.materialTypes,
      finish: "Black",
      lengthType: "1",
      variants: [],
      inventoryType: 1,
      area: 0,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value }, () => {
      this.handleWeight();
      this.handleData();
    });
  };

  getVariant = () => {
    if (!this.state.variant) return;
    let variant = this.state.variants.filter(
      (i) => i.variantName === this.state.variant
    );
    return variant[0]._id;
  };

  getPrice = () => {
    let itemPrice;
    if (this.state.inventoryType === 0) {
      itemPrice = this.state.price * this.state.weight;
    } else if (this.state.inventoryType === 1) {
      itemPrice = this.state.price * this.state.totalArea * this.state.pieces;
    }
    return itemPrice;
  };

  handleData = () => {
    let data = {
      materialName: this.state.materialNameId,
      materialType: this.state.materialTypeId,
      section: this.state.sectionId,
      variant: this.getVariant(),
      weightPerMeter: this.state.weightPerMeter,
      weight: this.state.weight,
      pieces: this.state.pieces,
      finish: this.state.finish,
      itemPrice: this.getPrice(),
      length: this.state.length,
      price: this.state.price,
      totalArea: this.state.totalArea,
      inventoryType: this.state.inventoryType,
    };
    console.log(data);
    this.props.handleItems(this.props.id, data);
  };

  handleUnitChange = (e) => {
    this.setState({ lengthType: e.target.value });
  };

  showPrice = (type, id) => {
    let materialType = this.props.materialTypes.filter(
      (i) => i._id === this.state.materialTypeId
    );
    if (materialType[0].isPriceBasedOnMaterial) {
      this.setState({ price: materialType[0].price });
    } else if (type === 2) {
      let section = this.state.sections.filter((i) => i._id === id);
      if (section.length > 0) this.setState({ price: section[0].price });
    }
  };

  handleMaterialTypeChange = (e) => {
    this.setState(
      {
        materialTypeId: e.target.value,
        sections: [],
        sectionId: "",
        materials: [],
        imageUrl: "",
        materialImageUrl: "",
        materialNameId: "",
        variants: [],
        variant: "",
      },
      () => this.showPrice(1)
    );
    let data = { params: { materialTypeId: e.target.value } };
    axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
      console.log(res.data);
      this.setState({
        sections: res.data.sections,
      });
    });
    this.handleData();
  };

  handleSectionChange = (e) => {
    this.setState({
      sectionId: e.target.value,
      materials: [],
      materialImageUrl: "",
      materialNameId: "",
      imageUrl: "",
      variants: [],
      variant: "",
    });
    this.showPrice(2, e.target.value);
    let imageUrl = this.state.sections.filter((i) => i._id === e.target.value);
    this.setState({ imageUrl: imageUrl[0].drawing });
    let data = { params: { sectionId: e.target.value } };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/`, data)
      .then((res) => {
        this.setState({ materials: res.data.inventory });
      });
    this.handleData();
  };

  // getSection = (id, materialId) => {
  //   let data = { params: { materialTypeId: id } };
  //   axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
  //     this.setState({ sections: res.data.sections }, () =>
  //       this.changeSection(materialId)
  //     );
  //   });
  // };

  // changeSection = (materialId) => {
  //   console.log(materialId);
  //   let imageUrl = this.state.sections.filter(
  //     (i) => i._id === this.state.sectionId
  //   );
  //   this.setState({ imageUrl: imageUrl[0].drawing });
  //   let data = { params: { sectionId: this.state.sectionId } };
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}inventory/`, data)
  //     .then((res) => {
  //       let materialImageUrl = res.data.inventory.filter(
  //         (i) => i._id === materialId
  //       );
  //       if (materialImageUrl[0]) {
  //         this.setState({
  //           materials: res.data.inventory,
  //           materialNameId: materialId,
  //           materialImageUrl: materialImageUrl[0].drawing,
  //           variants: materialImageUrl[0].variants,
  //         });
  //       }
  //     });
  // };

  handleMaterialChange = (event) => {
    let imageUrl = this.state.materials.filter(
      (i) => i._id === event.target.value
    );
    this.setState(
      {
        materialImageUrl: imageUrl[0].drawing,
        materialNameId: event.target.value,
        inventoryType: imageUrl[0].inventoryType,
        variant: "",
      },
      () => {
        this.state.materials.map((item) => {
          console.log(item._id, "ttttt", this.state.materialNameId);
          if (item._id === this.state.materialNameId) {
            return this.setState({
              weightPerMeter: item.weight,
              variants: item.variants,
            });
          }
          return null;
        });
        this.handleWeight();
        this.handleData();
      }
    );
  };

  handlePrice = () => {
    if (!this.state.materialNameId) return;
    let selectedMaterial = this.state.materials.filter(
      (item) => item._id === this.state.materialNameId
    );
    let { materialType, section } = selectedMaterial[0];
    let price = materialType.isPriceBasedOnMaterial
      ? materialType.price
      : section.price;
    let itemPrice = price * this.state.weight;
    this.setState({ itemPrice: itemPrice }, () => this.handleData());
  };

  handleWeight = () => {
    let weight =
      this.state.weightPerMeter *
      Number(this.state.variant) *
      this.state.pieces;
    this.setState({ weight: weight }, () => this.handlePrice());
  };

  convertLength = (value) => {
    if (this.state.lengthType === "2") return convert(value).from("m").to("ft");
    else return value;
  };

  handleWidthChange = (e) => {
    let width = e.target.value;
    let totalArea = 0;
    if (this.state.length) {
      totalArea = Number(this.state.length) * Number(width);
      let itemPrice = this.state.totalArea * this.state.pieces;
      this.setState({
        variant: width,
        totalArea: totalArea,
        itemPrice: itemPrice,
      });
    } else {
      this.setState({ variant: width });
    }
  };

  handleLength = (e) => {
    let length = e.target.value;
    let totalArea = 0;
    if (this.state.variant) {
      let newLength = length;
      totalArea = Number(length) * Number(this.state.variant);
      if (this.state.lengthType === "2") {
        newLength = this.convertLength(Number(length));
        totalArea = Number(newLength) * Number(this.state.variant);
      }
      let itemPrice = this.state.totalArea * this.state.pieces;
      this.setState({
        length: length,
        totalArea: totalArea,
        itemPrice: itemPrice,
      });
    } else {
      this.setState({ length: length });
    }
  };

  getArea = () => {
    let length = this.state.length;
    if (this.state.lengthType === "2") {
      let width = convert(this.state.variant).from("m").to("ft");
      let area = length * width * this.state.pieces;
      console.log("length",length,"* width",width," * pieces",this.state.pieces,"=",area)
      return area;
    } else {
      let area = length * Number(this.state.variant) * this.state.pieces;
      console.log("length",length,"* width",this.state.variant," * pieces",this.state.pieces,"=",area)
      return area;
    }
  };

  render() {
    let itemPrice = 0;
    if (this.state.inventoryType === 0) {
      itemPrice = this.state.price * this.state.weight;
    } else if (this.state.inventoryType === 1) {
      itemPrice = this.state.price * this.state.totalArea * this.state.pieces;
    }
    let area = this.getArea();
    let price = this.state.price
    if(this.state.lengthType==="2"&&this.state.inventoryType === 1){
      price = price * 0.3048
    }
    return (
      <>
        <div className="float-right" style={{ float: "right" }}>
          <button
            className="btn"
            disabled={this.props.length === 1}
            onClick={() => this.props.deleteItem(this.props.id)}
          >
            <i className="bi bi-trash-fill" />
          </button>
        </div>
        <div className="form-outline mb-4">
          <label className="form-label" htmlFor="materialTypeId">
            Material Type
          </label>
          <select
            className="form-control form-select col-12"
            id="materialTypeId"
            value={this.state.materialTypeId}
            onChange={this.handleMaterialTypeChange}
            required
          >
            {" "}
            <option value="" disabled selected>
              Select Material Type
            </option>
            {this.props.materialTypes.map((item, index) => (
              <option key={item._id} value={item._id}>
                {item.materialType}
              </option>
            ))}
          </select>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="sectionId">
                Category
              </label>
              <select
                className="form-control form-select col-12"
                id="sectionId"
                value={this.state.sectionId}
                onChange={this.handleSectionChange}
                required
              >
                {" "}
                <option value="" disabled selected>
                  Select Category
                </option>
                {this.state.sections.map((item, index) => (
                  <option key={`material${index}`} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {this.state.imageUrl && (
            <div className="col-6">
              <img
                className="orders-img"
                src={`${process.env.REACT_APP_IMAGE_URL + this.state.imageUrl}`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <div className="form-outline">
              <label className="form-label" htmlFor="materiaName">
                Material Name
              </label>
              <select
                className="form-control form-select col-12"
                id="materialNameId"
                value={this.state.materialNameId}
                onChange={this.handleMaterialChange}
                required
              >
                {" "}
                <option value="" disabled selected>
                  Select Material Name
                </option>
                {this.state.materials.map((item, index) => (
                  <option key={`material${index}`} value={item._id}>
                    {`${item.materialName} (Code ${item.materialCode})`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {this.state.imageUrl && (
            <div className="col-6">
              <img
                className="orders-img"
                src={`${
                  process.env.REACT_APP_IMAGE_URL + this.state.materialImageUrl
                }`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="row mb-4">
          <div className="col-6">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="weightPerMeter">
                  Weight/m
                </label>
                <input
                  type="number"
                  id="weightPerMeter"
                  className="form-control"
                  value={this.state.weightPerMeter}
                  disabled
                />
              </div>
            ) : (
              this.state.inventoryType === 1 && (
                <div className="form-outline">
                  <label className="form-label" htmlFor="length">
                    Length ({this.state.lengthType === "1" ? "m" : "feet"})
                  </label>
                  <input
                    type="number"
                    id="length"
                    className="form-control"
                    value={this.state.length}
                    onChange={this.handleLength}
                  />
                </div>
              )
            )}
          </div>
          <div className="col-2">
            <div className="form-outline">
              <label className="form-label" htmlFor="lengthType">
                Unit
              </label>
              <select
                className="form-control form-select col-12"
                id="lengthType"
                value={this.state.lengthType}
                onChange={this.handleUnitChange}
              >
                <option value={"1"}>m</option>
                <option value={"2"}>feet</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="variant">
                  Length(
                  {this.state.lengthType === "1" ? "m" : "feet"})
                </label>
                <select
                  className="form-control form-select col-12"
                  id="variant"
                  value={this.state.variant}
                  onChange={this.handleInputChange}
                  required
                >
                  {" "}
                  <option value="" disabled selected>
                    Select variant
                  </option>
                  {this.state.variants.map((item, index) => (
                    <option key={item._id} value={item.variantName}>
                      {this.convertLength(item.variantName)}
                    </option>
                  ))}
                </select>
                <small
                  className="float-right"
                  style={{ float: "right", cursor: "pointer" }}
                  title="Can't find the length you're looking for?"
                  onClick={() => this.props.handleModal(true)}
                >
                  <i class="bi bi-question-square-fill"></i>
                </small>
              </div>
            ) : (
              this.state.inventoryType === 1 && (
                <div className="form-outline">
                  <label className="form-label" htmlFor="variant">
                    Width ({this.state.lengthType === "1" ? "m" : "feet"})
                  </label>
                  <select
                    className="form-control form-select col-12"
                    id="variant"
                    value={this.state.variant}
                    onChange={this.handleWidthChange}
                    required
                  >
                    {" "}
                    <option value="" disabled selected>
                      Select Width
                    </option>
                    {this.state.variants.map((item, index) => (
                      <option key={item._id} value={item.variantName}>
                        {this.convertLength(item.variantName)}
                      </option>
                    ))}
                  </select>
                  <small
                    className="float-right"
                    style={{ float: "right", cursor: "pointer" }}
                    title="Can't find the length you're looking for?"
                    onClick={() => this.props.handleModal(true)}
                  >
                    <i class="bi bi-question-square-fill"></i>
                  </small>
                </div>
              )
            )}
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="pieces">
                Pieces
              </label>
              <input
                type="number"
                id="pieces"
                className="form-control"
                value={this.state.pieces}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col">
            {this.state.inventoryType === 0 ? (
              <div className="form-outline">
                <label className="form-label" htmlFor="weight">
                  Weight(kg)
                </label>
                <input
                  type="number"
                  id="weight"
                  className="form-control"
                  value={this.state.weight}
                  disabled
                />
              </div>
            ) : (
              <div className="form-outline">
                <label className="form-label" htmlFor="totalArea">
                  Square {this.state.lengthType === "1" ? "meter" : "feet"}
                </label>
                <input
                  type="number"
                  id="totalArea"
                  className="form-control"
                  value={area}
                  disabled
                />
              </div>
            )}
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="itemPrice">
                Item Price
              </label>
              <input
                type="number"
                id="price"
                className="form-control"
                value={price}
                disabled
              />
            </div>
          </div>
          <div className="col">
            <div className="form-outline">
              <label className="form-label" htmlFor="itemPrice">
                Item Total
              </label>
              <div className="input-group">
                <span class="input-group-text br-0 border-gray bg-white">
                  ₹
                </span>
                <input
                  type="number"
                  id="itemPrice"
                  className="form-control bl-0 rounded-l-0"
                  value={itemPrice}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
