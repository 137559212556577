import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";

class ViewVendors extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      newStock: 0,
      vendor: "",
      stock: [],
      vendors: [],
    };
  }

  handleVendorChange = (e) => {
    this.setState({ vendor: e.target.value });
    // let config = this.state.user?.token
    //   ? {
    //       headers: { Authorization: `Token ${this.state.user.token}` },
    //       params: { orderType: this.state.orderType },
    //     }
    //   : {};
    let data = { user: e.target.value };
    axios
      .get(`${process.env.REACT_APP_API_URL}stock/`, { params: data })
      .then((res) => this.setState({ stock: res.data }));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
    axios.get(`${process.env.REACT_APP_API_URL}vendor/`, {}).then((res) =>
      this.setState(
        {
          vendors: res.data.vendors,
        },
        () => console.log(this.state)
      )
    );
  }

  render() {
    console.log("state : " + this.state);
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Vendors</h1>
              </div>
              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Vendor Name</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vendors.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>
                            {item.vendorName}
                            {item.isAdminStore && " (Admin Store)"}
                          </td>
                          <td>
                            <Link
                              className="me-2"
                              to={{
                                pathname: "/admin/edit-vendor/" + item._id,
                              }}
                              style={{
                                pointerEvents: item.isAdminStore && "none",
                              }}
                            >
                              <button
                                className="btn text-sm btn-outline-secondary border-0"
                                disabled={item.isAdminStore}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                  <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  ></path>
                                </svg>
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewVendors;
