import axios from 'axios';
import React, { useState,useEffect } from 'react';
import Select from 'react-select';




function Contractors({ selectedContractors, onContractorsChange }) {
    const [data,setData] = useState([])
    const [name,setName] = useState([])
    const userId=selectedContractors


    useEffect(() => {
        axios
        .get(process.env.REACT_APP_API_URL + "user/contractors-list")
        .then((res) => {
          setData(res.data.users);
        });
    }, [])

    console.log('uid',userId);


    useEffect(() => {
        const fechdata=async()=>{
           try{
               const response= await axios.get( process.env.REACT_APP_API_URL + "user/getcontractor/"+userId)
               console.log('vgvgh',response.data.users);
               setName(response.data.users)
             } 
             catch (error) {
           console.error('Error fetching user data:', error);
       }
        }
        fechdata()
       },[userId])  


    //  const Uname= name.map(c=>({
    //   value:c._id,
    //   label:c.firstName
    //  }))  
    //  console.log('c',Uname);
    
    const options = data.map(item=>({
        value:item._id,
        label:item.firstName
    }))

  return (
    <div className="App">
    <label className="form-label" htmlFor="variantName">
        Select Expert Contractors
    </label>
    <Select
      onChange={onContractorsChange}
      options={options}
      isMulti
      closeMenuOnSelect={false}
      // value={selectedContractors}
    />
  </div>
  )
}

export default Contractors