import React from "react";
import axios from "axios";
import Header from "../Header";
import SidebarUser from "../SideBar2";
import { nanoid } from "nanoid";
import Modal from "react-bootstrap/Modal";
import Enquiry from "./Enquiry";
import ItemEdit from "./ItemEdit";

class EditOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      data: {},
      orderNumber: "",
      projectName: "",
      customerName: "",
      length: "",
      weightPerMeter: 0,
      weight: 0,
      pieces: 0,
      totalPrice: 0,
      deliveryLocation: "",
      sectionId: "",
      imageUrl: "",
      materialImageUrl: "",
      deliveryDate: "",
      remarks: "",
      materialNameId: "",
      materialTypeId: "",
      materials: [],
      sections: [],
      materialTypes: [],
      finish: "Black",
      isUserLoggedIn: false,
      isOrderPlaced: false,
      lengthType: "1",
      status: "",
      variants: [],
      items: [
        {
          id: nanoid(),
          materialName: "",
          length: "",
          weightPerMeter: "",
          weight: "",
          pieces: "",
          finish: "",
          itemPrice: "",
        },
      ],
      showVendorModal: false,
      loaded: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleModal = (modalState) => {
    this.setState({ showVendorModal: modalState });
  };

  handleItems = (id, value) => {
    let items = [...this.state.items];
    // let temp = { ...variant[id], variantName: value };
    let tempData = items[id];
    tempData.materialName = value.materialName;
    tempData.materialType = value.materialType;
    tempData.section = value.section;
    tempData.variant = value.variant;
    tempData.pieces = value.pieces;
    tempData.finish = value.finish;
    tempData.itemPrice = value.itemPrice;
    tempData.inventoryType = value.inventoryType;

    if (value.inventoryType === 0) {
      tempData.weight = value.weight;
      tempData.weightPerMeter = value.weightPerMeter;
    } else if (value.inventoryType === 1) {
      tempData.length = value.length;
      tempData.totalArea = value.totalArea;
    }
    console.log(value)
    items[id] = tempData;
    this.setState({ items: items }, () => this.getTotalPrice());
  };

  addItem = () => {
    this.setState({
      items: [
        ...this.state.items,
        {
          id: nanoid(),
          materialName: "",
          length: "",
          weightPerMeter: "",
          weight: "",
          pieces: "",
          finish: "",
          itemPrice: "",
        },
      ],
    });
  };

  deleteItem = (id) => {
    var tempList = this.state.items.filter((item, index) => index !== id);
    this.setState({ items: tempList });
  };

  getSection = (id, materialId) => {
    let data = { params: { materialTypeId: id } };
    axios.get(`${process.env.REACT_APP_API_URL}section/`, data).then((res) => {
      this.setState({ sections: res.data.sections }, () =>
        this.changeSection(materialId)
      );
    });
  };

  changeSection = (materialId) => {
    console.log(materialId);
    let imageUrl = this.state.sections.filter(
      (i) => i._id === this.state.sectionId
    );
    this.setState({ imageUrl: imageUrl[0].drawing });
    let data = { params: { sectionId: this.state.sectionId } };
    axios
      .get(`${process.env.REACT_APP_API_URL}inventory/`, data)
      .then((res) => {
        let materialImageUrl = res.data.inventory.filter(
          (i) => i._id === materialId
        );
        if (materialImageUrl[0]) {
          this.setState({
            materials: res.data.inventory,
            materialNameId: materialId,
            materialImageUrl: materialImageUrl[0].drawing,
            variants: materialImageUrl[0].variants,
          });
        }
      });
  };

  getLength = () => {
    let variant = this.state.variants.filter(
      (i) => i.variantName === this.state.length
    );
    return variant[0]._id;
  };

  getTotalPrice = () => {
    let totalPrice = this.state.items.reduce(
      (total, item) => total + item.itemPrice,
      0
    );
    let precision = Math.pow(10, 2);
    totalPrice = Math.ceil(totalPrice * precision) / precision;
    this.setState({ totalPrice: totalPrice });
  };

  requestBodyBuilder = () => {
    let data = {
      projectName: this.state.projectName,
      customerName: this.state.customerName,
      deliveryDate: this.state.deliveryDate,
      items: this.state.items,
      remarks: this.state.remarks,
      deliveryLocation: this.state.deliveryLocation,
      totalPrice: this.state.totalPrice,
      id: this.state.id,
      status: this.state.status,
    };
    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = this.requestBodyBuilder();
    axios
      .put(`${process.env.REACT_APP_API_URL}order/edit`, data, config)
      .then((res) => {
        console.log(res);
        this.setState({ isOrderPlaced: true });
        if (this.state.user.userType === "admin") {
          this.props.history.push("/admin/vieworders?option=all");
        } else {
          this.props.history.push("/vieworders");
        }
      })
      .catch((err) => console.log(err));
  };

  populateData = (data) => {
    this.setState({
      orderNumber: data.orderNumber,
      projectName: data.projectName,
      customerName: data.customerName,
      deliveryDate: data.deliveryDate.split("T")[0],
      items: data.items,
      remarks: data.remarks,
      deliveryLocation: data.deliveryLocation,
      totalPrice: data.totalPrice,
      loaded: true,
      status: data.status,
    });
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    axios.get(`${process.env.REACT_APP_API_URL}material/`, {}).then((res) => {
      console.log(res.data);
      this.setState({
        materialTypes: res.data.materialTypes,
      });
    });
    if (user) {
      let customerName = user.firstName === undefined ? "" : user.firstName;
      this.setState(
        {
          user: user,
          token: user.token,
          customerName: customerName,
          id: this.props.match.params.id,
        },
        () => {
          let config = {
            headers: { authorization: `Token ${this.state.user.token}` },
            params: {
              id: this.props.match.params.id,
            },
          };
          axios
            .get(`${process.env.REACT_APP_API_URL}order/getorder`, config)
            .then((res) => {
              console.log(res.data.orderDetails);
              this.populateData(res.data.orderDetails);
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      );
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SidebarUser />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <Modal
                show={this.state.showVendorModal}
                onHide={() => this.handleModal(false)}
                size="lg"
              >
                <Enquiry
                  handleModal={this.handleModal}
                  token={this.state.token}
                  user={this.state.user}
                />
              </Modal>
              <div className="auth-page">
                <div className="container page">
                  <div className="row">
                    <div className="col-xxl-6 col-lg-8 col-md-10 mx-auto offset-md-3">
                      <div
                        class="alert alert-success"
                        style={{
                          display: this.state.isOrderPlaced ? "block" : "none",
                        }}
                        onClick={() => this.props.history.push("/vieworders")}
                      >
                        <strong>Success!</strong> Order has been placed.
                      </div>
                      <h1 className="Heading ps-0">
                        Edit Order: {this.state.orderNumber.toUpperCase()}
                      </h1>
                      <p className="text-xs-center"></p>
                      <form onSubmit={(event) => this.submitOrder(event)}>
                        {/* 2 column grid layout with text inputs for the first and last names */}

                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="projectName">
                            Project Name
                          </label>
                          <input
                            type="text"
                            id="projectName"
                            className="form-control"
                            value={this.state.projectName}
                            onChange={this.handleInputChange}
                            // required
                          />
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="customerName">
                            Customer Name
                          </label>
                          <input
                            type="text"
                            id="customerName"
                            className="form-control"
                            value={this.state.customerName}
                            onChange={this.handleInputChange}
                            // required
                          />
                        </div>
                        {this.state.items.map((item, index) => (
                          <div className="border-bottom border-dark border-5px my-3">
                            <ItemEdit
                              key={item.id}
                              materialTypes={this.state.materialTypes}
                              handleItems={this.handleItems}
                              deleteItem={this.deleteItem}
                              length={this.state.items.length}
                              data={item}
                              id={index}
                              handleModal={this.handleModal}
                              user={this.state.user}
                              loaded={this.state.loaded}
                            />
                          </div>
                        ))}
                        <button
                          type="button"
                          className="btn"
                          onClick={() => this.addItem()}
                        >
                          <i className="bi bi-plus-circle pe-2 " />
                          Add Item
                        </button>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="totalprice">
                            Total Price
                          </label>
                          <div className="input-group">
                            <span class="input-group-text br-0 border-gray bg-white">
                              ₹
                            </span>
                            <input
                              type="number"
                              id="totalprice"
                              className="form-control bl-0 rounded-l-0"
                              value={this.state.totalPrice}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="deliveryDate"
                              >
                                Delivery Date
                              </label>
                              <input
                                type="date"
                                id="deliveryDate"
                                className="form-control"
                                value={this.state.deliveryDate}
                                onChange={this.handleInputChange}
                                // required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="location">
                            Delivery Location
                          </label>
                          <div className="input-group">
                            <span class="input-group-text br-0 border-gray bg-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-geo-alt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              id="deliveryLocation"
                              value={this.state.deliveryLocation}
                              onChange={this.handleInputChange}
                              className="form-control bl-0 rounded-l-0"
                              // required
                            />
                          </div>
                        </div>

                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="remarks">
                            Remarks
                          </label>
                          <textarea
                            className="form-control"
                            id="remarks"
                            rows={4}
                            value={this.state.remarks}
                            onChange={this.handleInputChange}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary2 btn-block mb-4"
                          disabled={this.state.isOrderPlaced}
                        >
                          Place order
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default EditOrder;
