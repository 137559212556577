import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import queryString from "query-string"
import ViewOrderDetails from "./ViewOrderDetails";

class ViewVendorOrderDetailsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      vendorId: "",
      orders: [],
      approved: true,
      orderDetails: {},
      stocks: [],
      orderType: "all",
      isOrderLoaded: true,
      viewModalState: false,
      driverName: "",
      driverContactNumber: "",
      vehicleNumber: "",
      items: [],
      action: "confirm",
    };
  }
  openImage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleVendorModal = (modalState) => {
    if (this.state.orderDetails.status !== "approved") {
      this.getVendors();
      this.setState({ showVendorModal: modalState });
    } else {
      this.getOrderDetails();
      this.setState({ showVendorModal: modalState });
    }
  };

  handleOrderTypeChange = (e) => {
    this.setState({ orderType: e.target.value });
    let config = {
      headers: { Authorization: `Token ${this.state.token}` },
      params: { orderType: e.target.value },
    };
    axios.get(`${process.env.REACT_APP_API_URL}order/`, config).then((res) => {
      this.setState({ isOrderLoaded: true, orders: res.data }, () =>
        console.log("orders==>", this.state.orders)
      );
      console.log(res);
    });
  };

  createApprovalBody = () => {
    let materialNames = [];
    materialNames = this.state.items.map((item) => ({
      materialNameId: item.inventory._id,
      weight: item.totalWeight,
      pieces: item.pieces,
      totalArea: item.totalArea,
      inventoryType: item.inventoryType,
      color: item.color,
    }));
    let data = {
      action: "confirm",
      id: this.state.id,
      itemList: materialNames,
      vehicleNumber: this.state.vehicleNumber,
      vendor: this.state.vendorId,
      driverName: this.state.driverName,
      driverContactNumber: this.state.driverContactNumber,
    };
    return data;
  };

  handleApproval = () => {
    this.setState({ showVendorModal: false });
    let config = { headers: { Authorization: `Token ${this.state.token}` } };
    let data = this.createApprovalBody();
    axios
      .put(`${process.env.REACT_APP_API_URL}order/approve-order`, data, config)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            approved: true,
            selectVendor: false,
            isOrderLoaded: false,
          });
          this.getOrderDetails();
        } else {
          console.log("stock not available");
        }
      });
    // } else {
    //   this.setState({ selectVendor: true });
    // }
  };

  createStockCheckBody = () => {
    let data = [];
    data = this.state.items.map((item) => ({
      name: item.inventory._id,
      variant: item.variant?._id,
      piecesRequired: item.pieces,
      inventoryType: item.inventoryType,
      totalArea: item.totalArea,
      color: item.color || "",
    }));
    return data;
  };

  getVendors = () => {
    let data = this.createStockCheckBody();
    let config = {
      headers: { Authorization: `Token ${this.state.token}` },
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}stock/checkstocks`,
        { materialList: data },
        config
      )
      .then(
        (res) =>
          this.setState(
            {
              stocks: res.data.vendors,
            },
            () => console.log(res)
          )
        // console.log(res)
      );
  };

  getOrder = () => {
    let id = this.props.match.params.id;
    let config = {
      headers: { Authorization: `Token ${this.state.user.token}` },
      params: { id: id },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}order/getorderdetail/`, config)
      .then((res) => {
        this.setState({
          items: res.data.items,
          orderDetails: res.data,
          isOrderLoaded: true,
        });
        console.log(res);
      });
  };

  getOrderDetails = () => {
    let id = this.props.match.params.id;
    let config = {
      headers: { Authorization: `Token ${this.state.user.token}` },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}order/get-order-by-id/` + id,
        config
      )
      .then((res) => {
        this.setState({
          items: res.data.data.items,
          orderDetails: res.data.data,
          isOrderLoaded: true,
        });
      });
  };

  // componentDidUpdate = (prevProps) => {
  //   if (prevProps.location.search !== this.props.location.search) {
  //     let id = this.props.match.params.id;
  //     let config = {
  //       headers: { Authorization: `Token ${this.state.user.token}` },
  //       params: { id: id },
  //     };
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}order/getorderdetail/`, config)
  //       .then((res) => {
  //         this.setState({ items: res.data.items, orderDetails: res.data });
  //         console.log(res);
  //       });
  //   }
  // };

  componentDidMount() {
    // var query = queryString.parse(this.props.location.search);
    // var params = { options: query.option };
    let id = this.props.match.params.id;
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token, id: id }, () =>
        this.getOrderDetails()
      );
      // {
      //   let config = {
      //     headers: { Authorization: `Token ${this.state.user.token}` },
      //     params: { id: id },
      //   };
      //   axios
      //     .get(`${process.env.REACT_APP_API_URL}order/getorderdetail/`, config)
      //     .then((res) => {
      //       this.setState({ items: res.data.items, orderDetails: res.data });
      //       console.log(res);
      //     });
      // });
    }
  }

  handleOrderStatusUpdate = () => {
    this.setState({ showOrderStatusChange: false });
    let config = { headers: { Authorization: `Token ${this.state.token}` } };
    let data = {
      id: this.state.id,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}order/out-for-delivery`,
        data,
        config
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            approved: true,
            selectVendor: false,
            isOrderLoaded: false,
          });
          this.getOrderDetails();
        } else {
          console.log("stock not available");
        }
      });
  }

  handleOrderStatusToDelivered = () => {
    this.setState({ showOrderStatusChangeToDelivered: false });
    let config = { headers: { Authorization: `Token ${this.state.token}` } };
    let data = {
      id: this.state.id,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_URL}order/delivered`,
        data,
        config
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            approved: true,
            selectVendor: false,
            isOrderLoaded: false,
          });
          this.getOrderDetails();
        } else {
          console.log("stock not available");
        }
      });
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">
                  Order No:{" "}
                  {this.state.orderDetails?.orderNumber?.toUpperCase()}
                </h1>
                {/* Reactbootstrap */}
                <Modal
                  show={this.state.showVendorModal}
                  onHide={() => this.handleVendorModal(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Select Vendor</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {this.state.orderDetails.status !== "approved" ? (
                      <form>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="vendorId">
                                Vendor
                              </label>
                              <select
                                className="col-12 form-control form-select"
                                id="vendorId"
                                value={this.state.vendorId}
                                onChange={this.handleInputChange}
                              >
                                <option value="" selected disabled>
                                  Select Vendor
                                </option>
                                {this.state.stocks.map((item, index) => (
                                  <option
                                    key={`vendor${index}`}
                                    value={item.vendor._id}
                                  >
                                    {item.vendor.vendorName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="driverName"
                              >
                                Driver Name
                              </label>
                              <input
                                type="text"
                                id="driverName"
                                className="form-control"
                                value={this.state.driverName}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="driverContactNumber"
                              >
                                Driver Contact Number
                              </label>
                              <input
                                type="number"
                                id="driverContactNumber"
                                className="form-control"
                                value={this.state.driverContactNumber}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline">
                              <label
                                className="form-label"
                                htmlFor="vehicleNumber"
                              >
                                Vehicle Number
                              </label>
                              <input
                                type="text"
                                id="vehicleNumber"
                                className="form-control"
                                value={this.state.vehicleNumber}
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      this.state.orderDetails && (
                        <ViewOrderDetails
                          orderDetails={this.state.orderDetails}
                        />
                      )
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.handleVendorModal(false)}
                    >
                      Close
                    </Button>
                    {this.state.orderDetails.status !== "confirmed" && (
                      <Button
                        variant="primary"
                        onClick={() => this.handleApproval(true)}
                      >
                        Confirm Order
                      </Button>
                    )}
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={this.state.showOrderStatusChange}
                  onHide={() =>
                    this.setState({ showOrderStatusChange: false })
                  }
                >
                  <Modal.Header>
                    <Modal.Title>Out for Delivery</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Does the order is ready to deliver?</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => this.handleOrderStatusUpdate()}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ showOrderStatusChange: false })
                      }
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={this.state.showOrderStatusChangeToDelivered}
                  onHide={() =>
                    this.setState({ showOrderStatusChangeToDelivered: false })
                  }
                >
                  <Modal.Header>
                    <Modal.Title>Delivered</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Does the order is delivered?</Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => this.handleOrderStatusToDelivered()}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        this.setState({ showOrderStatusChangeToDelivered: false })
                      }
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th>Category Name</th>
                        <th>Material Name</th>
                        <th>Material Code</th>
                        <th>Color</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Weight/m</th>
                        <th>Total</th>
                        <th>Pieces</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody key={this.state.isOrderLoaded}>
                      {this.state.isOrderLoaded &&
                        this.state.items.map((item, index) => (
                          //item.status !== "confirmed" &&
                          <tr key={`${item.orderNumber}=${item.status}`}>
                            <td>{item.inventory.materialType?.materialType}</td>
                            <td>{item.inventory.section?.name}</td>
                            <td>{item.inventory.materialName}</td>
                            <td>{item.inventoryVariant.materialCode}</td>
                            <td>{item.color || "-"}</td>
                            <td>
                              {item.inventoryType === "0"
                                ? item.variant?.variantName + " m"
                                : item.inventoryType === "2"
                                ? "-"
                                : item.length + " m"}
                            </td>
                            <td>
                              {item.inventoryType === "0" ||
                              item.inventoryType === "2"
                                ? "-"
                                : item.variant?.variantName + " m"}
                            </td>
                            <td>
                              {item.inventoryType === "0"
                                ? item.inventoryVariant.weight + " kg"
                                : "-"}
                            </td>
                            <td>
                              {item.inventoryType === "0" ? (
                                item.totalWeight + " kg"
                              ) : (
                                <>
                                  {item.totalArea} m<sup>2</sup>
                                </>
                              )}
                            </td>
                            <td>{item.pieces}</td>
                            <td>{item.price}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-md-6">
                  <div className="table-sec">
                    <div className="table-responsive">
                      <h4 className="py-4 text-center">Customer Detail</h4>
                      <table class="table table-borderless table-hover">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <th scope="row">
                              {
                                this.state?.orderDetails?.addressDetails
                                  ?.fullName
                              }
                            </th>
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <th scope="row">
                              {
                                this.state?.orderDetails?.addressDetails
                                  ?.phoneNo
                              }
                            </th>
                          </tr>
                          <tr>
                            <td>Alt Phone</td>
                            <th scope="row">
                              {
                                this.state?.orderDetails?.addressDetails
                                  ?.altPhoneNo
                              }
                            </th>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <th scope="row">
                              {
                                this.state?.orderDetails?.addressDetails
                                  ?.address
                              }
                            </th>
                          </tr>
                          <tr>
                            <td>City</td>
                            <th scope="row">
                              {this.state?.orderDetails?.addressDetails?.city}
                            </th>
                          </tr>
                          <tr>
                            <td>State</td>
                            <th scope="row">
                              {this.state?.orderDetails?.addressDetails?.state}
                            </th>
                          </tr>
                          <tr>
                            <td>Pincode</td>
                            <th scope="row">
                              {
                                this.state?.orderDetails?.addressDetails
                                  ?.pinCode
                              }
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="table-sec">
                    <div className="table-responsive">
                      <h4 className="py-4 text-center">Order Actions</h4>
                      <table class="table table-borderless table-hover">
                        <tbody>
                          <tr>
                            <td>Order Status</td>
                            <th scope="row">
                              <span className="me-2">{this.state?.orderDetails?.status}</span>
                              {this.state.orderDetails.status === "approved" && (
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={() => {
                                    this.setState({
                                      showOrderStatusChange: true,
                                    });
                                  }}
                                >
                                  Update to Out for Delivery
                                </button>
                              )}
                              {this.state.orderDetails.status === "dispatched" && (
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={() => {
                                    this.setState({
                                      showOrderStatusChangeToDelivered: true,
                                    });
                                  }}
                                >
                                  Update to Delivered
                                </button>
                              )}
                            </th>
                          </tr>
                          <tr>
                            <td>Payment Status</td>
                            <th scope="row">
                              <span>
                                {this.state?.orderDetails?.paymentStatus}
                              </span>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewVendorOrderDetailsTable;
