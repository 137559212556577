import React from "react";
import Select from "react-select";

const MultiSelect = ({ onChange, options }) => {
  return (
    <Select
      isMulti
      name="materialType"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onChange}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelect;
