import React from "react";
import axios from "axios";
// import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import Header from "../Header";
import SidebarUser from "../SideBar2";
import ViewOrderItems from "./ViewOrderItems";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";

class ViewUserOrder extends React.Component {
  constructor() {
    super();
    this.state = {
      orders: [],
      showVendorModal: false,
      items: [],
    };
  }

  handleModal = (modalState) => {
    this.setState({ showVendorModal: modalState });
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData, token: userData.token }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}order/vieworders`, config)
          .then((res) => {
            this.setState({ orders: res.data });
            console.log(res);
          });
      });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SidebarUser />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Orders</h1>
              </div>
              <Modal
                show={this.state.showVendorModal}
                onHide={() => this.handleModal(false)}
                size="lg"
              >
                <Modal.Header>
                  <Modal.Title>
                    Order No :{this.state.orderNumber?.toUpperCase()}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ViewOrderItems items={this.state.items} />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => this.handleModal(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>Order No</th>
                        <th>Ordered date</th>
                        <th>Project Name</th>
                        <th>Customer Name</th>
                        <th>Total Price</th>
                        <th>Delivery Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                        <th>View</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.map((item, index) => (
                        <tr key={item.orderNumber}>
                          <td>{item.orderNumber.toUpperCase()}</td>
                          <td>{moment(item.createdAt).format("MMM Do YY")}</td>
                          <td>{item.projectName}</td>
                          <td>{item.customerName}</td>
                          <td>{item.totalPrice}</td>
                          <td>
                            {moment(item.deliveryDate).format("MMM Do YY")}
                          </td>
                          <td>{item.remarks}</td>
                          <td>{item.status.toUpperCase()}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-secondary"
                              onClick={() => {
                                this.setState(
                                  {
                                    items: item.items,
                                    orderNumber: item.orderNumber,
                                  },
                                  () => this.handleModal(true)
                                );
                              }}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <Link
                              style={{
                                pointerEvents:
                                  item.status === "confirmed" && "none",
                              }}
                              className="btn btn-sm cursor-pointer"
                              to={{
                                pathname: "/edit-order/" + item._id,
                                options: { isEditMode: true, id: item._id },
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                ></path>
                              </svg>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewUserOrder;
