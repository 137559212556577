import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
// import { Link } from "react-router-dom";

class ViewStock extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      newStock: 0,
      vendor: "",
      stock: [],
      vendors: [],
      currentItem: {},
      newArea: "",
    };
  }

  handleTotalArea = (e) => {
    this.setState({ newArea: e.target.value });
  };

  updateStock = (e) => {
    if (this.state.newStock + e < 0) return;
    this.setState({ newStock: this.state.newStock + e }, () => {
      let config = {
        headers: { authorization: `Token ${this.state.user.token}` },
      };
      let data = {
        id: this.state.id,
        newStock: this.state.newStock,
        weight:
          this.state.currentItem.weight +
          this.state.currentItem.inventoryVariant.weight,
        inventoryType: this.state.currentItem.inventory.inventoryType,
      };
      axios
        .put(
          `${process.env.REACT_APP_API_URL}stock/update-stocks`,
          data,
          config
        )
        .then((res) => {
          this.getStock();
        })
        .catch((err) => console.log(err));
    });
  };

  updateAreaStock = () => {
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = {
      id: this.state.id,
      totalArea: this.state.newArea,
      length:
        this.state.newArea / Number(this.state.currentItem.variant.variantName),
      inventoryType: this.state.currentItem.inventory.inventoryType,
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}stock/update-stocks`, data, config)
      .then((res) => {
        this.getStock();
      })
      .catch((err) => console.log(err));
  };

  stopUpdate = () => {
    console.log(this.state.currentItem);
    if (this.state.currentItem.inventory.inventoryType === 1) {
      this.updateAreaStock();
      this.setState({ id: "", editMode: false, newStock: 0 });
    } else {
      this.setState({ id: "", editMode: false, newStock: 0 });
    }
  };

  handleVendorChange = (e) => {
    this.setState({ vendor: e.target.value });
    let data = { user: e.target.value };
    this.getStock(data);
  };

  getStock = (data) => {
    const params = { params: data ? data : "" };
    axios
      .get(`${process.env.REACT_APP_API_URL}stock/list`, params)
      .then((res) => this.setState({ stock: res.data.data }));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
    this.getStock();
    axios.get(`${process.env.REACT_APP_API_URL}vendor/`, {}).then((res) =>
      this.setState(
        {
          vendors: res.data.vendors,
        },
        () => console.log(this.state)
      )
    );
  }

  render() {
    console.log(this.state.tempVar);
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Stock</h1>
              </div>
              <div className="form-group mb-4">
                <select
                  className="form-control form-select"
                  name="vendor"
                  value={this.state.vendor}
                  onChange={this.handleVendorChange}
                >
                  <option value={""}>Select Vendor</option>
                  {this.state.vendors.map((item, index) => (
                    <option key={`vendor${index}`} value={item._id}>
                      {item.vendorName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Vendor</th>
                        <th>Material Name</th>
                        <th>Material Code</th>
                        <th>Variant</th>
                        <th>Color</th>
                        <th>Current Stock</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.stock.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>{item.vendor?.vendorName}</td>
                          <td>{item.inventory?.materialName}</td>
                          <td>{item.inventoryVariant?.materialCode}</td>
                          <td>{item.variant?.variantName}</td>
                          <td>{item.color}</td>
                          <td>
                            <div className="flex">
                              {item.inventory?.inventoryType === "0" ||
                              item.inventory?.inventoryType === 2 ||
                              !item.inventory?.inventoryType ? (
                                <>
                                  {this.state.editMode &&
                                  this.state.id === item._id ? (
                                    <>
                                      {" "}
                                      <button
                                        className="bg-transparent rounded border-1 me-2"
                                        onClick={() => this.updateStock(-1)}
                                      >
                                        -
                                      </button>
                                      {this.state.newStock}
                                      <button
                                        className="bg-transparent rounded border-1 ms-2"
                                        onClick={() => this.updateStock(+1)}
                                      >
                                        +
                                      </button>
                                    </>
                                  ) : (
                                    <p>{item.currentStock} pcs</p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {this.state.editMode &&
                                  this.state.id === item._id ? (
                                    <>
                                      {" "}
                                      <input
                                        type="number"
                                        id="newArea"
                                        className="form-control"
                                        value={this.state.newArea}
                                        onChange={this.handleTotalArea}
                                      ></input>
                                    </>
                                  ) : (
                                    <p>{item.totalArea} sqrm</p>
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            {/* <Link
                              className="btn btn-sm cursor-pointer"
                              to={{
                                pathname: "/admin/addstock",
                                options: { isEditMode: true, id: item._id },
                              }}
                            > */}
                            {this.state.editMode &&
                            this.state.id === item._id ? (
                              <>
                                <button
                                  className="btn btn-sm cursor-pointer"
                                  onClick={() => this.stopUpdate()}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-check-circle-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-sm cursor-pointer"
                                  onClick={() => {
                                    this.setState({
                                      editMode: true,
                                      id: item._id,
                                      newStock: item.currentStock,
                                      currentItem: item,
                                    });
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                    <path
                                      fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    ></path>
                                  </svg>
                                </button>
                              </>
                            )}

                            {/* </Link> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewStock;
