import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";

class ViewBrand extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      brands: [],
    };
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      axios
        .get(`${process.env.REACT_APP_API_URL}user/admin-auth`, {
          headers: {
            authorization: "Bearer " + userData.token,
          },
        })
        .then((res) => {
          this.setState({ user: res.data.users });
        })
        .catch((err) => {
          this.props.history.push("/");
        });
    }
    this.getBrands();
  }

  getBrands = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brand/`, {})
      .then((res) => this.setState({ brands: res.data.brands }));
  };

  disableBrand = () => {
    var user = localStorage.getItem("user");
    var userData = JSON.parse(user);
    let config = {
      headers: { authorization: `Token ${userData.token}` },
    };
    let data = {
      id: this.state.id,
      isDisabled: true,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}brand/update-visibility`,
        data,
        config
      )
      .then((res) => {
        this.getBrands();
      });
  };

  render() {
    console.log(this.state.tempVar);
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Brands</h1>
              </div>

              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Brand Name</th>
                        {/* <th>Visibility</th> */}
                        {["superAdmin", "mainStaff"].includes(
                          this.state.user?.adminType
                        ) && <th>Edit</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.brands.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>{item.brandName}</td>
                          {/* <td>{item.isDisabled ? "Hidden" : "Public"}</td> */}
                          {["superAdmin", "mainStaff"].includes(
                            this.state.user?.adminType
                          ) && (
                            <td>
                              <Link
                                className="me-2"
                                to={{
                                  pathname: "/admin/edit-brand/" + item._id,
                                }}
                              >
                                <button className="btn text-sm btn-outline-secondary border-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    ></path>
                                  </svg>
                                </button>
                              </Link>{" "}
                              <button
                                className="btn text-sm btn-outline-secondary border-0"
                                onClick={() => {
                                  this.setState({ id: item._id }, () =>
                                    this.disableBrand()
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-x-octagon"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewBrand;
