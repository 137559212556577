import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";

class ViewPublicUsers extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      newStock: 0,
      vendor: "",
      stock: [],
      vendors: [],
      users: [],
    };
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}user/users-list`, {})
      .then((res) =>
        this.setState(
          {
            users: res.data.users,
          },
          () => console.log(this.state)
        )
      );
  }

  render() {
    // console.log(this.state.tempVar);
    return (
      <>
        <Header />
        <div className="container-fluid">
          {" "}
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Public Users</h1>
              </div>
              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>User Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>
                            {item.firstName && item.lastName !== null
                              ? item.firstName +
                                (item.lastName === undefined
                                  ? ""
                                  : item.lastName)
                              : "Unknown"}
                          </td>
                          <td>
                            {item.phoneNo !== ""
                              ? item.phoneNo
                              : "- - - - - - - - - -"}
                          </td>
                          <td>{item.userType}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewPublicUsers;
