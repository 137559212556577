// import logo from './logo.svg';
// import './App.css';
import React from "react";
import SignUp from "./auth/SignUp";
// import Vendor from "./vendor/Vendor";
import Home from "./home/Home";
// import Dashboard from "./dashboard/Dashboard";
import Orders from "./orders/Orders";
// import Inventory from "./inventory/AddInventory";
import ViewInventory from "./inventory/ViewInventory";
import Quotation from "./quotation/Quotation";
import ErrorPage from "./ErrorPage";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ViewOrder from "./orders/ViewOrders";
import ViewUserOrder from "./orders/ViewUserOrders";
import Confirmed from "./orders/Confirmed";
import AdminDashboard from "./admin/AdminDashboard";
import UserAccount from "./account/UserAccount";
import SignUpWithOtp from "./auth/SignUpWithOtp";
import AddStock from "./stock/AddStock";
import ViewStock from "./stock/ViewStock";
import VendorViewStock from "./stock/VendorViewStock";
import CompleteAccount from "./account/CompleteAccount";
import ViewMaterials from "./materials/ViewMaterials";
import AddMaterials from "./materials/AddMaterial";
import ViewSections from "./sections/ViewSections";
import AddSections from "./sections/AddSectionV2";
import ViewPriceBySection from "./price/ViewPriceBySection";
import ViewPriceByMaterial from "./price/ViewPriceByMaterial";
import AddBrand from "./brand/AddBrand";
import ViewBrand from "./brand/ViewBrand";
import ViewVendors from "./users/ViewVendors";
import ViewAdmins from "./users/ViewAdmins";
import AddAdminStore from "./users/AddAdminStore";
import ViewInventoryStock from "./stock/ViewInventoryStock";
import EditMaterial from "./materials/EditMaterial";
import EditSection from "./sections/EditSection";
import EditBrand from "./brand/EditBrand";
import EditVendor from "./users/EditVendor";
import ViewOrderDetailsTable from "./orders/ViewOrderDetailsTable";
import ViewEnquiries from "./enquiry/ViewEnquiries";
import EditOrder from "./orders/EditOrder";
// import EditInventory from "./inventory/EditInventory";
import VendorLogin from "./admin/VendorLogin";
import AddInventoryBulk from "./inventory/AddInventoryBulk";
import EditInventoryBulk from "./inventory/EditInventoryBulk";
import CreateBundle from "./bundle/CreateBundle";
import ViewBundle from "./bundle/ViewBundle";
import { Toaster } from "react-hot-toast";
import OrderList from "./ordersV2/OrderList";
import ViewPublicUsers from "./users/ViewPublicUsers";
import ViewContractors from "./users/viewContractors";
import UpdateBundle from "./bundle/UpdateBundle";
import ViewVendorOrder from "./orders/ViewVendorOrder";
import ViewVendorOrderDetailsTable from "./orders/ViewVendorOrderDetailsTable";
import EditContractors from "./users/EditContractors";
import ViewE2eEnquiries from "./enquiry/ViewE2eEnquiries";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isUserAdmin: false,
    };
  }
  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
      if (user.UserType === "admin") {
        this.setState({ isUserAdmin: true });
      }
    }
  }
  render() {
    return (
      <>
        <Toaster />
        <BrowserRouter>
          <Switch>
            <Route path="/signup" component={SignUpWithOtp} />
            <Route path="/admin/signup" component={SignUp} />
            <Route path="/signin" component={SignUpWithOtp} />
            {/* <Route path="/vendors" component={Vendor} /> */}
            {/* <Route path="/admin/dashboard" component={Dashboard} /> */}
            <Route path="/orders" component={Orders} />
            <Route path="/vieworders" component={ViewUserOrder} />
            <Route path="/edit-order/:id" component={EditOrder} />
            <Route path="/confirmed" component={Confirmed} />
            <Route path="/quotation" component={Quotation} />
            <Route path="/account" component={UserAccount} />
            <Route path="/updateaccount" component={CompleteAccount} />
            <Route exact path="/admin" component={AdminDashboard} />
            <Route exact path="/vendor" component={VendorLogin} />
            <Route path="/admin/inventory" component={AddInventoryBulk} />
            {/* <Route path="/admin/add-inventory" component={AddInventoryBulk} /> */}
            <Route
              path="/admin/edit-inventory/:id"
              component={EditInventoryBulk}
            />
            <Route path="/admin/viewinventory" component={ViewInventory} />
            <Route path="/admin/vieworders" component={ViewOrder} />
            <Route path="/vendor/vieworders" component={ViewVendorOrder} />
            <Route
              path="/admin/view-order-details/:id"
              component={ViewOrderDetailsTable}
            />
            <Route
              path="/vendor/view-order-details/:id"
              component={ViewVendorOrderDetailsTable}
            />
            <Route path="/admin/view-enquiries/" component={ViewEnquiries} />
            <Route path="/admin/view-e2e-enquiries/" component={ViewE2eEnquiries} />
            <Route path="/admin/addstock" component={AddStock} />
            <Route path="/admin/viewstock" component={ViewStock} />
            {/* <Route path="/admin/viewbrand" component={ViewBrand} /> */}
            <Route path="/admin/add-brand" component={AddBrand} />
            <Route path="/admin/edit-brand/:id" component={EditBrand} />
            <Route path="/admin/view-brands" component={ViewBrand} />
            <Route
              path="/admin/view-price-by-material"
              component={ViewPriceByMaterial}
            />
            <Route
              path="/admin/view-price-by-section"
              component={ViewPriceBySection}
            />
            <Route path="/admin/vendorviewstock" component={VendorViewStock} />
            <Route path="/admin/edit-vendor/:id" component={EditVendor} />
            <Route path="/admin/add-material" component={AddMaterials} />
            <Route path="/admin/edit-material/:id" component={EditMaterial} />
            <Route path="/admin/view-materials" component={ViewMaterials} />
            <Route path="/admin/add-section" component={AddSections} />
            <Route path="/admin/edit-section/:id" component={EditSection} />
            <Route path="/admin/view-sections" component={ViewSections} />
            //user
            <Route
              path="/admin/view-public-users"
              component={ViewPublicUsers}
            />
            <Route path="/admin/view-contractors" component={ViewContractors} />
            <Route path="/admin/view-vendors" component={ViewVendors} />
            <Route path="/admin/view-admins" component={ViewAdmins} />
            <Route path="/admin/add-adminstore" component={AddAdminStore} />
            <Route path="/admin/edit-contractor/:id" component={EditContractors}/>
            <Route
              path="/admin/view-inventory-stock/:id"
              component={ViewInventoryStock}
            />
            <Route path="/admin/view-bundle" component={ViewBundle} />
            <Route path="/admin/add-bundle" component={CreateBundle} />
            <Route path="/admin/edit-bundle/:id" component={UpdateBundle} />
            <Route path="/vendor/vendorviewstock" component={VendorViewStock} />
            {/* <Route path="/admin/test" component={OrderList} /> */}
            <Route exact path="/" component={AdminDashboard} />
            <Route component={ErrorPage} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
