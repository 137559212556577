import React from "react";
import axios from "axios";
import Header from "../Header";

class Quotation extends React.Component {
  constructor() {
    super();
    this.state = {
      quotationNumber: "",
      sectionNumber: "",
      customerName: "",
      length: "",
      weightPerMeter: "",
      weight: "",
      pieces: "",
      materialName: "",
      materials: [],
    };
  }

  handleInputChange = (event) => {
    console.log(event);
    this.setState({ [event.target.id]: event.target.value });
  };

  submitOrder(event) {
    event.preventDefault();
    console.log("state=>", this.state);
    axios
      .post("http://localhost:8081/api/quotation/add", {
        quotationNumber: this.state.quotationNumber,
        sectionNumber: this.state.sectionNumber,
        customerName: this.state.customerName,
        materialName:
          this.state.materialName || this.state.materials[0].materialType,
        length: this.state.length,
        weightPerMeter: this.state.weightPerMeter,
        weight: this.state.weight,
        pieces: this.state.pieces,
      })
      .then((res) => console.log(res));
  }

  componentDidMount() {
    axios.get("http://localhost:8081/api/inventory/materials", {}).then((res) =>
      this.setState({
        materials: res.data,
        materialName: res.data[0].materialName,
      })
    );
  }

  render() {
    return (
      <>
        <Header />
        <div className="auth-page">
          <div className="container page">
            <div className="row">
              <div className="col-md-6 offset-md-3 col-xs-12">
                <h1 className="text-xs-center">Quotation</h1>
                <p className="text-xs-center"></p>
                <form onSubmit={(event) => this.submitOrder(event)}>
                  {/* 2 column grid layout with text inputs for the first and last names */}
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="quotationNumber">
                          Quotation Number
                        </label>
                        <input
                          type="text"
                          id="quotationNumber"
                          className="form-control"
                          value={this.state.quotationNumber}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="sectionNumber">
                          Section No
                        </label>
                        <input
                          type="text"
                          id="sectionNumber"
                          className="form-control"
                          value={this.state.sectionNumber}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="customerName">
                      Customer Name
                    </label>
                    <input
                      type="text"
                      id="customerName"
                      className="form-control"
                      value={this.state.customerName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="materiaName">
                      Material Name
                    </label>
                    <select
                      className="select col-12"
                      id="materialName"
                      value={this.state.materialName}
                      onChange={this.handleInputChange}
                    >
                      {this.state.materials.map((material, index) => (
                        <option
                          key={`material${index}`}
                          value={material.materialName}
                        >
                          {material.materialName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* Number input */}
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="weightPerMeter">
                          Weight/m
                        </label>
                        <input
                          type="text"
                          id="weightPerMeter"
                          className="form-control"
                          value={this.state.weightPerMeter}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="length">
                          Length(m)
                        </label>
                        <input
                          type="text"
                          id="length"
                          className="form-control"
                          value={this.state.length}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="pieces">
                          Pieces
                        </label>
                        <input
                          type="text"
                          id="pieces"
                          className="form-control"
                          value={this.state.pieces}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="weight">
                          Weight
                        </label>
                        <input
                          type="text"
                          id="weight"
                          className="form-control"
                          value={this.state.weight}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Checkbox */}
                  {/* <div className="form-check d-flex mb-4">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      defaultValue
                      id="form6Example8"
                      defaultChecked
                    />
                    <label className="form-check-label" htmlFor="form6Example8">
                      {" "}
                      Create an account?{" "}
                    </label>
                  </div> */}
                  {/* Submit button */}
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mb-4"
                  >
                    Place order
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Quotation;
