import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";
import { Link } from "react-router-dom";

class ViewContractors extends React.Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      id: "",
      newStock: 0,
      vendor: "",
      stock: [],
      vendors: [],
      users: [],
    };
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}user/contractors-list`, {})
      .then((res) =>
        this.setState(
          {
            users: res.data.users,
          },
          () => console.log(this.state)
        )
      );
  }

  handleClick=(event, id) =>{
    console.log('evenr',event);
    let config = { headers: { authorization: `Token ${this.state.token}` } };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}user/contractors-approval/`+id,config)
      .then((res) => {
        console.log("res", res);
      })
  }

  render() {
    // console.log(this.state.tempVar);
    return (
      <>
        <Header />
        <div className="container-fluid">
          {" "}
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2 fw-bold">Contractors List</h1>
              </div>
              <div className="table-sec">
                <div className="table-responsive">
                  <table className="table table-borderless table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Mobile Number</th>
                        <th>User Type</th>
                        <th>Approved as</th>
                        <th>Rating</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index}</td>
                          <td>
                            {item.firstName && item.lastName !== null
                              ? item.firstName +
                                (item.lastName === undefined
                                  ? ""
                                  : item.lastName)
                              : "Unknown"}
                          </td>
                          <td>
                            {item.phoneNo !== ""
                              ? item.phoneNo
                              : "- - - - - - - - - -"}
                          </td>
                          <td>{item.userType}</td>
                          <td>
                            {/* <button
                              className="btn text-sm btn-outline-secondary border-0"
                              onClick={(event, id = item._id) =>
                                this.handleClick(event, id)
                              }
                            >
                              {item.approved === true
                                ? "Loham Expert"
                                : "Not Approved"}
                            </button> */}
                            {item.approved==true?"Loham Expert":'Not Approved'}
                          </td>
                          {item.rating?<td>{item.rating}★</td>:'--------'}
                          <td>
                         <Link
                          className="me-2"
                          to={{
                            pathname: "/admin/edit-contractor/" + item._id,
                          }}
                         >
                            <button className="btn text-sm btn-outline-secondary border-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    ></path>
                                  </svg>
                            </button>
                         </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}

export default ViewContractors;
