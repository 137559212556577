import React from "react";
import axios from "axios";
import Header from "../Header";

class AdminDashboard extends React.Component {
  constructor() {
    super();
    this.changeEmail = (event) => this.setState({ email: event.target.value });
    this.changePassword = (event) =>
      this.setState({ password: event.target.value });
    this.state = {
      email: "",
      password: "",
    };
  }

  submitForm(event) {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}user/adminsignin`, {
        password: this.state.password,
        email: this.state.email,
      })
      .then((res) => {
        if (res.data.email) {
          localStorage.setItem("user", JSON.stringify(res.data));
          console.log(res.data, this.props.history);
          if (res.data.userType === "admin") {
            this.props.history.push({ pathname: "/admin/vieworders" });
          } else if (res.data.userType === "vendor") {
            this.props.history.push({ pathname: "/admin/vendorviewstock" });
          }
        } else {
          console.log("Invalid", res.data);
        }
      })
      .catch(({ response }) => {
        this.setState({ error: response.data.message });
        console.log(response);
      });
  }

  componentDidMount() {
    var user = localStorage.getItem("user");
    console.log(user);
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData });
      if (userData.userType === "admin" || userData.userType === "staff") {
        this.props.history.push({
          pathname: "/admin/viewinventory",
        });
      } else if (userData.userType === "vendor") {
        this.props.history.push({
          pathname: "/admin/vendorviewstock",
        });
      } else {
        this.props.history.push({
          pathname: "/admin/viewinventory",
        });
      }
    }
  }

  render() {
    return (
      <>
        <Header isAuthPage={true} />
        <div className="auth-page">
          <div className="container">
            <div className="row  h-100">
              <div className="auth-box col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-8 col-11 px-md-5 py-md-5 px-4 py-4 rounded-6  border">
                <div className="mb-5 text-center">
                  <h2 className="text-center fw-bold pt-4">Sign In</h2>
                  <small className="text-sm fw-light">
                    Enter your details below to login
                  </small>
                </div>
                <form onSubmit={(event) => this.submitForm(event)}>
                  <fieldset>
                    <fieldset className="form-group form-outline mb-4">
                      <input
                        className="form-control py-2 border rounded-3"
                        type="email"
                        placeholder="Email"
                        id="email"
                        value={this.state.email}
                        onChange={this.changeEmail}
                      />
                    </fieldset>
                    <>
                      <fieldset className="form-group form-outline mb-4 position-relative">
                        <input
                          className="form-control"
                          type="password"
                          id="password"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.changePassword}
                        />
                        <small className="text-danger position-absolute">
                          {this.state.error}
                        </small>
                      </fieldset>
                    </>
                    <button
                      className="btn py-2 mb-4 btn-primary2 rounded-3 w-100"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminDashboard;
