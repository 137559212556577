import React, { useState } from "react";

import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

function arrayMove(array, from, to) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement((props) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(CreatableSelect);

export default function MultiCreatableSelectWithoutDropDown({
  onChange,
  value,
  options,
  isDisabled,
}) {
  // const [selected, setSelected] = useState(value);

  const handleChange = (selectedOptions) => {
    // setSelected(selectedOptions);
    onChange(selectedOptions);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(value, oldIndex, newIndex);
    onChange(newValue);
  };
  console.log(options);
  return (
    <SortableSelect
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={value}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
        Menu: () => <></>,
        DropdownIndicator: null,
      }}
      closeMenuOnSelect={false}
      isDisabled={isDisabled}
    />
  );
}
