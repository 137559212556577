import React from "react";
import axios from "axios";
import SideBar from "../SideBar";
import Header from "../Header";

export default class EditSection extends React.Component {
  constructor() {
    super();
    this.state = {
      user: "",
      name: "",
      priceOnMaterial: false,
      materialTypeId: "",
      price: "",
      drawing: "",
      preview: "",
      isPriceBasedOnMaterial: false,
      materialTypes: [],
      loading: false,
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleMaterialTypeChange = (e) => {
    let materialName = this.state.materialTypes.filter(
      (i) => i._id === e.target.value
    );
    this.setState({
      materialTypeId: e.target.value,
      isPriceBasedOnMaterial: materialName[0].isPriceBasedOnMaterial,
    });
  };

  handleFileChange = (event) => {
    this.setState({
      drawing: event.target.files[0],
      preview: URL.createObjectURL(event.target.files[0]),
    });
  };

  createRequestBody = () => {
    const data = new FormData();
    data.append("id", this.state.id);
    data.append("name", this.state.name);
    data.append("materialTypeId", this.state.materialTypeId);
    data.append("price", this.state.price);
    if (this.state.drawing) data.append("section", this.state.drawing);

    return data;
  };

  submitOrder = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let config = {
      headers: { authorization: `Token ${this.state.user.token}` },
    };
    let data = this.createRequestBody();
    console.log("req data", data);
    axios
      .put(`${process.env.REACT_APP_API_URL}section/update`, data, config)
      .then((res) => {
        if (res) {
          this.props.history.push({
            pathname: "/admin/view-sections",
          });
        }
      })
      .catch(({ response }) => {
        this.setState({ loading: false });
        console.log(response);
      });
  };

  getMaterialTypes = () => {
    axios.get(`${process.env.REACT_APP_API_URL}material/`, {}).then((res) =>
      this.setState({
        materialTypes: res.data.materialTypes,
      })
    );
  };

  populateData = (data) => {
    this.setState({
      name: data.name,
      materialTypeId: data.materialType,
      preview: process.env.REACT_APP_IMAGE_URL + data.drawing,
      price: data.price,
    });
    console.log(process.env.REACT_APP_IMAGE_URL + data.drawing);
  };

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    let id = this.props.match.params.id;
    if (user) {
      this.getMaterialTypes();
      this.setState({ user: user, id: id });
      let config = {
        headers: { authorization: `Token ${user.token}` },
        params: { id: id },
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}section/getsection/`, config)
        .then((res) => {
          if (res.data.sections[0]) {
            this.populateData(res.data.sections[0]);
          }
        });
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid">
          <div className="row">
            <SideBar />
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Edit Category</h1>
              </div>
              <div className="col-md-6">
                <form onSubmit={(event) => this.submitOrder(event)}>
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialType">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          value={this.state.name}
                          onChange={this.handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="materialType">
                          Material Type
                        </label>
                        <select
                          className="form-control form-select col-12"
                          id="materialTypeId"
                          value={this.state.materialTypeId}
                          onChange={this.handleMaterialTypeChange}
                          disabled
                        >
                          <option value="" disabled selected>
                            Select Material
                          </option>
                          {this.state.materialTypes.map((material, index) => (
                            <option key={material._id} value={material._id}>
                              {material.materialType}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {!this.state.price && (
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="price">
                            Price
                          </label>
                          <input
                            type="text"
                            id="price"
                            className="form-control"
                            value={this.state.price}
                            onChange={this.handleInputChange}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mb-4">
                    <div className="col">
                      <div className="form-outline">
                        <label className="form-label" htmlFor="drawing">
                          Drawing
                        </label>
                        <input
                          type="file"
                          id="drawing"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          onChange={this.handleFileChange}
                          // required
                        />
                      </div>
                      {this.state.preview && (
                        <img
                          alt=""
                          className="uploaded-preview mt-4"
                          src={this.state.preview}
                        />
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary2 btn-block mb-4"
                    disabled={this.state.loading}
                  >
                    Edit Category
                  </button>
                </form>
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }
}
