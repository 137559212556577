import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { withRouter } from "react-router";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      user: false,
    };
  }

  signOut = () => {
    localStorage.clear();
    this.props.handleData &&
      this.props.handleData({ key: "user", value: false });
    this.setState({ user: false }, () => this.props.history.push("/"));
  };

  componentDidMount() {
    var user = localStorage.getItem("user");
    if (user) {
      var userData = JSON.parse(user);
      this.setState({ user: userData }, () => {
        let config = this.state.user?.token
          ? { headers: { authorization: `Token ${this.state.user.token}` } }
          : {};
        axios
          .get(`${process.env.REACT_APP_API_URL}user/`, config)
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            if (err.status === 401) {
              this.signOut();
              this.props.history.push("/");
            }
          });
      });
    }
  }
  render() {
    return (
      <header className="navbar navbar-light border-bottom  sticky-top bg-white flex-md-nowrap p-1">
        <a
          className="navbar-brand col-md-3 col-lg-2 me-0 px-3"
          href={this.state.user.userType !== "admin" ? "/" : "/admin"}
        >
          Bright Aluminum
        </a>
        <ul className="navbar-nav px-3 d-flex flex-row align-items-center">
          <li
            className="nav-item text-nowrap me-md-0 me-2"
            style={{ display: this.props?.isAuthPage ? "none" : "block" }}
          >
            {!this.state.user ? (
              <Link to="/signin" className="nav-link">
                Sign In
              </Link>
            ) : (
              <div className="nav-link cursor-pointer" onClick={this.signOut}>
                Sign Out
              </div>
            )}
          </li>
          <li>
            <button
              className="navbar-toggler d-md-none collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
          </li>
        </ul>
      </header>
    );
  }
}

export default withRouter(Header);
